import {
  Component,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  SidebarComponent,
  NodeSelectEventArgs,
  TreeViewComponent,
} from '@syncfusion/ej2-angular-navigations';
import { ButtonComponent } from '@syncfusion/ej2-angular-buttons';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserMenuComponent } from '../user-menu/user-menu.component';
import { NavbarComponent } from '../navbar/navbar.component';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from '../auth/auth.service';
import {
  faChevronRight,
  faSignOut,
  faSignIn,
  faBars,
  faCircle,
  faCog,
  faArrowRightFromBracket,
} from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { EventEmitter } from '@angular/core';
import { SidebarService } from '../services/sidebar.service';
import { UserSessionService } from '../services/user-session.service';
import {
  catchError,
  map,
  Observable,
  of,
  OperatorFunction,
  switchMap,
} from 'rxjs';
import { LibService } from '../services/libService';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from '../services/profileService';
import { ControllerService } from '../services/controller.service';
import { User } from '../models/user';
import { Action } from '../models/action';

export interface MenuItem {
  label: string;
  auth: boolean;
  items?: SubMenuItem[];
}

export interface SubMenuItem {
  label: string;
  url: string;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class MySidebarComponent implements OnInit {
  faChevronRight = faChevronRight;
  faSignOut = faSignOut;
  faSignIn = faSignIn;
  faBars = faBars;
  faCircle = faCircle;
  faCog = faCog;
  faArrowRightFromBracket = faArrowRightFromBracket;
  usrs!: Observable<any>;
  visibilities: any;
  userActions: Action[];
  loading: any = true;

  @ViewChild('tree')
  public tree!: TreeViewComponent;
  @ViewChild('sidebar') sidebar!: SidebarComponent;
  public type: string = 'Push';
  public target: string = 'content';
  public enablePersistence: boolean = true;
  @ViewChild('togglebtn')
  public togglebtn!: ButtonComponent;
  public onCreated(args: any) {
    this.sidebar.element.style.visibility = '';
  }
  btnClick() {
    if (this.togglebtn.element.classList.contains('e-active')) {
      //  this.togglebtn.content = 'Open';
      this.sidebar.hide();
    } else {
      //  this.togglebtn.content = 'Close';
      this.sidebar.show();
    }
  }
  closeClick() {
    this.sidebar.hide();
    this.togglebtn.element.classList.remove('e-active');
    // this.togglebtn.content = 'Open'
  }
  @Input() drawer: any;
  @Input() user!: any;
  idClinica!: string;
  isAdmin: boolean = false;

  @Input() collapsed: boolean = false;

  @Output() onCollapse = new EventEmitter<boolean>();

  mainMenu: any = [
    {
      Label: 'Home',
      Icon: 'home',
      RouterLink: '/home',
    },
  ];

  public cssClass: string = 'mytree';

  public data: MenuItem[] = [
    {
      label: 'Amministrazione',
      auth: true,
      items: [
        {
          label: 'Settings',
          url: '/settings',
        },
        {
          label: 'Journeys',
          url: '/journeys',
        },
       // {
        //  label: 'Configurazione',
        //  url: '/genconfig'
       // },
        {
          label: 'Profili',
          url: '/profiles',
        },
        {
          label: 'Squadre',
          url: '/teams',
        },
        {
          label: 'Domini',
          url: '/domains',
        },
        {
          label: 'Sedi',
          url: '/companies',
        },
        {
          label: 'Licenze',
          url: '/ad-license',
        },
        {
          label: 'Utenti',
          url: '/users',
        },
        {
          label: 'Marketing Notes',
          url: 'settings/marketing-note-settings',
        },
        {
          label: 'Cedolino Al 10',
          url: '/final-balancer/history',
        },
      ],
    },
    {
      label: 'Operazioni',
      auth: true,
      items: [
        {
          label: 'CRM',
          url: '/crm',
        },
        {
          label: 'Obiettivi',
          url: '/targets',
        },
        {
          label: 'Cruscotto',
          url: '/cockpit',
        },
        // {
        //   label: 'Soggetti',
        //   url: '/subjects'
        // },
        {
          label: 'Pratiche',
          url: '/orders',
        },
        {
          label: 'Planning',
          url: '/planner',
        },
        {
          label: 'Agenda',
          url: '/schedule',
        },
        {
          label: 'Consuntivi',
          url: '/final-balancer',
        },
        {
          label: 'Certificati',
          url: '/certificates',
        },
        {
          label: 'Sondaggi',
          url: '/surveysmenu',
        },
        {
          label: 'Licenze',
          url: '/op-license',
        },
        {
          label: 'La Mia Sede',
          url: '/my-branch',
        },
        {
          label: 'ConsuntivazioneMulti',
          url: '/final-balancer-multi',
        },
        {
          label: 'Approvazione Storni',
          url: '/cancellations-approval',
        },
        {
          label: 'DPI',
          url: '/dpi',
        },
        {
          label: 'Incassi Cessione',
          url: '/transfer-receipts',
        },
        {
          label: 'Approvazione Ordini',
          url: '/ordersendorsement',
        },
        {
          label: 'Fatturazione Attiva',
          url: '/activeinvoicing',
        },
      ],
    },
    {
      label: 'Wiki',
      auth: false,
    },
    {
      label: 'FAQ',
      auth: false,
    },
  ];

  public field: Object = {
    dataSource: this.data,
    id: 'nodeId',
    text: 'nodeText',
    child: 'nodeChild',
    iconCss: 'icon',
    url: 'url',
  };

  public loadRoutingContent(args: NodeSelectEventArgs): void {
    let data: any = this.tree.getTreeData(args.node);
    let routerLink: string = data[0].url;
    this.router.navigate([routerLink]);
  }

  constructor(
    public libService: LibService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private authService: AuthService,
    private sidebarService: SidebarService,
    public userSessionService: UserSessionService,
    private profileService: ProfileService,
    private controllerService: ControllerService,
    public translate: TranslateService
  ) { }

  ngDoCheck() { }

  controllers$ = this.controllerService.getAllControllers();
  data$ = this.controllers$.pipe(
    map((controllers) => {
      console.log('CONTROLLERS --> ', controllers);
      this.libService.lockPage('Load Actions');
      return controllers.map((controller) => {
        return {
          label: controller.name,
          auth: true,
          items: controller.ControllersActionsRel?.map((action) => {
            // console.log("actions --> ", action)
            this.libService.unlockPage();
            return {
              label: action.name,
              url: action.routeTo,
              order: action.order,
            };
          }).sort((a, b) => {
            if (a.order > b.order) {
              return 1;
            }
            if (a.order < b.order) {
              return -1;
            }
            return 0;
          }),
        };
      });
    })
  );
  /* userActions$ = this.getuserActions()
  data$ = this.userActions$.pipe(
    switchMap((userActions: any) => {
      return this.controllers$.pipe(
        map((controllers: any) => {
          return controllers.map((controller: any) => {
            return controller.ControllersActionsRel.filter((action:any) => userActions.contains(action.id))
          })
        })
      )
    })
  ) */

  ngOnInit(): void {
    this.libService.lockPage(
      this.translate.instant('LOADINGMESSAGES.caricamentoVisibilita')
    );
    console.log('init sidebar is user %o', this.authService.user());
    this.controllers$.subscribe((res) => {
      console.log('controllers: ', res);
    });
    if (this.isUser()) {
      if (!this.userSessionService.getState('user')) {
        this.loaduserActions();
      }

      // Setta le actions per le visibilità
      this.userSessionService.getVisibilities().subscribe(
        (v) => {
          console.log('getVisibilities')
          console.log(v);
          this.userActions = v.actions;
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  mergeProfilesActions(user: User): Action[] {
    let actions: Action[] = [];
    //console.log('user %o user.UsersProfilesRel %o',user,user.UsersProfilesRel);
    user.UsersProfilesRel!.forEach((profile) => {
      //call profile
      this.profileService.getProfile(profile.id).subscribe((pr) => {
        console.log('----------------------------------- pr %o', pr);
        let aa = actions.concat(pr.ProfilesActionsRel);

        actions = Array.from(new Set(aa));
        //console.log('----------------------------------- aa %o actions %o', aa, actions);
      });
      return actions;
    });
    return actions;
  }

  hasNode(subitem: any) {
    const actions = this.userActions;
    //console.log('----------------------------------- userActions %o', actions);
    if (!(actions == null) && actions.length > 0) {
      for (let action of actions) {
        // console.log('--------------------------------- found node in userActions with name:%o action %o', nodeName, action);
        if (!(action == null) && subitem.label === action.name) return true;
      }
    }
    return false;
  }

  getTooltip(label: any) {
    return this.translate.instant('SIDEBAR.' + label);
  }

  hasNodes(nodes: any) {
    const actions = this.userActions;
    if (
      !(actions == null) &&
      actions.length > 0 &&
      !(nodes.items == null) &&
      nodes.items.length > 0
    ) {
      for (let itemN of nodes.items) {
        for (let action of actions) {
          if (itemN.label == action.name) return true;
        }
      }
    }
    return false;
  }

  loaduserActions(): void {
    console.log('LOAD USER ACTIONS');
    this.libService.lockPage('');
    this.usrs = this.userSessionService.getUser(this.authService.user().email);

    this.usrs.subscribe(
      (u) => {
        this.libService.unlockPage();
        console.log('get users %o', u);
        this.userSessionService.saveState('user', u[0]);
        console.log('SAVE STATE  %o', u[0]);

        if (!(this.userSessionService == null)) {
          let currentLanguage = this.userSessionService.getCurrentLanguage();
          this.translate.use(currentLanguage);
        }

      },
      (err) => {
        this.libService.unlockPage();
        this.toastr.error(
          this.translate.instant('TOASTR.MESSAGE_5'),
          this.translate.instant('TOASTR.WARNING')
        );
      }
    );
  }

  logout() {
    this.authService.logout();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setCollapsed(event?.target?.innerWidth <= 1024);
  }

  setCollapsed(value: boolean) {
    this.collapsed = value;
    this.sidebarService.setCollapsed(value);
  }

  drawerToggle = () => {
    this.drawer.toggle();
  };

  isLogged() {
    return this.authService.user();
  }

  getIconName(label: string) {
    return label.toLowerCase().replaceAll(' ', '_');
  }

  goTo(url: any) {
    if (window.innerWidth <= 1024) this.setCollapsed(true);
    this.router.navigate([url]);
  }

  goToExt(url: any) {
    window.open(url, '_blank');
  }

  goHome() {
    this.router.navigate(['/']);
  }

  isUser() {
    return this.authService.user();
  }

  signOut() {
    this.authService.logout();
    let urlLogout =
      environment.cognitoEntryPoint +
      '/logout?client_id=' +
      environment.cognitoAppClientId +
      '&redirect_uri=' +
      environment.postLogoutRedirectUri;
    console.log('LOGOUT sidebar on %o', urlLogout);
    window.location.href = urlLogout;
  }

  signIn() {
    this.authService.login();
  }

  inPortrait() {
    return window.innerWidth <= 1024;
  }
}
