<div class="container-fluid my-container">

  <!--
  <div class="buttons">
    <div class="buttons-right">
      <button (click)="goTo({id: 0})" type="button" class="btn btn-primary">+</button>
    </div>
  </div>
-->
  <!-- <div class="search-wrapper mat-elevation-z2"> -->
    <!-- <div class="search" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
      <input type="text" [(ngModel)]="kwSearch" [value]="kwSearch" (ngModelChange)="search($event)"
      [placeholder]="translate.instant('FORM_FIELD.SEARCH')" />
    </div> -->

    <app-practices-fulltextsearch-with-pagination (selected)="goTo($event)" (list)="updateList($event)" (clearOut)="clearList($event)"></app-practices-fulltextsearch-with-pagination>
    <!-- </div> -->
  <div style="overflow-x: auto;">
    <app-practices-filter [stateCategories]="stateCategoriesFilter"  [states]="states" [branches]="branchesFilter" [type]="'Pratica'" (getFilterSelection)="getFilterSelectionEmit($event)" [disabled]="disabled"></app-practices-filter>
    
    <mat-slide-toggle color="primary" (change)="changeColumsStructure($event)" >{{'LABEL.visualizzazione_con_dati_commessa' | translate}}</mat-slide-toggle>
    
    <table mat-table [dataSource]="dataSource" matSort style="width:100%">
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{'LABEL.Codice' | translate
          }}</th>
        <td [ngClass]="getClassRow(element.StateId)" mat-cell *matCellDef="let element">
          <span><b>{{element.code}}</b></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="branch">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{'LABEL.BRANCH' | translate
          }}</th>
        <td [ngClass]="getClassRow(element.StateId)" mat-cell *matCellDef="let element">
          <span><b>{{getBranchLabel(element.BusinessLineId)}}</b></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="responsible">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{'LABEL.Responsabile' | translate
          }}</th>
        <td [ngClass]="getClassRow(element.StateId)" mat-cell *matCellDef="let element">
          {{element.respCommercialeName}} </td>
      </ng-container>
      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{'LABEL.Sede' | translate
          }}</th>
        <td [ngClass]="getClassRow(element.StateId)" mat-cell *matCellDef="let element">
          {{getSede(element.idSede)?.code}} </td>
      </ng-container>
      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{'LABEL.VALUE' | translate
        }}</th>
        <td [ngClass]="getClassRow(element.StateId)" mat-cell *matCellDef="let element">
          <span>{{getValue(element) | number: '1.2-2'}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{'LABEL.STATUS' | translate
          }}</th>
        <td [ngClass]="getClassRow(element.StateId)" mat-cell *matCellDef="let element">
          <span><b>{{ getStatusLabel(element.StateId) | translate}}</b></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="valoreDeliberato">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{'LABEL.VALORE_DELIBERATO' | translate
          }}</th>
        <td [ngClass]="getClassRow(element.StateId)" mat-cell *matCellDef="let element">
          <span><b>{{element.isDeliberato === true? element.realPrice: 0}}</b></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="valoreProdotto">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{'LABEL.ValoreProdotto' | translate
          }}</th>
        <td [ngClass]="getClassRow(element.StateId)" mat-cell *matCellDef="let element">
          <span><b>{{element?.productValue?.toFixed(2) }}</b></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="datoMLC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{'LABEL.MLC' | translate
          }}</th>
        <td [ngClass]="getClassRow(element.StateId)" mat-cell *matCellDef="let element">
          <span><b>?</b></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="fatturato">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{'LABEL.Fatturato' | translate
          }}</th>
        <td [ngClass]="getClassRow(element.StateId)" mat-cell *matCellDef="let element">
          <span><b>{{element.fatturato}}</b></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="incassato">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{'LABEL.Incassato' | translate
          }}</th>
        <td [ngClass]="getClassRow(element.StateId)" mat-cell *matCellDef="let element">
          <span><b>{{element.incassato}}</b></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="dataUltimoSAL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{'LABEL.DATAULTIMOSAL' | translate
          }}</th>
        <td [ngClass]="getClassRow(element.StateId)" mat-cell *matCellDef="let element">
          <span><b>{{getDataUltimoSAL(element) | date}}</b></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="subjectId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.SUBJ' | translate }}</th>
        <td [ngClass]="getClassRow(element.StateId)" mat-cell *matCellDef="let element"> <span
            >{{getSubject(element)}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="buildingId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.BUILDING' | translate }}</th>
        <td [ngClass]="getClassRow(element.StateId)" mat-cell *matCellDef="let element">
          <span>{{getBuildingName(element)}}</span>
        </td>
      </ng-container>
      <!--
      <ng-container matColumnDef="condominiusId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Condominio</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.nameCondominius}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="subjectACId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Amministratore</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.nameAdminCondominius}}</span>
        </td>
      </ng-container>
      -->
      <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.NOTE' | translate }}</th>
        <td [ngClass]="getClassRow(element.StateId)" mat-cell *matCellDef="let element"> <span>{{getNote(element)}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="sal100">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.SAL100' | translate }}</th>
        <td [ngClass]="getClassRow(element.StateId)" mat-cell *matCellDef="let element"> <span *ngIf="element.isSalCento"><i class="material-icons">checked</i></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.CREATION_DATE' | translate }}</th>
        <td [ngClass]="getClassRow(element.StateId)" mat-cell *matCellDef="let element"> <span>{{element.createdAt |
            date: 'dd/MM/YYYY'}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="editAction">
        <th mat-header-cell *matHeaderCellDef></th>
        <td [ngClass]="getClassRow(element.StateId)" mat-cell *matCellDef="let element" (click)="goTo(element)">
          <span [matTooltip]="translate.instant('LABEL.EDIT_ORDER')">
          <i class="material-icons ic">edit</i>
          </span>
      </ng-container>
      <ng-container matColumnDef="goToFinalBalancer">
        <th mat-header-cell *matHeaderCellDef></th>
        <td [ngClass]="getClassRow(element.StateId)" [class.disabled]="!isConsuntivable(element.StateId)" mat-cell *matCellDef="let element" (click)="goToFinalBalancer(element)">
          <span [matTooltip]="translate.instant('LABEL.Consuntivo')">
            <i class="material-icons ic">account_balance_wallet</i>
          </span> 
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="back-table centered"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
 
  <mat-paginator
  [length]="totalRows"
  [pageIndex]="currentPage"
  (page)="pageChanged($event)"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    showFirstLastButtons
  ></mat-paginator>

</div>