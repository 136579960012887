
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { map, of, switchMap, tap, combineLatest, forkJoin, Observable, iif, debounceTime } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Building } from 'src/app/models/building';
import { Request } from 'src/app/models/request';
import { Status } from 'src/app/models/status';
import { Subject } from 'src/app/models/subject';
import { SubjectsRelations } from 'src/app/models/subjectsRelations';
import { ContactReasonService } from 'src/app/services/contact-reason.service';
import { LibService } from 'src/app/services/libService';
import { NavService } from 'src/app/services/nav.service';
import { RequestService } from 'src/app/services/request.service';
import { StatusService } from 'src/app/services/status.service';
import { SubjectsRelationsService } from 'src/app/services/subjects-relations.service';
import { TranslatorService } from 'src/app/services/translate.service';
import { UserDataService } from 'src/app/services/users.service';
import { WikiService } from 'src/app/services/wikiservice.service';
import { UserSessionService } from 'src/app/services/user-session.service';
import { CompanyService } from 'src/app/services/companyService';
import { SubjectService } from 'src/app/services/subject.service';
import { BuildingService } from 'src/app/services/building.service';
import { CondominiumService } from 'src/app/services/condominium.service';
import { ClaimService } from 'src/app/services/claim.service';
import { TransactionsService } from 'src/app/services/transactions.service';
import { LegalService } from 'src/app/services/legal.service';
import { ComplaintService } from 'src/app/services/complaint.service';
import { Complaint } from 'src/app/models/complaint';
import { Claim } from 'src/app/models/claim';
import { Transaction } from 'src/app/models/transaction';
import { Legal } from 'src/app/models/legal';
import { ActivitiesService } from 'src/app/services/activities.service';
import { RoleService } from 'src/app/services/role.service';
import { OrderService } from 'src/app/services/order.service';
import { ItemCategoryService } from 'src/app/services/itemCategory.service';
import { StateService } from 'src/app/services/state.service';
import { ComponentService } from 'src/app/services/component.service';
import { Document, DocumentType } from 'src/app/models/document';

@Component({
  selector: 'app-request-detail',
  templateUrl: './request-detail.component.html',
  styleUrls: ['./request-detail.component.scss']
})
export class RequestDetailComponent implements OnInit {

  isNew: boolean;
  formRequest: FormGroup;
  statuses: Status[];
  selectedIndex: any;
  loggedUser: any;
  assignedUser$: any;
  users: any;
  operatorList: any[] = [];
  edit: boolean = false;
  save: boolean = true;
  hide: boolean = false;
  validationFields = ['telephone2', 'email'];
  companies: any;
  disableUpload: boolean = false;
  formValue: any;
  complaint: Complaint = {};
  claim: Claim = {};
  transaction: Transaction = {};
  legal: Legal = {};
  creaReclamo$: Observable<any>;
  reqAss$: Observable<any>;
  statusNew: any;
  companyLocal: any;
  resp: any;
  potenzialeSubject: any;
  potenzialeBuilding: any;
  clickButton: boolean = false;
  nullBuilding: boolean = false;

  documents: Document[];

  checkDuplicate$: Observable<any>;
  checkDuplicateSubject$: Observable<any>;
  checkDuplicateBuilding$: Observable<any>;

  components: any = null
  stateId: any = null

  requestType: any = null
  practiceCode: any = null
  complaintTypeSection: any = null
  pecId: any = null

  contactReasons: any = []

  public itemId: any;
  itemType: string = 'requestId';

  companies$ = this.companyService.getAllCompaniesForRequest();

  loggedUser$ = this.userSessionService.getUser(this.authService.user().email).pipe(
    map((val) => this.loggedUser = val),
    tap(val => {
      if (this.formRequest.get('status')?.value === null || (this.formRequest.get('status')?.value as Status).code === 'NEW')
        this.formRequest.get('insertionOperatorRole')?.setValue(val[0].UsersProfilesRel?.find(profile => profile.name === 'BackOffice')?.name)
    }));

  id$ = this.route.paramMap.pipe(
    map(params => params.get('id')),
    tap(id => {
      this.isNew = id === '0' ? true : false;
      this.setDisabled
    })
  )

  request$ = this.id$.pipe(
    switchMap(id => { return id !== '0' ? this.requestService.getRequest(id!) : of(null) })
  )

  statusSubject$ = this.statusService.getById(7, "Subject");
  // statusBuilding$ = this.statusService.getById(2, "Building");
  statuses$ = this.statusService.getAll('Request')


  // this.potenzialeBuilding = this.statusesBuilding.find(status => status.code === 'SS2');
  // this.potenzialeSubject = this.statusesSubject.find(status => status.code === 'SS8');

  constructor(
    public navService: NavService,
    private requestService: RequestService,
    private relationService: SubjectsRelationsService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private translate: TranslatorService,
    private toastr: ToastrService,
    private statusService: StatusService,
    private contactReasonService: ContactReasonService,
    private orderService: OrderService,
    private authService: AuthService,
    private libService: LibService,
    public wikiService: WikiService,
    private userService: UserDataService,
    private userSessionService: UserSessionService,
    private companyService: CompanyService,
    private cdr: ChangeDetectorRef,
    private claimService: ClaimService,
    private transactionService: TransactionsService,
    private legalService: LegalService,
    private complaintService: ComplaintService,
    private subjectService: SubjectService,
    private buildingService: BuildingService,
    private condominiumService: CondominiumService,
    private serviceStatus: StatusService,
    private activityService: ActivitiesService,
    private itemCategoryService: ItemCategoryService,
    private roleService: RoleService,
    private stateService: StateService,
    private componentService: ComponentService
  ) {

    this.formRequest = this.fb.group({
      id: null,
      version: 1,
      requestId: null,
      stateId: null,
      countryCode: null,
      itemCategoryId: null,
      societyId: null,
      companyId: null,
      marketingNoteId: null,
      pointId: [null, []],
      companyCode: null,
      businessLineId: null,
      businessLineCode: null,
      subjectTypeArray: null,
      contactReason: [null, Validators.required],
      contactReasonId: [null, Validators.required],
      subject: this.fb.group({
        id: [null, []],
        name: [null],
        surname: [null],
        legalEntity: [null, []],
        type: ['Edificio', []],
        vat: [null, []],
        personalFiscalCode: [null, []],
        marketingNoteId: [null],
        taxCode: [null, []],
        telephone1: [null, []], // fixTelephone
        telephone2: [null, []], // mobile
        email: [null, []],
        streetName: [null, []],
        streetNumber: [null, []],
        otherInfo: [null, []],
        city: [null, []],
        province: [null, []],
        state: [null, []],
        zip: [null, []],
        country: [null, []],
        coordX: [null, []],
        coordY: [null, []],
        officeCode: [null, []],
        isProspect: [null, []],
        subjectType: [null, []],
        subjectTypeArray: [[], []],
        condominiums: [null, []],
        subjectscondominiums: [null, []],
        adminAssociation: [null, []],
        subjectRatings: [null, []],
        status: [null, []],
        contacts: [null, []],
        clientStatus: ['Cliente', []],
        company: [null, []],
        companyLocation: [null, []],
        geographicArea: [null, []],
        geographicLocation: [null, []],
        responsible: [null, []],
        origin: [null, []],
        contactChannel: [null, []],
        administeredCondominiums: [null, []],
        albertCondominiums: [null],
        subscribedToFBGroup: [null, []],
        fbLink: [null, []],
        igLink: [null, []],
        linkedinLink: [null, []],
        twitterLink: [null, []],
        webSite: [null, []],
        gender: [null, []],
        birthdate: [null, []],
        contactPreference: [null, []],
        subscribedToEdacApp: [null, []],
        fax: [null, []],
        storicBuilding: [null, []],
        totAdministeredCondominiums: [null, []],
        notes: [null, []],
        businessName: [null, []],
        sysCodeDelta: [null, []],
        documents: [null, []],
        privacyConsent: [null, []],
        eaCourses: [null, []],
        appEa: [null, []],
        surveys: [null, []],
        components: [null, []],
      }),
      contact: null,
      building: this.fb.group({
        id: null,
        storicBuilding: null,
        type: 'Edificio',
        name: null,
        company: null,
        companyLocation: null,
        geographicArea: null,
        geographicLocation: null,
        streetName: null,
        streetNumber: null,
        otherInfo: null,
        city: null,
        province: null,
        state: null,
        zip: null,
        googlePlaceId: [null, []],
        formattedAddress: [null, []],
        neighborhood: [null, []],
        sublocality: [null, []],
        country: null,
        coordX: null,
        coordY: null,
        height: null,
        width: null,
        numberOfFloors: null,
        responsible: null,
        //condominium?: Condominiums
        sysCodeDelta: null,
        notes: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        documents: null,
        surveys: null,
        origin: null,
        contactChannel: null,
      }),
      order: this.fb.group({
        id: null,
        code: null,
        version: null,
        idStatus: null,
        nameAdminCondominius: null,
        nameBuilding: null,
        nameCondominius: null,
        nameSubject: null,
        statusDescription: null,
        practiceId: null
      }),
      orderSubject: null,
      condominium: null,
      admin: null,
      solicitReason: null,
      contactChannel: null,
      origin: null,
      inspectionContact: null,
      inspectionContactId: null,
      complaintTypeSection: null,
      complaintType: null,
      workType: null,
      notes: null,
      claimStatus: null,
      insertionOperator: this.authService.user().email,
      insertionOperatorRole: null,
      insertionDate: new Date,
      insertionLocation: null,
      assignmentOperator: null,
      assignmentDate: null,
      isLastVersion: null,
      status: null,
      activities: null,
      company: null,
      companyCity: null,
      solicitHandled: null,
      solicitRequest: this.fb.group({
        id: null,
        assignmentOperator: null,
        workType: null,
        nameBuilding: null,
        nameSubject: null
      }),
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
      buildingDuplicated: null,
      subjectDuplicated: null
    })
  }

  goToWiki() {
    window.open(this.wikiService.getWikiUrl(this.navService.getWikiKey()));
  }

  goToWikiByKey(k: any) {
    window.open(this.wikiService.getWikiUrl(k));
  }

  initItemCategoryId() {
    this.itemCategoryService.showByAttributes({ code: 'item_categories_requests' }).subscribe((response: any) => {
      console.log('initItemCategoryId')
      console.log(response.id)
      this.formRequest.get('itemCategoryId')?.setValue(response.id)
    })
  }

  initContactReasonId() {
    console.log('INIT CONTACT REASON')
    let contactReasonId = this.formRequest.get('contactReason')?.value?.id
    this.formRequest.get('contactReasonId')?.setValue(contactReasonId)
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.requestType = params['type']
      this.practiceCode = params['practiceCode']
      this.complaintTypeSection = params['complaintTypeSection']
      if(params['pecId']) this.pecId = Number(params['pecId'])
    });

    let makeHttpCall: boolean = true;

    // this.formRequest.get('assignmentOperator')?.valueChanges.subscribe(assOp => {
    //   console.log("[OPERATORE ASSEGNATO] - [Value Changes] -->", assOp);
    //   console.log("[Form  AssOp value] --> ", this.formRequest.get('assignmentOperator')?.value);
    // })

    this.selectedIndex = 0;
    this.initNavabar();
    let req: any;
    this.libService.lockPage('Loading Request')
    combineLatest([this.loggedUser$, this.request$, this.statuses$, this.companies$, this.serviceStatus.getById(1, "Claims"), this.statusSubject$]).subscribe({
      next: ([logUsr, request, statuses, companies, newStatus, sSubj]: any) => {
        this.potenzialeSubject = sSubj;
        this.statusNew = newStatus;
        this.statuses = statuses
        this.companies = companies;
        let build: any;
        console.log("[Utente Loggato] - [this.loggedUser] => ", this.loggedUser);
        if (!request) {
          this.formRequest.get('status')?.setValue(this.statuses.find(status => status.code === 'NEW'))

          let contactReasonId: any = null
          if (this.requestType) contactReasonId = Number(this.requestType)

          // RICHIESTA, SOLLECITO, RECLAMO
          if ([1, 2, 3].includes(contactReasonId)) {
            console.log(this.requestType)
            console.log(this.practiceCode)
            this.formRequest.get('contactReasonId')?.setValue(contactReasonId)
            this.contactReasonService.getAll().subscribe((response: any) => {
              this.contactReasons = response
              let currentContactReason = response.find((reason: any) => reason.id == contactReasonId)
              this.formRequest.get('contactReason')?.setValue(currentContactReason)
            })
          }

          if (this.complaintTypeSection) this.formRequest.get('complaintTypeSection')?.setValue(this.complaintTypeSection)


        } else {
          req = request;
          console.log("request --> ", req);
          this.permissionDocumentUpload();
          this.formRequest.patchValue({ building: req.building })
          //this.formRequest.patchValue({ subject: req.subject })
          build = this.formRequest.get('building')?.value;
          console.log("BUILDING DELLA RICHIESTA --> ", build);
          if (build.company) {
            this.formRequest.get('building')?.get('company')?.disable({ onlySelf: true, emitEvent: false });
          }
          if (req.contactReason.id === 2) {
            this.patchSolicit(req);

          }
          this.itemId = request?.id
        }

        // Blocca campo type della richiesta
        console.log('Blocca campo type della richiesta')
        console.log(this.requestType)
        if (this.requestType) this.formRequest.get('contactReason')?.disable({ emitEvent: false })

        build?.id !== null && build?.id !== undefined ?
          this.nullBuilding = false :
          this.nullBuilding = true;
        this.nullBuilding === false && this.isNew === false ?
          this.checkVisibility() :
          this.nullBuilding = true;


        //this.alertSubjectRequest();





        this.formRequest.patchValue(request);

        // INIZIALIZZAZIONE FORM
        console.log(request)
        if (!this.isNew) {
          this.stateId = request.stateId
          this.initForm(request.stateId)
        } else {
          this.initForm(null)
          this.libService.unlockPage();
        }



      },
      error: (error) => {
        console.log(error);
        this.libService.unlockPage()
      }
    })

    let prevCompany: string = 'prova';
    this.formRequest.get('building')?.valueChanges.subscribe(val => {
      // console.log("[AssignmentOperator FormRequest -->] ", this.formRequest.get('assignmentOperator')?.value);
      this.roleService.getMainRole(this.loggedUser);
      if (val.company !== null && val.company !== undefined && val.company !== prevCompany) {
        let sessionComp = this.userSessionService.getState(val.company);
        if (sessionComp !== null && sessionComp !== undefined) {
          this.companyLocal = sessionComp
          if (this.companyLocal.UsersCompaniesManagerRelId !== null && this.companyLocal.UsersCompaniesManagerRelId !== undefined) {
            this.userService.getUser(this.companyLocal.UsersCompaniesManagerRelId).subscribe(usr => {
              let ind: any;
              if (usr.role !== "EX_RISORSA") {
                ind = this.operatorList.indexOf(this.resp)
                ind > -1 ? this.operatorList.splice(ind, 1) : undefined;
                this.resp = usr;
                this.resp !== null && this.resp !== undefined ? this.operatorList.push(this.resp) : undefined;
                this.resp !== null && this.resp !== undefined && this.formRequest.get('assignmentOperator')?.value?.trim() === "" ?
                  this.formRequest.patchValue({ assignmentOperator: this.resp.email }) : undefined;
                this.userSessionService.saveState(this.resp.email, this.resp);
                console.log("RESPOSNSNSNSAL-->", this.resp);
              }
            })
          }
        }
        else {
          if ((val.company !== null && val.company !== undefined)) {
            this.companyService.getCompanyByCode(val.company).subscribe(com => {
              this.companyService.getCompanyOperational(com.id).subscribe(company => {
                this.companyLocal = company;
                this.userSessionService.saveState(this.companyLocal.code, this.companyLocal);
                r = this.userSessionService.getState(req?.assignmentOperator);
                console.log("R LOCAL VARIABLE --> ", r)
                r !== null && r !== undefined ? this.resp = r : undefined;
                if (this.companyLocal !== null && this.companyLocal !== undefined) {
                  if (this.companyLocal.UsersCompaniesManagerRelId !== null && this.companyLocal.UsersCompaniesManagerRelId !== undefined) {
                    this.userService.getUser(this.companyLocal.UsersCompaniesManagerRelId).subscribe(usr => {
                      let ind: any;
                      if (usr.role !== "EX_RISORSA") {
                        ind = this.operatorList.indexOf(this.resp)
                        ind > -1 ? this.operatorList.splice(ind, 1) : undefined;
                        this.resp = usr;
                        this.resp !== null && this.resp !== undefined ? this.operatorList.push(this.resp) : undefined;
                        this.resp !== null && this.resp !== undefined && this.formRequest.get('assignmentOperator')?.value?.trim() === "" ?
                          this.formRequest.patchValue({ assignmentOperator: this.resp.email }) : undefined;
                        this.userSessionService.saveState(this.resp.email, this.resp);
                        console.log("RESPOSNSNSNSAL-->", this.resp);
                      }
                    })
                  }
                }
              })
            });
          }
        }
        let r;
      }
      prevCompany === 'prova' ? this.handleUsers(val) : undefined;

      let acceptedCompanies = this.loggedUser[0].UsersCompaniesRel.map((a: any) => a.code);

      if (val.company !== null && val.company !== undefined) {
        acceptedCompanies.includes(val.company) ? this.operatorList.push(this.loggedUser[0]) : undefined;
        if (val.company !== prevCompany) {
          prevCompany !== 'prova' ?
            this.handleUsers(val) : undefined;
          makeHttpCall = false;
        }
      }

      const index = this.operatorList.indexOf(this.loggedUser[0])
      index > -1 ? this.operatorList.splice(index, 1) : undefined;
      // this.operatorList.push(this.loggedUser[0]);
      this.cdr.detectChanges();

      if (this.isNew) {
        let building = this.formRequest.get('building')?.value;
        if (building.company && this.loggedUser[0].role === 'BackOffice') {
          let companyLocal = this.companies.find((company: any) => company.code === building.company);
          // PROVA IMPLEMENTAZIONE
          companyLocal !== null && companyLocal !== undefined ?
            this.companyService.getCompanyOperational(companyLocal.id).subscribe(operational => {
              console.log("companyLocal", companyLocal);
              console.log("OPERATIONAL COMPANY -->", operational);
            }) : undefined;

          this.resp !== null && this.resp !== undefined ?
            this.formRequest.patchValue({
              assignmentOperator: this.resp.email
            }) : undefined;
        }
      } else {
        if (this.clickButton === false) {
          // this.formRequest.getRawValue().assignmentOperator !== null && this.formRequest.getRawValue().assignmentOperator !== undefined &&
          // this.formRequest.getRawValue().assignmentOperator.trim() !== ''?
          this.formRequest.patchValue({ assignmentOperator: req?.assignmentOperator });
          // undefined;
        }

      }
      prevCompany = val.company;
    })

    this.initItemCategoryId()
    this.getDocuments()

  }

  initForm(stateId: any) {

    if (stateId) {
      this.stateService.getState(stateId).subscribe((response: any) => {
        console.log('init form')
        this.setComponents(response.components)
        this.initContactReasonId()
        this.libService.unlockPage();
      })
    } else {
      this.setComponents(null)
    }
  }

  setComponents(components: any) {
    //console.log('setComponents')
    if (this.components == null) this.components = { codes: [], settings: [] }
    if (components != null) {
      Object.keys(components).forEach(key => {
        let tmpComponent = {
          componentCode: key,
          attributes: components[key]?.attributes
        }
        this.components.codes.push(key)
        this.components.settings.push(tmpComponent)
      });
    }
  }

  checkComponent(componentCode: any) {
    return this.components?.codes?.includes(componentCode)
  }

  checkComponentOrNew(componentCode: any) {
    return this.components?.codes?.includes(componentCode) || this.isNew
  }

  getAttributes(componentCode: any) {
    let component = this.components?.settings?.find((component: any) => component.componentCode == componentCode)
    return component?.attributes
  }

  onTabChanged(e: any) {
    this.selectedIndex = e.index;
  }

  getErrorTooltip() {
    return this.translate.instant('LABEL.FORM_ERROR_VALITATION') + '\n' + this.getFormValidationErrors();
  }

  getFormValidationErrors() {
    let ret = '';

    let blocks: any = {
      form: this.formRequest,
      subject: this.formRequest.controls['subject'],
      building: this.formRequest.controls['building']
    }
    //console.log(blocks)
    Object.entries(blocks).forEach(block => {
      const [key, value]: any = block;

      let tmpErrors: any = []
      Object.entries(value.controls).forEach(control => {
        const [k, v]: any = control;


        var controlErrors: any = v?.errors;
        if (!(controlErrors == null)) {
          Object.keys(controlErrors).forEach(keyError => {
            let errorMessage = this.translate.instant('LABEL.CAMPO_VALIDAZIONE') + k + this.translate.instant('LABEL.ERRORE_VALIDAZIONE') + keyError + '\n';
            tmpErrors.push(errorMessage);
          });
        }

      });

      if (tmpErrors.length != 0) {
        ret += ' ' + key + ':\n';
        ret += tmpErrors.join('')
      }

    })

    return ret;
  }

  checkCompanyId() {
    if ([null, undefined].includes(this.formRequest.get('companyId')?.value)) {
      this.save = false
      this.toastr.error('It is necessary to indicate the company of the request', this.translate.instant('TOASTR.WARNING'), { enableHtml: true, timeOut: 10000 })
    }
  }

  checkDuplicated() {
    let buildingDuplicated: any = this.formRequest?.get('buildingDuplicated')?.value
    let subjectDuplicated: any = this.formRequest?.get('subjectDuplicated')?.value

    let buildingId: any = this.formRequest?.get('building')?.get('id')?.value
    let subjectId: any = this.formRequest?.get('subject')?.get('id')?.value

    let message = ''

    if (subjectDuplicated && subjectId == null) {
      this.save = false
      message += this.translate.instant('TOASTR.MESSAGE17') + (subjectDuplicated.name ? subjectDuplicated.name : '') + ' ' + (subjectDuplicated.surname ? subjectDuplicated.surname : '') + this.translate.instant('TOASTR.MESSAGE18');
    }
    if (buildingDuplicated && buildingId == null) {
      this.save = false
      message += this.translate.instant('TOASTR.MESSAGE19') + buildingDuplicated.name;
    }

    if (message != '') this.toastr.error(message, this.translate.instant('TOASTR.WARNING'), { enableHtml: true, timeOut: 10000 });
  }

  async update() {
    if (this.formRequest.get('id')?.value == null && this.formRequest.get('contactReason')?.value?.id === 1) {
      // CREAZIONE DEL WORKFLOW
      this.sendInitWorkflow()
    } else {
      this.libService.lockPage('');
      this.clickButton = true;
      if (this.formRequest.get('contactReason')?.value?.id === 2) {
        //Solicit Reason Update / Save
        this.updateSolicitReason();
      }
      if (this.formRequest.get('contactReason')?.value?.id === 1) {
        //Preventive Request Update / Save
        let duplicateSubject: any, duplicateBuilding: any, updateOrCreate$, obsProva;
        this.save === true ? this.setBuildingName() : undefined;
        this.save === true ? this.checkPhoneMail() : undefined;
        this.save === true ? this.requestAssignment() : undefined;
        this.save === true ? this.assignResponsibleSubject() : undefined;
        this.checkDuplicated()
        this.checkCompanyId()


        this.formRequest.controls['inspectionContact'].setValue({ id: this.formRequest.controls['inspectionContactId'].value })

        if (this.save === false) {
          this.save = true;
          this.clickButton = false;
          //this.checkVisibility();
          this.libService.unlockPage();
          return
        }

        // this.formRequest.get('building')?.get('name')

        if (this.isNew) {
          console.log("VALORE FORM AL SALVATAGGIO --> ", this.formRequest.getRawValue());
          (this.formRequest.get('subject')?.value !== undefined || this.formRequest.get('subject')?.value !== null) ?
            this.formRequest.patchValue({ subject: { status: this.potenzialeSubject } }) : null;
          (this.formRequest.get('building')?.value !== undefined || this.formRequest.get('building')?.value !== null) ?
            this.formRequest.patchValue({ building: { status: this.potenzialeSubject } }) : null;

          this.checkDuplicateSubject(this.formRequest.get('building')?.value);
          this.checkDuplicateSubject(this.formRequest.get('subject')?.value);

          (this.checkDuplicateSubject$ !== undefined && !this.checkDuplicateBuilding$ !== undefined) ?
            undefined :
            this.checkDuplicateSubject$ = of(undefined);
          (this.checkDuplicateBuilding$ !== undefined && !this.checkDuplicateSubject$ !== undefined) ?
            undefined :
            this.checkDuplicateBuilding$ = of(undefined);

          this.checkDuplicate$ = combineLatest([this.checkDuplicateSubject$, this.checkDuplicateBuilding$]).pipe(
            tap(([subj, build]) => {
              duplicateSubject = subj;
              duplicateBuilding = build;
            }),
            switchMap(([subject, building]) => {

              if ((subject === undefined || subject === null) && (building === undefined || building === null)) {
                //FA CREARE RICHIESTA
                return of(false);

              } else {
                //NON FA CREARE RICHIESTA
                return of(true);
              }
            })
          )

          this.setsOriginAndChannelValueForSubjectsAndBuildingsIfTheyAreEmpty()

          updateOrCreate$ = this.checkDuplicate$.pipe(
            switchMap(v => {
              return v === true ? of(null) : this.requestService.createRequest(this.clean(this.formRequest.getRawValue())).pipe(
                tap((request) => {
                  this.toastr.success(
                    this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'))
                  this.formRequest.patchValue(request)
                })
              );
            }));

        } else {

          this.setsOriginAndChannelValueForSubjectsAndBuildingsIfTheyAreEmpty()

          //UPDATE DELLA RICHIESTA
          console.log("VALORE FORM PRIMA DEL SALVATAGGIO --> ", this.formRequest.get('building')?.value);
          updateOrCreate$ = this.requestService.updateRequest(this.formRequest.get('id')?.value, this.clean(this.formRequest.getRawValue())).pipe(
            tap((request) => {
              this.toastr.success(
                this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
              this.formRequest.patchValue(request);
            })
          )
        }

        if (this.formRequest.get('assignmentOperator')?.value !== null && this.formRequest.get('assignmentOperator')?.value !== undefined) {
          this.save = true;
        } else {
          this.save = false;
          // this.toastr.error(, this.translate.instant('TOASTR.WARNING'), { enableHtml: true, timeOut: 10000 });
        }

        this.save = true;

        if (this.save) {
          console.log("SALVATAGGIO RIUSCITO LALALALA");
          this.userSessionService.saveState('getAllRequests', null);
          // let proceed = true;
          let message: string = '';
          let createOrUpdateRelations$ = updateOrCreate$.pipe(
            switchMap((request: Request) => {
              if (request !== null) {

                return this.createOrUpdateRelations(request.subject!, request.building!)
              } else {

                return of([])
              }
            })
          )

          try {

            createOrUpdateRelations$?.subscribe(([res1, res2]: any) => {
              if ((duplicateBuilding !== undefined && duplicateBuilding !== null) || (duplicateSubject !== undefined && duplicateSubject !== null)) {

                if (duplicateSubject !== undefined && duplicateSubject !== null) {
                  message = this.translate.instant('TOASTR.MESSAGE17') + (duplicateSubject.name ? duplicateSubject.name : '') + ' ' + (duplicateSubject.surname ? duplicateSubject.surname : '') + this.translate.instant('TOASTR.MESSAGE18');
                }
                if (duplicateBuilding !== undefined && duplicateBuilding !== null) {
                  message = message + this.translate.instant('TOASTR.MESSAGE19') + duplicateBuilding.name;
                }

                // this.toastr.error(message, this.translate.instant('TOASTR.WARNING'), { enableHtml: true, timeOut: 10000 });
                this.checkDuplicateSubject$ = of(undefined);
                this.checkDuplicateBuilding$ = of(undefined);
                // this.checkDuplicate$ = of(false);

              } else {
                console.log('relation created: ', res1, res2);
                this.handleUsers(this.formRequest.get('building')?.value);
                this.router.navigate(['request/' + this.formRequest.get('id')?.value]).then(_res => {
                  window.location.reload()
                })


              }
            })
          } catch {
          }

        } else {
          this.libService.unlockPage();
          console.log("SALVATAGGIO IMPEDITO");
        }
      }
      this.clickButton = false;
    }

  }

  createOrUpdateRelations(subject: Subject, building: Building) {
    let subjectRelations$ = this.relationService.getSubjectRelations(subject.id!)
    let buildingRelations$ = this.relationService.getSubjectRelations(building.id!)
    console.log("SOGGETTO E BUILDING --> ", subject, building);
    return combineLatest([subjectRelations$, buildingRelations$]).pipe(
      map(([subjectRelations, buildingRelations]) => {
        console.log("RELATIONS --> ", subjectRelations, buildingRelations);
        let mergedRelations = this.unique([...subjectRelations, ...buildingRelations], ['subjectTo', 'subjectFrom'])
        let mergedRelationsUnique = mergedRelations.filter((relation: SubjectsRelations) =>
        ((relation.subjectFrom === subject.id || relation.subjectFrom === building.id) &&
          (relation.subjectTo === building.id || relation.subjectTo === subject.id)))
        return mergedRelationsUnique;
      }),
      switchMap(relations => {
        let relationFromSubject = relations.find((relation: SubjectsRelations) => (relation.subjectFrom === subject.id && relation.subjectTo === building.id))
        let relationFromBuilding = relations.find((relation: SubjectsRelations) => (relation.subjectFrom === building.id && relation.subjectTo === subject.id))
        let relationfromSubject$ = of(null)
        let relationfromBuilding$ = of(null)
        if (!relationFromSubject) {
          let relation: SubjectsRelations = {
            subjectFrom: subject.id!.toString(),
            subjectFromType: subject.type,
            subjectTo: building.id!.toString(),
            subjectToType: building.type
          }
          relationfromSubject$ = this.relationService.createSubjectRelation(relation)
        }
        if (!relationFromBuilding) {
          let relation: SubjectsRelations = {
            subjectFrom: building.id!.toString(),
            subjectFromType: building.type,
            subjectTo: subject.id!.toString(),
            subjectToType: subject.type
          }
          relationfromBuilding$ = this.relationService.createSubjectRelation(relation)
        }

        return combineLatest([relationfromSubject$, relationfromBuilding$])
      })
    )
  }

  sendInitWorkflow() {
    this.libService.lockPage(this.translate.instant('LOADINGMESSAGES.salvataggioInCorso'));
    let attributes = {
      itemCategoryId: this.formRequest.get('itemCategoryId')?.value,
      countryCode: this.formRequest.get('countryCode')?.value,
      //societyId: this.formRequest.get('societyId')?.value,
      businessLineId: this.formRequest.get('businessLineId')?.value,
      contactReasonId: this.formRequest.get('contactReasonId')?.value,
      contactReason: this.formRequest.get('contactReason')?.value,

    }

    this.requestService.initWorkflow(attributes).subscribe(
      (res: any) => {
        console.log(res)
        this.router.navigate(['request/' + res.id]).then(_res => {
          window.location.reload()
        })
      },
      (err: any) => {
        this.libService.unlockPage();
        console.log(err)
      },

    );
  }

  updateComplaints() {

    this.formValue = this.formRequest.getRawValue();
    let subSubTabName: any = null;

    let defaultPECStructure = {
      id: null,
      practiceId: this.formValue?.order?.practiceId,
      pecType: null,
      pecTypeValue: null,
      description: null,
      authorId: this.loggedUser[0].id,
      emailPec: null,
      subject: this.formValue?.subject,
    }



    console.log("FORM VALUE ---> ", this.formValue);
    switch (this.formValue.complaintType.complaintType.toLowerCase()) {
      case 'danni a terzi': case 'danni a edificio': case 'danni subiti edac': case 'lesione a terzi': case 'infortunio sul lavoro':
        subSubTabName='claims'
        // CREAZIONE CLAIM
        console.log("CLAIM DA CREARE --> ", this.formValue);
        this.claim.startDate = new Date();
        this.claim.orderNumberEA = this.formValue.order.code;
        this.claim.orderVersion = this.formValue.order.version;
        this.claim.marshNumberClaim = "";
        this.claim.location = this.formValue.building.city;
        this.claim.province = this.formValue.building.province;

        this.claim.status = this.statusNew;
        this.claim.complainant = this.formValue?.subject?.id !== null ? this.formValue.subject : this.formValue.admin;
        this.claim.type = this.formValue.complaintType;

        // Setta PEC
        if (this.pecId) {
          this.claim.pec = { id: this.pecId };
        } else {
          this.claim.pec = defaultPECStructure;
        }


        this.creaReclamo$ = this.claimService.createClaim(this.claim);
        // this.claim.claimStatus =
        break;
      case 'richiesta legale pec': case 'richiesta legale giudiziale': case 'richiesta legale extragiudiziale':
        subSubTabName='legals'
        // CREAZIONE LEGAL
        console.log("LEGAL DA CREARE --> ", this.formValue);
        // valorizzare city e province
        this.legal.status = this.statusNew;
        this.legal.orderNumberEA = this.formValue.order.code;
        this.legal.company = this.formValue.company;
        this.legal.companyCity = this.formValue.building.city;
        this.legal.promotingSubject = this.formValue?.subject?.id !== null ? this.formValue.subject : this.formValue.admin;
        this.legal.year = new Date().getFullYear().toString();
        // Setta PEC
        if (this.pecId) {
          this.legal.pec = { id: this.pecId };
        } else {
          this.legal.pec = defaultPECStructure;
        }

        this.legal.province = this.formValue.building.province;

        this.creaReclamo$ = this.legalService.createLegal(this.legal);
        break;

      case 'accordi transitivi interni':
        subSubTabName='transactions'
        // CREAZIONE TRANSACTIONS
        console.log("TRANSACTIONS DA CREARE --> ", this.formValue);
        //valorizzare via, anno, città, provincia, nazione, CAP
        this.transaction.orderNumberEA = this.formValue.order.code;
        this.transaction.streetName = this.formValue.building.streetName;
        this.transaction.streetNumber = this.formValue.building.streetNumber;
        this.transaction.city = this.formValue.building.city;
        this.transaction.province = this.formValue.building.province;
        this.transaction.country = this.formValue.building.country;
        this.transaction.zip = this.formValue.building.zip;
        this.transaction.complainant = this.formValue?.subject?.id !== null ? this.formValue.subject : this.formValue.admin;
        this.transaction.damageYear = new Date().getFullYear().toString();

        // Setta PEC
        if (this.pecId) {
          this.transaction.pec = { id: this.pecId };
        } else {
          this.transaction.pec = defaultPECStructure;
        }

        this.creaReclamo$ = this.transactionService.createTransaction(this.transaction);
        break;
      case 'errore commerciale': case 'errore di lavorazione':
        subSubTabName='warranty'
        // CREAZIONE COMPLAINT + CREAZIONE ATTIVITA
        this.complaint.creationDate = new Date();
        this.complaint.company = this.formValue.company;
        this.complaint.orderNumberEA = this.formValue.order.code;
        this.complaint.orderVersion = this.formValue.order.version;
        let companyLocal = this.companies.find((company: any) => company.code === this.formValue.company);

        // this.complaint.managerArea = this.formValue.building.responsible;
        // this.complaint.deliberateValue =
        // this.complaint.totalHours
        // this.complaint.creationDate =
        this.complaint.complaintType = this.formValue.complaintType;
        this.complaint.status = this.statusNew;
        this.complaint.motherOrder = this.formValue.order.code;

        // Setta PEC
        if (this.pecId) {
          this.complaint.pec = { id: this.pecId };
        } else {
          this.complaint.pec = defaultPECStructure;
        }

        // let activity: any = {};
        // activity.owner = this.formValue.building.responsible;
        // activity.type = 'SOPRALLUOGO';
        // activity.reason = 'Pratiche';
        // activity.activityDate = new Date();
        // activity.category = 'PROOFING';
        // activity.branch = 'BUILDINGS';
        // activity.orderCode = this.formValue.order.code;
        // activity.activityStatus = { id: 1, code: 'DA_FARE', uiLabel: 'ACTIVITIES.DA_FARE' };

        // this.complaint.complaintStatus =
        this.creaReclamo$ = this.userService.getUser(companyLocal.UsersCompaniesAreaManagerRelId).pipe(
          map(val => this.complaint.managerArea = val.email),
          // switchMap(_val => this.activityService.createActivity(activity)),
          switchMap(_val => this.complaintService.createComplaint(this.complaint))
        );
        console.log("COMPLAINT DA CREARE --> ", this.complaint);
        break;
      default:
        break;
    }

    // if (this.formValue.order.idStatus === 66) {
    this.libService.lockPage('');
    this.creaReclamo$.subscribe(val => {
      this.toastr.success(this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
      let queryParams: any = {
        subTabName:'complaints',
        subSubTabName: subSubTabName
      }
      this.router.navigate(['crm/', { tabName: 'rapports' }], {queryParams: queryParams})
      this.libService.unlockPage();
      console.log("RECLAMO/TRANSACTION/SINISTRO/LEGAL CREATO --> ", val);
    })
    // } else {
    //   this.toastr.error(this.translate.instant('TOASTR.MESSAGE37'), this.translate.instant('TOASTR.WARNING'))
    // }

  }

  initNavabar() {
    setTimeout(() => {
      let actions: any;
      let tabName: any = 'requests'
      let contactReason: any = this.formRequest.get('contactReason')?.value
      if (contactReason?.id == 3) tabName = 'rapports'
      if (contactReason?.id == 2) tabName = 'rapports'

      actions = [
        { name: 'back', url: 'crm', params: { tabName: tabName } }
      ];
      // console.log('id: ', this.formSubject.get('id')?.value);

      let title = this.formRequest.get('id')?.value !== null ?
        this.translate.instant('LABEL.EDIT', { field: this.translate.instant('LABEL.REQUEST') }) :
        this.translate.instant('LABEL.NEW', { field: this.translate.instant('LABEL.REQUEST') })
      let subTitle = "EDAC - " + title
      this.navService.setActions(actions);
      this.navService.setTitle(title);
      this.navService.setSubTitle(subTitle);
    }, 1000);

  }

  private clean(obj: any) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
      if (typeof obj[propName] === 'object') {
        obj[propName] = this.clean(obj[propName])
      }
    }
    return obj
  }

  setDisabled() {
    this.formRequest.get('contactReason')?.disable({ emitEvent: false })
    this.formRequest.get('contactChannel')?.disable({ emitEvent: false })
  }

  setBuildingName() {
    let streetName = this.formRequest.get('building')?.get('streetName')?.value
    let streetNumber = this.formRequest.get('building')?.get('streetNumber')?.value
    this.formRequest.get('building')?.get('name')?.setValue(streetName + ' ' + streetNumber)
  }

  unique(arr: any, keyProps: string[]): any[] {
    const kvArray = arr.map((entry: any) => {
      const key = keyProps.map(k => entry[k]).join('|');
      return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
  }

  // INIZIO - CAMBIO OPERATORE ASSEGNATO RICHIESTA
  requestAssignment() {

    this.roleService.getMainRole(this.loggedUser);
    let username = this.loggedUser[0].name;
    let building = this.formRequest.get('building')?.value;
    let companyId = this.formRequest.get('companyId')?.value;
    let responsible: any;
    let companyLocal: any = this.companies.find((company: any) => company.id === companyId);
    let userProfiles = this.loggedUser[0].UsersProfilesRel.map((a: any) => a.name);


    let acceptedCompanies = this.loggedUser[0].UsersCompaniesRel.map((a: any) => a.code);
    if (this.formRequest.get('contactReason')?.value.id === 1) {
      switch (this.loggedUser[0].role) {
        case 'Tecnico Commerciale':
          //SE LA SEDE DEL TC è UGUALE A QUELLA DEL BUILDING ASSEGNA AL TC LOGGATO, SENNO AL RA DELLA SEDE DEL BUILDING
          (acceptedCompanies.includes(companyLocal?.code)) ?
            responsible = this.loggedUser[0] :
            responsible = this.resp;
          if (!responsible) {
            this.save = false;
            this.toastr.error(this.translate.instant('TOASTR.MESSAGE20'), this.translate.instant('TOASTR.WARNING'));
          } else {
            if (userProfiles.includes('BackOffice') || this.isNew || this.nullBuilding) {
              let assOp = this.formRequest.getRawValue().assignmentOperator;
              if (!(assOp !== null && assOp !== undefined && assOp.trim() !== ''))
                this.formRequest.patchValue({ assignmentOperator: responsible?.email });
            }
          }
          break;
        case 'BackOffice':
          // ASSEGNA SEMPRE AL RA DELLA SEDE DEL BUILDING
          responsible = this.resp;
          building.responsible ?
            this.formRequest.patchValue({ assignmentOperator: building.responsible }) : this.formRequest.patchValue({ assignmentOperator: responsible?.email })
          break;
        case 'Area Manager': case 'Responsabile Area': case 'Direzione': case 'Affiliato': case 'Responsabile Commerciale':
          // ASSEGNA A SE STESSO SE LA SEDE è UGUALE ALLA SEDE DELL'UTENTE LOGGATO, SENNO ASSEGNA ALL'RA DELLA SEDE DELL'UTENTE LOGGATO
          this.save = true;
          (acceptedCompanies.includes(companyLocal?.code)) ?
            responsible = this.loggedUser[0] :
            responsible = this.resp

          if (this.formRequest.get('assignmentOperator')?.value) {
          } else {
            (building.responsible ?
              this.formRequest.patchValue({ assignmentOperator: building.responsible }) :
              this.formRequest.patchValue({ assignmentOperator: responsible?.email }))
          }
          if (!responsible) {
            this.save = false;
            this.toastr.error(this.translate.instant('TOASTR.MESSAGE20'), this.translate.instant('TOASTR.WARNING'));
          }
          break;
        case 'Admin':
          //SE LA SEDE DEL USER è UGUALE A QUELLA DEL BUILDING ASSEGNA AL USER LOGGATO, SENNO AL RA DELLA SEDE DEL BUILDING
          this.save = true;
          (acceptedCompanies.includes(companyLocal?.code)) ? responsible = this.loggedUser[0] : responsible = this.resp;

          if (this.formRequest.get('assignmentOperator')?.value) {
          } else {
            (responsible ?
              this.formRequest.patchValue({ assignmentOperator: responsible?.email }) :
              undefined);
            if (!responsible) {
              this.save = false;
              this.toastr.error(this.translate.instant('TOASTR.MESSAGE20'), this.translate.instant('TOASTR.WARNING'));
            }
          }


          break;
        case '' || null || undefined:
          this.save = false;
          this.toastr.error("L'utente " + username + this.translate.instant('TOASTR.MESSAGE21'));
          this.formRequest.patchValue({ assignmentOperator: '' });
          break;

        default:
          // ASSEGNA SEMPRE AL RA DELLA SEDE DEL BUILDING
          // companyLocal = this.companies.find((company: any) => company.code === building.company);
          responsible = this.resp// companyLocal.CompaniesUsersRel.find((user: any) => user.id === companyLocal.UsersCompaniesManagerRelId);
          building.responsible ?
            this.formRequest.patchValue({ assignmentOperator: building.responsible }) : this.formRequest.patchValue({ assignmentOperator: responsible?.email })
          break;
        // this.save = false;
        // this.toastr.error("L'utente " + username + this.translate.instant('TOASTR.MESSAGE21'));
        // this.formRequest.patchValue({ assignmentOperator: '' });
        // break;
      }
    }

    console.log("post request assignment");
  }

  alertSubjectRequest() {
    this.formRequest.get('telephone2')?.value && this.formRequest.get('email')?.value ? undefined :
      this.toastr.warning(this.translate.instant('TOASTR.MESSAGE23'), this.translate.instant('TOASTR.WARNING'));
  }

  checkPhoneMail() {
    let count = 0;
    let subj = this.formRequest.getRawValue().subject;
    console.log("soggetto della richiesta ..> ", subj);
    if (subj.type !== 'Edificio' && subj.type !== 'Condominio') {
      console.log("SUBJECT DA SALVARE --> ", subj);
      this.validationFields.forEach(element => {
        if (subj[element] !== null && subj[element].toString().trim() !== "") {
          count++;
        }
      })
      console.log("COUNTER --> ", count)
      //SE COUNT > 0 TUTTO OK SENNO FARE ERRORE PER COMPILAZIONE MAIL/TELEFONO
      if (count > 0) {
        this.save = true
      } else {
        this.save = false;
        this.toastr.error(this.translate.instant('TOASTR.MESSAGE24'), this.translate.instant('TOASTR.WARNING'));
      }
    } else {
      this.save = true;
    }
  }

  handleUsers(building: any) {
    let acceptedCompanies: Array<any> = [];
    let operator = this.formRequest.get('assignmentOperator')?.value;
    let userCompanies = this.loggedUser[0].UsersCompaniesRel.map((comp: any) => comp.code)
    building.company !== null && building.company !== undefined ?
      acceptedCompanies.push(building.company) : undefined;
    console.log("ACCEPTED COMPANIES (detail) --> ", acceptedCompanies);

    // SOSTITUIRE CON CHIAMATA PER OTTENERE USERS DALLA COMPANY CODE
    acceptedCompanies.length > 0 ?
      this.userService.getByCompanyCode(acceptedCompanies).subscribe(comps => {
        console.log("USERS BY Company Code -->", comps);
        let pippo = comps.filter((user: any) => user.UsersProfilesRel.map((profile: any) => profile.id).includes(12) || user.UsersProfilesRel.map((profile: any) => profile.id).includes(13) || user.UsersProfilesRel.map((profile: any) => profile.id).includes(14) || user.UsersProfilesRel.map((profile: any) => profile.id).includes(64));
        console.log("USERS FILTRATI PER PROFILI --> ", pippo);
        this.operatorList = [... new Set(pippo.map((tag: any) => tag))];

        userCompanies.includes(acceptedCompanies[0]) ? this.operatorList.push(this.loggedUser[0]) : undefined;
        const index = this.operatorList.indexOf(this.loggedUser[0])
        index > -1 ? this.operatorList.splice(index, 1) : undefined;
        this.roleService.getMainRole(this.loggedUser);
        this.loggedUser.role !== 'BackOffice' ? this.operatorList.push(this.loggedUser[0]) : null;
        this.cdr.detectChanges();
      }) : undefined;
    this.formRequest.patchValue({ assignmentOperator: operator })

  }

  checkVisibility() {
    this.roleService.getMainRole(this.loggedUser);
    let companyLocal: any;
    let building = this.formRequest.getRawValue().building;
    let userProfiles = this.loggedUser[0].UsersProfilesRel.map((a: any) => a.name);
    let acceptedCompanies = this.loggedUser[0].UsersCompaniesRel.map((a: any) => a.code);
    if (this.loggedUser !== undefined) {
      switch (this.loggedUser[0].role) {
        case 'Tecnico Commerciale':
          companyLocal = this.companies.find((company: any) => company.code === building?.company);
          // companyLocal !== undefined?

          console.log("USERS PROFILES --> ", userProfiles);

          if (!this.isNew) {
            if (userProfiles.includes('BackOffice')) {
              // SOLO PER TC CHE HANNO ANCHE PROFILO BACKOFFICE
              this.edit = true;
              let building = this.formRequest.get('building')?.value;
              companyLocal = this.companies.find((company: any) => company.code === building?.company);
              Object.keys(this.formRequest.controls).forEach(key => {
                this.formRequest.get(key)?.disable({ emitEvent: false });
                this.formRequest.get('subject.company')?.disable();
              });
              if (acceptedCompanies.includes(companyLocal?.code) || this.formRequest.get('assignmentOperator')?.value === this.loggedUser[0].email) {
                this.formRequest.get('assignmentOperator')?.enable();
              }
            } else {
              this.formRequest.get('assignmentOperator')?.value === this.loggedUser[0].email ? undefined :
                Object.keys(this.formRequest.controls).forEach(key => {
                  this.formRequest.get(key)?.disable({ emitEvent: false });
                  this.formRequest.get('subject.company')?.disable();
                  this.edit = true;
                });
            }
          } else {
            this.formRequest.patchValue({ assignmentOperator: this.loggedUser.email })
          }
          // this.isNew ?
          //    : undefined;

          break;
        case 'BackOffice':
          if (!this.isNew) {
            this.edit = true;
            let building = this.formRequest.get('building')?.value;
            companyLocal = this.companies.find((company: any) => company.code === building?.company);
            Object.keys(this.formRequest.controls).forEach(key => {
              this.formRequest.get(key)?.disable({ emitEvent: false });
              this.formRequest.get('subject.company')?.disable();
            });
            if (acceptedCompanies.includes(companyLocal?.code) || this.formRequest.get('assignmentOperator')?.value === this.loggedUser[0].email) {
              this.formRequest.get('assignmentOperator')?.enable();
            }

          }
          break;

        case 'Responsabile Area': case 'Affiliato':
          companyLocal = this.companies.find((company: any) => company.code === building?.company);
          if (this.isNew === false) {
            acceptedCompanies.includes(companyLocal?.code) || this.formRequest.get('assignmentOperator')?.value === this.loggedUser[0].email ? undefined :
              Object.keys(this.formRequest.controls).forEach(key => {
                this.formRequest.get(key)?.disable({ emitEvent: false });
                this.formRequest.get('subject.company')?.disable();
              })
          }

          break;
        case 'Area Manager': case 'Direzione':
          this.save = true;
          break;

        case 'Admin':
          this.save = true;
          this.edit = false;
          break;

        case 'Coordinatore':
          Object.keys(this.formRequest.controls).forEach(key => {
            this.formRequest.get(key)?.disable({ emitEvent: false });
            this.formRequest.get('subject.company')?.disable();
          })
          break;
        case 'Responsabile Commerciale': case 'Coordinatore Country': case 'Top Management': case 'Contabilita': case 'Amministrazione': case 'HR': case 'Responsabile Mkt':
          companyLocal = this.companies.find((company: any) => company.code === building?.company);
          if (this.isNew === false) {
            acceptedCompanies.includes(companyLocal?.code) || this.formRequest.get('assignmentOperator')?.value === this.loggedUser[0].email ? undefined :
              Object.keys(this.formRequest.controls).forEach(key => {
                this.formRequest.get(key)?.disable({ emitEvent: false });
                this.formRequest.get('subject.company')?.disable();
              })
          }
          break;
        case '' || null || undefined:
          this.save = false;

          break;

        default:
          this.save = false;

          break;
      }
    }
  }

  permissionDocumentUpload() {
    this.disableUpload = true;
    this.roleService.getMainRole(this.loggedUser);
    this.loggedUser[0].UsersProfilesRel.forEach((element: any) => {
      if (this.loggedUser[0].role === 'Tecnico Commerciale' ||
        this.loggedUser[0].role === 'Responsabile Area' ||
        this.loggedUser[0].role === 'Affiliato' ||
        this.loggedUser[0].role === 'Area Manager' ||
        this.loggedUser[0].role === 'Admin')
        this.disableUpload = false;
    });
  }

  assignResponsibleSubject() {
    let subject = this.formRequest.get('subject')?.value;
    let companyId = this.formRequest.get('companyId')?.value;
    subject.company = this.formRequest.get('subject.company')?.value;
    let building = this.formRequest.get('building')?.value;
    let responsible: any;
    let companyLocal: any;
    let acceptedCompanies = this.loggedUser[0].UsersCompaniesRel.map((a: any) => a.code);
    if (!subject.id) {
      console.log("SOGGETTO NUOVO --> ", subject);

      switch (this.loggedUser[0].role) {
        case 'Tecnico Commerciale':
          //SE LA SEDE DEL TC è UGUALE A QUELLA DEL BUILDING ASSEGNA AL TC LOGGATO, SENNO AL RA DELLA SEDE DEL BUILDING
          companyLocal = this.companies.find((company: any) => company.id === companyId);
          if (acceptedCompanies.includes(companyLocal?.code)) {
            responsible = this.loggedUser[0];
          } else {
            responsible = this.resp//companyLocal.CompaniesUsersRel.find((user: any) => user.id === companyLocal.UsersCompaniesManagerRelId);
          }
          this.formRequest.patchValue({ subject: { responsible: responsible?.email } })
          break;
        case 'BackOffice':
          // ASSEGNA SEMPRE AL RA DELLA SEDE DEL BUILDING
          companyLocal = this.companies.find((company: any) => company.id === companyId);
          responsible = this.resp//companyLocal.CompaniesUsersRel.find((user: any) => user.id === companyLocal.UsersCompaniesManagerRelId);
          this.formRequest.patchValue({ subject: { responsible: responsible?.email } })
          break;
        case 'Area Manager': case 'Responsabile Area': case 'Affiliato':
          // ASSEGNA A SE STESSO SE LA SEDE è UGUALE ALLA SEDE DELL'UTENTE LOGGATO, SENNO ASSEGNA ALL'RA DELLA SEDE DELL'UTENTE LOGGATO
          // this.save = true;
          companyLocal = this.companies.find((company: any) => company.id === companyId);

          if (acceptedCompanies.includes(companyLocal?.code)) {
            responsible = this.loggedUser[0];
          } else {
            responsible = this.resp// companyLocal?.CompaniesUsersRel.find((user: any) => user.id === companyLocal?.UsersCompaniesManagerRelId);
          }

          this.formRequest.patchValue({ subject: { responsible: responsible?.email } })
          break;

        case '' || null || undefined:
          // this.save = false;
          // this.toastr.error("L'utente " + username + " non possiede nessun ruolo, quindi non si puo salvare la richiesta corrente");
          this.formRequest.patchValue({ assignmentOperator: '' });
          break;

        default:
          companyLocal = this.companies.find((company: any) => company.id === companyId);
          responsible = this.resp//companyLocal.CompaniesUsersRel.find((user: any) => user.id === companyLocal.UsersCompaniesManagerRelId);
          this.formRequest.patchValue({ subject: { responsible: responsible?.email } })
          break;
      }
    }
    if (!building.id) {
      switch (this.loggedUser[0].role) {
        case 'Tecnico Commerciale':
          //SE LA SEDE DEL TC è UGUALE A QUELLA DEL BUILDING ASSEGNA AL TC LOGGATO, SENNO AL RA DELLA SEDE DEL BUILDING
          companyLocal = this.companies.find((company: any) => company.id === companyId);
          if (acceptedCompanies.includes(companyLocal?.code)) {
            responsible = this.loggedUser[0];
          } else {
            responsible = this.resp//companyLocal.CompaniesUsersRel.find((user: any) => user.id === companyLocal.UsersCompaniesManagerRelId);
          }
          this.formRequest.patchValue({ building: { responsible: responsible?.email } })
          break;
        case 'BackOffice':
          // ASSEGNA SEMPRE AL RA DELLA SEDE DEL BUILDING
          companyLocal = this.companies.find((company: any) => company.id === companyId);
          responsible = this.resp//companyLocal.CompaniesUsersRel.find((user: any) => user.id === companyLocal.UsersCompaniesManagerRelId);
          this.formRequest.patchValue({ building: { responsible: responsible?.email } })
          break;
        case 'Area Manager': case 'Responsabile Area': case 'Admin': case 'Affiliato':
          // ASSEGNA A SE STESSO SE LA SEDE è UGUALE ALLA SEDE DELL'UTENTE LOGGATO, SENNO ASSEGNA ALL'RA DELLA SEDE DELL'UTENTE LOGGATO
          // this.save = true;
          companyLocal = this.companies.find((company: any) => company.id === companyId);
          if (acceptedCompanies.includes(companyLocal?.code)) {
            responsible = this.loggedUser[0];
          } else {
            responsible = this.resp//companyLocal.CompaniesUsersRel.find((user: any) => user.id === companyLocal.UsersCompaniesManagerRelId);
          }
          this.formRequest.patchValue({ building: { responsible: responsible?.email } })
          break;

        case '' || null || undefined:
          // this.save = false;
          this.formRequest.patchValue({ assignmentOperator: '' });
          break;

        default:
          companyLocal = this.companies.find((company: any) => company.id === companyId);
          responsible = this.resp//companyLocal.CompaniesUsersRel.find((user: any) => user.id === companyLocal.UsersCompaniesManagerRelId);
          this.formRequest.patchValue({ building: { responsible: responsible?.email } })
          break;
      }
    }
  }

  checkDuplicateSubject(subject: any) {
    // let subject = this.formRequest.getRawValue();
    console.log("SUBJCT: CONTROLLARE IL TIPO --> ", subject)
    if (subject.id === null) {
      switch (subject.type) {
        case 'Edificio':
          // this.checkDuplicateSubject$ = this.buildingService.findByAddress(subject);
          this.checkDuplicateBuilding$ = this.buildingService.findByAddress(subject);
          break;
        case 'Condominio':
          this.checkDuplicateSubject$ = this.condominiumService.findCondominiumByAddress(subject);
          break;
        case 'Amministratore':
          this.checkDuplicateSubject$ = this.subjectService.findSubjectByPhoneAndMail(subject);
          break;
        default:
          this.checkDuplicateSubject$ = this.subjectService.findSubjectByPhoneAndMail(subject);
          break;
      }
    }
  }

  hideSaveButton(e: any) {
    console.log("EVENT EMITTER TO HIDE BUTTON --> ", e);
    this.hide = e;
  }

  updateSolicitReason() {
    //Logica di Salvataggio per i solleciti
    let solicit: any;
    console.log("[Salvataggio Sollecito Prenventivo]");
    this.formValue = this.formRequest.getRawValue();

    solicit = this.formValue;
    solicit.orderId = solicit.order.id;
    solicit.requestId = solicit.solicitRequest.id;
    solicit.solicitHandled === true ?
      solicit.status = this.statuses.find(status => status.code === 'HANDLED') :
      solicit.status = this.statuses.find(status => status.code === 'NEW_SOLICIT');



    if (this.isNew) {
      delete solicit.deletedAt; delete solicit.updatedAt; delete solicit.createdAt;
      this.libService.lockPage('');
      solicit.solicitHandled = false;
      this.requestService.createRequest(solicit).subscribe(req => {
        this.toastr.success(this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
        this.router.navigate(['crm/', { tabName: 'requests' }])
        this.libService.unlockPage();
        console.log("[Richiesta Creata:] -> ", req);
      });
    } else {
      delete solicit.subject.deletedAt; delete solicit.subject.updatedAt; delete solicit.subject.createdAt;
      this.libService.lockPage('');
      this.requestService.updateRequest(solicit.id, solicit).subscribe(req => {
        console.log("[Richiesta Upadatata:] -> ", req)
        this.toastr.success(this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
        this.router.navigate(['crm/', { tabName: 'requests' }])
        this.libService.unlockPage();
      })
    }
  }

  async patchOrder(req: any) {
    if (req.orderId !== null && req.orderId !== undefined) {
      this.orderService.getOrderDetail(req.orderId).subscribe(order => {
        console.log("[Order Solicit] -> ", order);
        this.formRequest.patchValue({
          order: order
        });
      })
    }
  }

  patchSolicit(req: any) {
    this.formRequest.patchValue(req);
    req.orderId !== null && req.orderId !== undefined ?
      this.patchOrder(req) : null;
    req.requestId !== null && req.requestId !== undefined ?
      this.patchRequest(req.requestId) : null;
  }

  patchRequest(id: any) {
    this.requestService.getRequest(id).subscribe(req => {

      this.formRequest.patchValue({
        subject: req.subject,
        building: req.building,
        solicitRequest: {
          id: req.id,
          assignmentOperator: req?.assignmentOperator,
          workType: req.workType?.workType,
          nameBuilding: req.building?.name !== null && req.building?.name !== undefined ? req.building.name : '',
          nameSubject: req.subject?.name !== null && req.subject?.name !== undefined ? req.subject.name : ''
        }
      })
    })
  }

  setsOriginAndChannelValueForSubjectsAndBuildingsIfTheyAreEmpty() {

    // if subject contactChannel is blank -> Set subject contactChannel = request contactChannel
    if ([null, undefined].includes(this.formRequest.get('subject')?.value.contactChannel)) {
      this.formRequest.get('subject')?.get('contactChannel')?.setValue(this.formRequest.value.contactChannel)
    }

    // if subject origin is blank -> Set subject origin = request origin
    if ([null, undefined].includes(this.formRequest.get('subject')?.value.origin)) {
      this.formRequest.get('subject')?.get('origin')?.setValue(this.formRequest.value.origin)
    }

    // if building contactChannel is blank -> Set building contactChannel = request contactChannel
    if ([null, undefined].includes(this.formRequest.get('building')?.value.contactChannel)) {
      this.formRequest.get('building')?.get('contactChannel')?.setValue(this.formRequest.value.contactChannel)
    }

    // if building origin is blank -> Set building origin = request origin
    if ([null, undefined].includes(this.formRequest.get('building')?.value.origin)) {
      this.formRequest.get('building')?.get('origin')?.setValue(this.formRequest.value.origin)
    }

  }

  checkNecessaryDocuments() {
    let key = 'request_document_manager'
    if (!this.checkComponent(key)) key = 'crmDocumentManager'
    let requestDocumentManagerAttributes = this.getAttributes(key)
    return this.componentService.checkNecessaryDocuments(requestDocumentManagerAttributes, this.formRequest).status
  }

  getDocuments() {
    this.formRequest.get('building')?.get('documents')?.valueChanges.subscribe((documents: Document[]) => {
      this.documents = documents?.filter(document => document?.requestId === this.formRequest.get('id')?.value)
    })
  }

}
