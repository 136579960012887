import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { AssociationSubjectComponent } from './association-subject/association-subject.component';
import { AssociationBuildingComponent } from './association-building/association-building.component';
import { AssociationSubjectV2Component } from './association-subject-v2/association-subject-v2.component';
import { AssociationBuildingV2Component } from './association-building-v2/association-building-v2.component';
import { WorkflowConfiguratorComponent } from './workflow-configurator/workflow-configurator.component';
import { RequestSearchPracticeRequestComponent } from './request-search-practice-request/request-search-practice-request.component';
import { RequestLinkPracticeComponent } from './request-link-practice/request-link-practice.component';
import { PracticeHistoryComponent } from './practices-history/practices-history.component';
import { RequestFormComponent } from './request-form/request-form.component';
import { RequestDocumentManagerComponent } from './request-document-manager/request-document-manager.component';
import { PracticeWorkflowManagerComponent } from './practice-workflow-manager/practice-workflow-manager.component';
import { CrmDocumentManagerComponent } from './crm-document-manager/crm-document-manager.component';
import { PracticeButtonComplaintOpeningComponent } from './practice-button-complaint-opening/practice-button-complaint-opening.component';
import { RequestCloseButtonComponent } from './request-close-button/request-close-button.component';
import { RequestCancelButtonComponent } from './request-cancel-button/request-cancel-button.component';
import { ChangeStateButtonComponent } from './change-state-button/change-state-button.component';
import { PracticeStateMigrationComponent } from './practice-state-migration/practice-state-migration.component';
import { PlanningButtonSetNonProgrammableComponent } from './planning-button-set-non-programmable/planning-button-set-non-programmable.component';
import { PlanningButtonRestorePreviuousStateComponent } from './planning-button-restore-previuous-state/planning-button-restore-previuous-state.component';
import { RequestCustomerContactedButtonComponent } from './request-customer-contacted-button/request-customer-contacted-button.component';
import { GenericFileUploaderComponent } from './generic-file-uploader/generic-file-uploader.component';
import { FullTextSearchModule } from '../fulltextsearch2/fulltextsearch.module';
import { SharedModule } from '../shared/shared.module';
import { ModalCloseRequest } from './modals/modal-close-request/modal-close-request.component'
import { GenericModal } from './modals/generic-modal/generic-modal.component'
import { IframeModal } from './modals/iframe-modal/iframe-modal.component'
import { ComponentsFileUploadModalComponent } from './modals/components-file-upload-modal/components-file-upload-modal.component'
import { FileDocumentTypeTableComponent } from './modals/components-file-upload-modal/file-document-type-table/file-document-type-table.component'
import { MatDialogModule } from '@angular/material/dialog';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatRadioModule} from '@angular/material/radio';
import {MatTabsModule} from '@angular/material/tabs';

const MATERIAL_MODULES = [
  MatTableModule,
  MatPaginatorModule,
  MatButtonModule,
  MatSortModule,
  MatIconModule,
  MatInputModule,
  MatFormFieldModule,
  MatExpansionModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatSelectModule,
  MatCardModule,
  FullTextSearchModule,
  MatDialogModule,
  MatSlideToggleModule,
  MatListModule,
  MatGridListModule,
  MatRadioModule,
  MatTabsModule
]

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AssociationSubjectComponent,
    AssociationBuildingComponent,
    AssociationSubjectV2Component,
    AssociationBuildingV2Component,
    WorkflowConfiguratorComponent,
    RequestFormComponent,
    RequestDocumentManagerComponent,
    PracticeWorkflowManagerComponent,
    CrmDocumentManagerComponent,
    PracticeButtonComplaintOpeningComponent,
    GenericFileUploaderComponent,
    RequestCloseButtonComponent,
    RequestCancelButtonComponent,
    ChangeStateButtonComponent,
    PracticeStateMigrationComponent,
    PlanningButtonSetNonProgrammableComponent,
    PlanningButtonRestorePreviuousStateComponent,
    RequestCustomerContactedButtonComponent,
    ModalCloseRequest,
    GenericModal,
    IframeModal,
    ComponentsFileUploadModalComponent,
    FileDocumentTypeTableComponent,
    RequestSearchPracticeRequestComponent,
    RequestLinkPracticeComponent,
    PracticeHistoryComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    FormsModule,
    HttpClientModule,
    FlexLayoutModule,
    NgxMatSelectSearchModule,
    TranslateModule.forChild(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        },
      }
    ),
    MATERIAL_MODULES
  ],
  exports: [
    AssociationSubjectComponent,
    AssociationBuildingComponent,
    AssociationSubjectV2Component,
    AssociationBuildingV2Component,
    WorkflowConfiguratorComponent,
    RequestFormComponent,
    RequestDocumentManagerComponent,
    PracticeWorkflowManagerComponent,
    CrmDocumentManagerComponent,
    PracticeButtonComplaintOpeningComponent,
    GenericFileUploaderComponent,
    RequestCloseButtonComponent,
    RequestCancelButtonComponent,
    ChangeStateButtonComponent,
    PracticeStateMigrationComponent,
    PlanningButtonSetNonProgrammableComponent,
    PlanningButtonRestorePreviuousStateComponent,
    RequestCustomerContactedButtonComponent,
    ModalCloseRequest,
    GenericModal,
    IframeModal,
    ComponentsFileUploadModalComponent,
    FileDocumentTypeTableComponent,
    RequestSearchPracticeRequestComponent,
    RequestLinkPracticeComponent,
    PracticeHistoryComponent
  ]
})
export class ComponentsModule { }
