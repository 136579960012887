import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { UserSessionService } from './user-session.service';
import { GuardService } from './guard.service';
import { AuthService } from '../auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class ComponentService extends BaseService {

  actionVisibilities: any = []

  constructor(
    public LibService: LibService,
    public AuthService: AuthService,
    public userSessionService: UserSessionService,
    public guardService: GuardService
  ) {
    super(LibService, AuthService)
    this.baseApi = environment.rootApiFoundation
    this.model = 'components'
  }

  full(): Observable<any> {
    return this.libService.getDataWithAccessToken<any>(this.tokenId, this.baseApi, `${this.model}/full`);
  }

  checkPermissions(actionKey: any): boolean {
    this.actionVisibilities = this.userSessionService.getActionCodes()
    return this.actionVisibilities.includes(actionKey)
  }

  getAttributes(field: any, attributes: any) {
    return attributes[field]
  }

  checkVisibility(field: any, attributes: any) {
    return attributes.hasOwnProperty(field)
  }

  checkEditability(field: any, attributes: any) {
    let status = false
    if (attributes.hasOwnProperty(field) && attributes[field].find((f: any) => f.editable == true)) status = true
    return status
  }

  mandatoryVerification(field: any, attributes: any) {
    let status = false
    if (attributes.hasOwnProperty(field) && attributes[field].find((f: any) => f.required == true)) status = true
    return status
  }

  checkNecessaryDocuments(requestDocumentManagerAttributes: any, form: any) {
    //console.log('checkNecessaryDocuments')
    let response: any = {}
    let structure: any = {}
    let status: any = true
    let documents = form?.get('building')?.get('documents')?.value || []
    //console.log(documents)

    if (![undefined, null].includes(requestDocumentManagerAttributes)) {
      Object.entries(requestDocumentManagerAttributes).forEach(attributes => {

        const [key, value]: any = attributes;

        let tmpStatus: any = true

        let requestId: any = form?.get('id')?.value
        let currentDocuments = []
        let settings: any = this.getSettings(value)
        let required: any = settings.required || false // Se non è presente il campo required, di default viene settato a FALSE
        let min: any = settings.min || 0
        let max: any = settings.max || null
        let code: any = settings.code || null

        if (required && min == 0) min = 1 // Se non è impostato un valore minimo ma è un doc obbligatorio, setto il minimo a 1

        structure[key] = {
          min: min,
          required: required,
          max: max
        }

        //console.log('---')
        //console.log(key)
        //console.log(settings)

        // Verifico quali documenti sono delle medesima tipologia del caricatore di documenti
        documents.forEach((doc: any) => {
          if (doc.typeName.id == settings.documentTypeId && doc.requestId == requestId) currentDocuments.push(doc)
        })

        // Se il documento è richiesto verifico la quantità caricata. 
        // Se non è presente il numero minimo, ritorno FALSE
        let total: any = currentDocuments.length
        tmpStatus = required ? total >= min : true
        structure[key].status = tmpStatus
        structure[key].total = total

        if (tmpStatus == false) status = tmpStatus
        //console.log(status)
      })
    } else {
      status = false
    }

    response.status = status
    response.structure = structure

    return response

  }


  checkDocumentsStatus(key: any = 'request_document_manager', attributes: any, form: any, documents: any = []) {

    if (key == 'crmDocumentManager') {
      let documentList = attributes?.documentList || []
      let tmpStructure: any = {}
      documentList.forEach((doc: any) => {
        let key = `document${doc.documentTypeCode}`
        if (!tmpStructure[key]) tmpStructure[key] = []
        tmpStructure[key].push(doc)
      })
      return this.checkNecessaryDocumentsForPractices(documents, tmpStructure, form).status
    } else {
      return this.checkNecessaryDocuments(attributes, form).status
    }

  }


  checkNecessaryDocumentsForPractices(documents: any, requestDocumentManagerAttributes: any, form: any, order: any = null) {
    //console.log('checkNecessaryDocuments')
    let response: any = {}
    let structure: any = {}
    let status: any = true
    // let documents = form?.get('building')?.get('documents')?.value || []
    //console.log(documents)

    if (![undefined, null].includes(requestDocumentManagerAttributes)) {
      Object.entries(requestDocumentManagerAttributes).forEach(attributes => {

        const [key, value]: any = attributes;

        let tmpStatus: any = true

        let requestId: any = form?.get('id')?.value
        let currentDocuments: any = []
        let settings: any = this.getSettings(value)
        let required: any = settings.required || false // Se non è presente il campo required, di default viene settato a FALSE
        let min: any = settings.min || 0
        let max: any = settings.max || null
        let code: any = settings.code || null

        if (required && min == 0) min = 1 // Se non è impostato un valore minimo ma è un doc obbligatorio, setto il minimo a 1

        structure[key] = {
          min: min,
          required: required,
          max: max
        }

        // Verifico quali documenti sono delle medesima tipologia del caricatore di documenti
        if (documents) {
          documents.forEach((doc: any) => {
            if (doc.typeName.code == settings.documentTypeCode && doc.orderVersion == order?.version) currentDocuments.push(doc)
          })
        }

        // Se il documento è richiesto verifico la quantità caricata. 
        // Se non è presente il numero minimo, ritorno FALSE
        let total: any = currentDocuments.length
        tmpStatus = required ? total >= min : true
        structure[key].status = tmpStatus
        structure[key].total = total

        if (tmpStatus == false) status = tmpStatus
        //console.log(status)
      })
    } else {
      status = false
    }

    if (Object.keys(structure).length == 0) status = true

    response.status = status
    response.structure = structure

    return response

  }

  getSettings(attributes: any) {
    let tmpSettings: any = {}
    attributes.forEach((a: any) => {
      Object.entries(a).forEach(attr => {
        const [key, value]: any = attr;
        tmpSettings[key] = value;
      })
    })
    return tmpSettings
  }

  getStructure(componentAttributes: any) {
    let response: any = []
    Object.entries(componentAttributes).forEach(attributes => {
      const [key, value]: any = attributes;
      let settings: any = this.getSettings(value)
      settings.code = key;
      response.push(settings)
    })
    return response
  }

  getObjectStructure(componentAttributes: any) {
    let response: any = {}
    Object.entries(componentAttributes).forEach(attributes => {
      const [key, value]: any = attributes;
      let tmpStructure: any = {}
      value.forEach((val: any) => {

        if ('groupCode' in val) {
          if (response[key] == null) response[key] = []
          if (tmpStructure[val.groupCode] == null) tmpStructure[val.groupCode] = {}
          Object.entries(val).forEach(attributes => {
            const [k, v]: any = attributes;
            tmpStructure[val.groupCode][k] = v
          })

        } else {

          if (response[key] == null) response[key] = {}
          Object.entries(val).forEach(attributes => {
            const [k, v]: any = attributes;
            response[key][k] = v
          })

        }
      })

      Object.entries(tmpStructure).forEach(attributes => {
        const [k, v]: any = attributes;
        response[key].push(v)
      })
    })

    return response
  }

}