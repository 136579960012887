import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { TranslatorService } from 'src/app/services/translate.service';

@Component({
    selector: 'planner-riepilogo-dialog',
    templateUrl: './planner-riepilogo-dialog.html',
    styleUrls: ['./planner-riepilogo-dialog.scss'],
})
export class plannerRiepilogoDialogComponent implements OnInit {

    displayDate: string;
    displayDataRange: string;
    startDate: any = this.data.startDate;
    endDate: any = this.data.endDate;
    operators: { code: string; name: string; role: string }[] = [];
    displayedColumns: string[] = ['code', 'name', 'role']; // Aggiungi qui le colonne da visualizzare
    noOrdersMessage: string; // Aggiungi questa variabile per il messaggio



    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private toastr: ToastrService,
        public translate: TranslatorService
    ) { }

    ngOnInit(): void {
        this.updateDisplayDate();
        this.processSummary(this.data.summary);
    }

    // Metodo per aggiornare il display della data
    updateDisplayDate(): void {

        this.displayDataRange = `<strong>${this.formatDateWithMoment(this.data.dataRangeStart)}</strong> - <strong>${this.formatDateWithMoment(this.data.dataRangeEnd)}</strong>`;

        // Condizione per determinare quale messaggio visualizzare
        const isInRange = moment(this.startDate).isBetween(this.data.dataRangeStart, this.data.dataRangeEnd, 'day', '[]') &&
            moment(this.endDate).isBetween(this.data.dataRangeStart, this.data.dataRangeEnd, 'day', '[]');

        if (isInRange) {
            this.noOrdersMessage = this.translate.instant('PLANNER_RIEPILOGO.NO_PLANNED_ORDERS');
        } else {
            this.noOrdersMessage = `${this.translate.instant('PLANNER_RIEPILOGO.OUT_OF_RANGE')} ${this.displayDataRange}`;
        }

        const dateDifference = this.getDateDifference(this.startDate, this.endDate);

        if (dateDifference > 5) {
            this.displayDate = `${this.formatDate(this.startDate)} - ${this.formatDate(this.endDate)}`;
        } else {
            this.displayDate = `${this.formatDate(this.startDate)}`;
        }

        this.displayDataRange = `${this.formatDateWithMoment(this.data.dataRangeStart)} - ${this.formatDateWithMoment(this.data.dataRangeEnd)}`;
    }

    processSummary(summary: any): void {
        const uniqueOperatorsSet = new Set<string>();
        const uniqueDisplaySet = new Set<string>();
        const allOperators: { code: string; name: string; role: string }[] = [];

        // Itera su tutte le chiavi (date) nel summary
        Object.keys(summary).forEach(dateKey => {
            const data = summary[dateKey];

            // Itera su tutte le commesse per ogni data
            data.commesse.forEach((commessa: any) => {
                // Itera sugli operatori all'interno di ciascuna commessa
                commessa.operators.forEach((operator: { name: string, role: string }) => {
                    const operatorData = {
                        code: commessa.code, // Codice della commessa associata
                        name: operator.name,
                        role: operator.role
                    };

                    // Crea una chiave unica per evitare duplicati basata su commessa, nome e ruolo
                    const uniqueKeyForDisplay = `${operatorData.code}-${operatorData.name}-${operatorData.role}`;

                    // Aggiungi all'array per la visualizzazione solo se non è già nel Set
                    if (!uniqueDisplaySet.has(uniqueKeyForDisplay)) {
                        uniqueDisplaySet.add(uniqueKeyForDisplay);
                        allOperators.push(operatorData);
                    }

                    // Aggiungi al Set per il conteggio univoco degli operatori (basato solo su nome)
                    if (!uniqueOperatorsSet.has(operator.name)) {
                        uniqueOperatorsSet.add(operator.name);
                    }
                });
            });
        });
        // Ordinamento per codice commessa
        allOperators.sort((a, b) => a.code.localeCompare(b.code));


        // Assegna gli operatori totali da visualizzare
        this.operators = allOperators;
    }




    get uniqueOperatorCount(): number {
        return new Set(this.operators.map(op => op.name)).size;
    }

    // Metodo per calcolare la differenza tra due date
    getDateDifference(date1: Date, date2: Date): number {
        const timeDifference = Math.abs(date2.getTime() - date1.getTime());
        return Math.ceil(timeDifference / (1000 * 3600 * 24));
    }
    formatDateWithMoment(date: Date | string): string {
        return moment(date).format('DD/MM/YYYY');
    }

    // Metodo per formattare la data in 'gg/mm/yyyy'
    formatDate(date: Date): string {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }
    getTranslatedRole(role: string): string {
        return this.translate.instant(`PLANNER_RIEPILOGO.ROLES.${role}`);
    }
}

interface DialogData {
    startDate: Date;
    endDate: Date;
    summary: any; // La struttura summary che contiene gli operatori e le commesse
    dataRangeStart: Date;
    dataRangeEnd: Date;
}
