<main class="container-fluid">
    <mat-tab-group class="wbck" animationDuration="300ms" (selectedTabChange)="tabChanged($event)"
        [selectedIndex]="selectedTabIndex">
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="my-bounce material-icons">group</mat-icon>
                <span class="ml-1">{{ 'SIDEBAR.AnomalieConsuntivazione' | translate }}</span>
            </ng-template>
            <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="my-bounce material-icons">calendar_month</mat-icon>
                <span class="ml-1">{{ 'SIDEBAR.Holiday Calendar' | translate }}</span>
            </ng-template>
            <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="my-bounce material-icons">assignment</mat-icon>
                <span class="ml-1">{{ 'SIDEBAR.Contratti' | translate }}</span>
            </ng-template>
            <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="my-bounce material-icons">event_busy</mat-icon>
                <span class="ml-1">{{ 'SIDEBAR.ModuloAssenze' | translate }}</span>
            </ng-template>
            <div class="container-fluid mb-5 mt-5">
                <button (click)="goToPageForm()" id="kt_sign_in_submit" class="btn btn-lg btn-primary mb-5">
                    <span class="indicator-label text-dark">Aggiungi assenza</span>
                </button>

                <div appearance="fill" class="form-subject">
                    <!-- <mat-grid-list cols="2" rowHeight="5:1">
                        <mat-grid-tile>
                            <mat-select (selectionChange)="filterByBusinessLineId($event)" placeholder="Business Lines" multiple>
                              <mat-option *ngFor="let branch of branchesFilter" [value]="branch.id">
                                {{ branch.code }} ( {{ branch.codCountry }} )
                              </mat-option>
                            </mat-select>
                          </mat-grid-tile>
                    </mat-grid-list> -->
                    <mat-expansion-panel class="mb-6">
                        <mat-expansion-panel-header>
                            <mat-panel-title> Ricerca </mat-panel-title>
                            <mat-panel-description>
                                Imposta i parametri di ricerca
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <form class="historySearchForm" [formGroup]="searchForm">
                                <div class="formGrid">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Seleziona l'operatore</mat-label>
                                        <mat-select formControlName="UserId">
                                            <input matInput [placeholder]="translate.instant('LABEL.SEARCH')"
                                                [formControl]="operatorSearchControl" class="padd-input">
                                            <mat-option *ngFor="let user of filteredUsers" [value]="user.id">
                                                {{ user.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ 'COMPANIES.START_DATE' | translate }}</mat-label>
                                        <input matInput [matDatepicker]="startDate" formControlName="startDate" />
                                        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                                        <mat-datepicker #startDate></mat-datepicker>
                                    </mat-form-field>
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ 'COMPANIES.END_DATE' | translate }}</mat-label>
                                        <input matInput [matDatepicker]="endDate"
                                            [min]="minEndDate | date : 'yyyy-MM-dd'" formControlName="endDate" />
                                        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                                        <mat-datepicker #endDate></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="d-flex justify-content-end">
                                    <button mat-button type="button" (click)="reset()" class="resetButton"
                                        aria-label="warning">
                                        Reset
                                    </button>
                                    <button mat-button type="submit" (click)="onSubmit()" color="primary"
                                        aria-label="find" [disabled]="!searchForm.valid">
                                        Cerca
                                    </button>
                                </div>
                            </form>
                        </ng-template>
                    </mat-expansion-panel>
                </div>

                <div style="overflow-x: auto;">
                    <app-data-table-with-pagination [data]="dataSource" [columns]="columns"
                        [displayedColumns]="displayedColumns" [actions]="actions" [totalRows]="totalRows"
                        [pageInit]="pageInit" [currentPage]="currentPage" (changePagination)="pageChanged($event)"
                        (changeOrder)="updateDataBySortParams($event)" sortActive=""></app-data-table-with-pagination>

                </div>

            </div>
            <ng-template #itemDialog>
                <app-action-form></app-action-form>
            </ng-template>
        </mat-tab>


    </mat-tab-group>
</main>