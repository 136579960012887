<div class="container-fluid my-container">
  <div class="buttons my-2">

    <div style="display:flex; flex-direction: column;">
      <p *ngIf="getName()">
        {{ 'LABEL.NAME' | translate }}: <strong>{{getName()}}</strong>
      </p>
      <p *ngIf="getLegalEntity()">
        {{ 'LABEL.LEGAL_ENTITY' | translate }}: <strong>{{getLegalEntity()}}</strong>
      </p>
      <p *ngIf="getSubjectType()">
        {{ 'LABEL.SUBJECT_TYPE' | translate }}: <strong>{{getSubjectType()}}</strong>
      </p>
    </div>

    <div class="buttons-right">
      <mat-icon class="check-ok" *ngIf="formSubject.valid">check</mat-icon>
      <mat-icon [matTooltip]="getErrorTooltip()" class="check-ko" *ngIf="!formSubject.valid">warning</mat-icon>
      <button style="background-color:transparent;" [disabled]="!formSubject.valid" matTooltip="Salva" type="button"
        class="my-bounce btn btn-primary mx-2" (click)="update()">
        <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
      </button>
    </div>
  </div>
  <hr>
  <mat-tab-group class="wbck" animationDuration="300ms" (selectedTabChange)="changeTab($event)">

    <mat-tab>

      <ng-template mat-tab-label>
        <mat-icon class="my-bounce material-icons">maps_home_work</mat-icon>
        <span class="ml-1">{{ 'LABEL.LOCALIZATION' | translate }}</span>
        <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
          class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
      </ng-template>
      <app-subject-section-localization [form]="formSubject" [toDisable]="toDisable" [isNew]="isNew" [companies]="companies"
        [type]="type"></app-subject-section-localization>
    </mat-tab>

    <mat-tab *ngIf="checkSection('subjectForm')">
      <ng-template mat-tab-label>
        <mat-icon class="my-bounce material-icons">person</mat-icon>
        <span class="ml-1">{{ 'LABEL.ANAGRAFICA' | translate }}</span>
        <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
          class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
      </ng-template>
      <fieldset ng-disabled="boolView">
        <app-subject-section-data *ngIf="components && formSubject" [subject]="subject" [components]="components"
          [form]="formSubject" [allTypes]="(allTypes$ | async)!" [types]="(types$ | async)!" [statuses]="statuses"
          [companies]="companies" [loggedUser]="loggedUser" [users]="oplist2" [disable]="toDisable" [isNew]="isNew"
          [globalType]="type"></app-subject-section-data>
      </fieldset>

    </mat-tab>

    <mat-tab *ngIf="checkSection('subjectMarketingForm')">
      <ng-template mat-tab-label>
        <mat-icon class="my-bounce material-icons">info</mat-icon>
        <span class="ml-1">{{ 'LABEL.MARKETING' | translate }}</span>
        <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
          class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
      </ng-template>
      <app-subject-section-marketing *ngIf="components && formSubject" [subject]="subject" [components]="components"
        [form]="formSubject"></app-subject-section-marketing>
    </mat-tab>

    <mat-tab [disabled]="isNew" *ngIf="checkSection('crmDocumentManager')">
      <ng-template mat-tab-label>
        <mat-icon class="my-bounce material-icons">folder</mat-icon>
        <span class="ml-1">{{ 'LABEL.Documenti' | translate }}</span>
        <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
          class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
      </ng-template>
      <app-subject-section-documents *ngIf="components" [components]="components" [form]="formSubject"
        [toDisable]="toDisable"></app-subject-section-documents>
    </mat-tab>

    <mat-tab *ngIf="!isNew && checkSection('questionnaires')">
      <ng-template mat-tab-label>
        <mat-icon class="my-bounce material-icons">list</mat-icon>
        <span class="ml-1">{{ 'LABEL.Questionari' | translate }}</span>
        <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
          class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
      </ng-template>
      <app-subject-section-survey [form]="formSubject" [toDisable]="toDisable"></app-subject-section-survey>
    </mat-tab>

    <mat-tab [disabled]="isNew" #tabRelations *ngIf="checkSection('subjectRelations')">
      <ng-template mat-tab-label>
        <mat-icon class="my-bounce material-icons">group</mat-icon>
        <span class="ml-1">{{ 'LABEL.Relazioni' | translate }}</span>
        <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
          class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
      </ng-template>
      <div *ngIf="tabRelations.isActive">

        <app-subject-section-relations [form]="formSubject" [toDisable]="toDisable"
          [loggedUser]="loggedUser"></app-subject-section-relations>

      </div>
    </mat-tab>

    <mat-tab [disabled]="isNew" *ngIf="checkSection('subjectActivities')">

      <ng-template mat-tab-label>
        <mat-icon class="my-bounce material-icons">settings</mat-icon>
        <span class="ml-1">{{ 'LABEL.ACTIVITIES' | translate }}</span>
        <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
          class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
      </ng-template>
      <div class="full-w">
        <app-activities *ngIf="subject !== null" [subject]="subject" [isInput]="true"
          [toDisable]="disableActivity"></app-activities>
      </div>
    </mat-tab>

    <mat-tab [disabled]="isNew" #tabOrders *ngIf="checkSection('subjectPractices')">

      <ng-template mat-tab-label>
        <mat-icon class="my-bounce material-icons">description</mat-icon>
        <span class="ml-1">{{ 'LABEL.PRACTICES' | translate }}</span>
        <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
          class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
      </ng-template>
      <div class="full-w" *ngIf="tabOrders.isActive">
        <app-subjects-section-practices *ngIf="(subject !== null)" [subject]="subject"
          [form]="formSubject"></app-subjects-section-practices>
      </div>
    </mat-tab>

  </mat-tab-group>

  <div class="buttons-right  my-2">
    <mat-icon class="check-ok" *ngIf="formSubject.valid">check</mat-icon>
    <button style="background-color:transparent;" [disabled]="!formSubject.valid" matTooltip="Salva" type="button"
      class="my-bounce btn btn-primary mx-2" (click)="update()">
      <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
    </button>
  </div>

</div>