import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, TemplateRef, Output } from '@angular/core';
import { LibService } from '../../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NavService } from '../../services/nav.service';
import { TranslatorService } from '../../services/translate.service';
import { BehaviorSubject, catchError, combineLatest, map, Observable, of, Subject as Subject2, tap } from 'rxjs';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { CalendarContractService } from 'src/app/services/calendar-contrcats-service';
import { BranchService } from 'src/app/services/branch.service';
import { FormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDatepicker } from '@angular/material/datepicker';
import { CountryService } from 'src/app/services/country.service';
import { ProfileService } from 'src/app/services/profileService';
import { AbsenceModuleService } from 'src/app/services/absence-module-service';
import { FileDialogComponent } from './absence-module-document-dialog/absence-module-document-dialog';
import { UserSessionService } from '../../services/user-session.service';




@Component({
  selector: 'absence-modules-component',
  templateUrl: './absence-module.component.html',
  styleUrls: ['./absence-module.component.scss']
})

export class AbsenceModulesComponent implements OnInit {

  dataSource: any;
  actionList: any;
  kwSearch: any;
  title: string; //= this.translate.instant('SIDEBAR.Sedi');
  subTitle: string;// = "EDAC - " + this.translate.instant('SIDEBAR.Sedi');
  selectedTabIndex: number = 3;
  totalRows: number = 10;
  pageInit: number = 0;
  currentPage = 0;
  pageSize = 10;
  pageSizeOptions = [5, 10, 20];
  orderOptions: any = {};
  operatorSearchControl = new FormControl();


  currentUrl: string;

  searchTimeout: any;
  idSediCurrentUser: any;
  operatorAvailable: any;
  filteredUsers: any;

  constructor(public navService: NavService,
    public libService: LibService,
    private router: Router,
    public translate: TranslatorService,
    private CalendarContractService: CalendarContractService,
    public BranchService: BranchService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public countryService: CountryService,
    public profileService: ProfileService,
    private AbsenceModuleService: AbsenceModuleService,
    public dialog: MatDialog,
    private userSessionService: UserSessionService,

  ) {
    this.dataSource = new MatTableDataSource();
  }

  searchForm: UntypedFormGroup = this.fb.group({
    UserId: [null],
    startDate: [null],
    endDate: [null],
  });

  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;

    this.pageInit = this.currentPage * this.pageSize;
    this.loadDataSource()
  }



  updateDataBySortParams(event: any) {

    // console.log("updateDataBySortParams(event: any)")
    // console.log(event)
    this.orderOptions = event

    this.loadDataSource()

  }


  loadDataSource() {
    // console.log('loadDataSource')
    this.libService.lockPage('');

    // if (reset) {
    //   this.resetOrder()
    //   this.resetPagination()
    //   this.filterOptions.ids = []
    // }

    this.AbsenceModuleService.absenceModuleWithPagination(this.searchForm.value, this.pageInit, this.pageSize).subscribe((responseData: any) => {
      this.totalRows = responseData.count;
      this.actionList = responseData.data;
      this.dataSource = responseData.data;
      this.libService.resetLockPage();
    }, (err: any) => {
      this.libService.resetLockPage();
      this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.WARNING'));
    });


  }



  public reset(): void {
    this.searchForm.reset();
    this.currentPage = 0;
    this.pageInit = 0;
    this.loadDataSource();
    // this.onSubmit()
  }

  public onSubmit(): void {
    // console.log('SUBMIT', this.searchForm.value);
    this.libService.lockPage('');
    this.currentPage = 0;
    this.pageInit = 0;
    this.loadDataSource();
  }


  displayedColumns = ['nomeUtente', 'hours', 'startDate', 'endDate', 'workCausal'];

  structure: any = {
    nomeUtente: {
      type: 'string',
      required: true,
      disabled: false,
      readOnly: false
    },
    hours: {
      type: 'numeric',
      required: true,
      disabled: false,
      readOnly: false
    },
    startDate: {
      type: 'date',
      required: true,
      disabled: false,
      readOnly: false
    },
    endDate: {
      type: 'date',
      required: true,
      disabled: false,
      readOnly: false
    },
    workCausal: {
      type: 'string',
      required: true,
      disabled: false,
      readOnly: false
    }
  };

  columns: any = [
    {
      name: 'nomeUtente',
      title: this.getTitle('nomeUtente'),
      cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
      value: (item: any) => item.userName
    },
    {
      name: 'hours',
      title: this.getTitle('hours'),
      cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
      value: (item: any) => item.hours
    },
    {
      name: 'startDate',
      title: this.getTitle('startDate'),
      cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
      value: (item: any) => this.formatDateToDDMMYYYY(item.startDate)
    },
    {
      name: 'endDate',
      title: this.getTitle('endDate'),
      cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
      value: (item: any) => this.formatDateToDDMMYYYY(item.endDate)
    },
    {
      name: 'workCausal',
      title: this.getTitle('workCausal'),
      cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
      value: (item: any) => item.workCausalCode // Mostra il codice causale del lavoro
    }
  ];

  getTitle(type: any) {
    let title: any = this.translate.get('LABEL.NOT_PRESENT');
    switch (type) {
      case 'nomeUtente':
        title = this.translate.get('Nome utente');
        break;
      case 'hours':
        title = this.translate.get('Ore assenza');
        break;
      case 'startDate':
        title = this.translate.get('Data di Inizio');
        break;
      case 'endDate':
        title = this.translate.get('Data di Fine');
        break;
      case 'workCausal':
        title = this.translate.get('Causale di assenza');
        break;
      default:
        title = this.translate.get('LABEL.NOT_PRESENT');
        break;
    }
    return title;
  }

  padWithZero(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }

  formatDateToDDMMYYYY(dateString: string): string {
    const date = new Date(dateString); // Crea un oggetto Date dalla stringa ISO
    // Usa toLocaleDateString per formattare la data come DD/MM/YYYY
    return date.toLocaleDateString('it-IT', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  }


  setUserActions() {
    // SHOW
    this.actions.push({
      label: of(''),
      icon: (item: any) => {
        return this.iconRoutine(item);
      },
      onClick: (item: any) => this.goToPageForm(item.id),
      color: 'primary'
    })
    // Icona per visualizzare i file caricati
    this.actions.push({
      label: of(''),
      icon: () => 'folder_open', // Icona di una cartella aperta o usa un'icona adatta
      onClick: (item: any) => this.openFilePopup(item.absenceDocuments), // Apre il popup per visualizzare i documenti
      color: 'primary'
    });
  }

  openFilePopup(absenceDocuments: any[]): void {
    if (absenceDocuments && absenceDocuments.length > 0) {
      // Apri il dialog solo se ci sono documenti
      const dialogRef = this.dialog.open(FileDialogComponent, {
        width: '400px',
        data: { documents: absenceDocuments }
      });
    } else {
      this.toastr.info('Nessun documento associato a questo modulo di assenza.');
    }
  }



  iconRoutine(subject: any) {
    return 'edit';
  }



  actions: any = [];


  ngOnInit(): void {
    this.getUsers();
    // this.loadData();
    this.setUserActions();
    this.loadDataSource();

    // console.log('actionList: ', this.actionList)
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    this.navService.setWikiKey('NODEF');

    this.operatorSearchControl.valueChanges.subscribe(value => {
      this.filteredUsers = this.operatorAvailable.filter((causal: any) =>
        !value || causal?.name.toLowerCase().includes(value.toLowerCase())
      );
    });

  }




  loadData(): void {

    this.libService.lockPage('');

    this.CalendarContractService.getContractsWithPagination(this.searchForm.value, this.pageInit, this.pageSize).subscribe((responseData: any) => {
      // console.log('loadDataSets response %o', responseData);
      this.libService.unlockPage();
      // console.log('responseData: ', responseData)
      this.actionList = responseData;
      this.dataSource = responseData;
    }, (err: any) => {
      // console.log(err)
    });

  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    /* console.log('tabChangeEvent --> ', tabChangeEvent); */
    /* console.log('index --> ', tabChangeEvent.index); */
    this.selectedTabIndex = tabChangeEvent.index
    if (tabChangeEvent.index !== (null || undefined)) {
      if (tabChangeEvent.index === 0) {
        this.goToPage('final-balancer/history')
      }
      if (tabChangeEvent.index === 1) {
        this.goToPage('settings/calendar-settings')
      }
      if (tabChangeEvent.index === 2) {
        this.goToPage('settings/calendar-contracts')
      }
      if (tabChangeEvent.index === 3) {

      }
    }


  }

  goToPage(link: any) {
    this.router.navigate([link]);
  }
  goToPageForm(id: number = 0) {
    // console.log(id)
    this.router.navigate([`settings/absence-modules-form/`, id]);
  }

  getUsers() {
    this.userSessionService.getVisibilities().subscribe(
      (userdata: any) => {
        console.log(userdata);

        // Ricavo gli ID delle sedi legate all'utente
        this.idSediCurrentUser = userdata.UsersCompaniesRel.map((company: any) => company.id);
        console.log(this.idSediCurrentUser);

        // Chiamo il metodo per ottenere i nomi degli utenti legati agli ID delle sedi
        this.userSessionService.getNamesBySedeAppartenenzaIds(this.idSediCurrentUser).subscribe(
          (operatorNames: any) => {
            this.operatorAvailable = operatorNames; // Salvo i nomi degli utenti ottenuti
            console.log(this.operatorAvailable); // Stampo i nomi degli utenti
            this.filteredUsers = this.operatorAvailable;
          },
          (err: any) => {
            console.log('Errore nel recupero dei nomi:', err);
          }
        );
      },
      (err: any) => {
        console.log('Errore nel recupero delle visibilità:', err);
      }
    );
  }



}