<div mat-content class="Tooltip container">
    <h1 class="mt-5 mb-7">{{ formTitle }}</h1>
    <form [formGroup]="form" class="d-flex flex-column">
        <mat-form-field appearance="fill">
            <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.NOME_CALENDARIO' | translate }}</mat-label>
            <input matInput formControlName="description" placeholder="Inserisci nome contratto">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.SELEZIONA_NAZIONE' | translate }}</mat-label>
            <mat-select formControlName="countries" multiple>
                <input matInput [placeholder]="translate.instant('LABEL.SEARCH')" [formControl]="countrySearchControl"
                    class="padd-input">
                <mat-option *ngFor="let country of filteredCountries" [value]="country.id">
                    {{ country.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.SELEZIONA_SOCIETA' | translate }}</mat-label>
            <mat-select formControlName="societies" multiple>
                <input matInput [placeholder]="translate.instant('LABEL.SEARCH')" [formControl]="societySearchControl"
                    class="padd-input">
                <mat-option *ngFor="let society of filteredSocieties" [value]="society.id">
                    {{ society.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.SELEZIONA_SEDE' | translate }}</mat-label>
            <mat-select formControlName="companies" multiple>
                <input matInput [placeholder]="translate.instant('LABEL.SEARCH')" [formControl]="companySearchControl"
                    class="padd-input">
                <mat-option *ngFor="let company of filteredCompanies" [value]="company.id">
                    {{ company.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>profiles</mat-label>
            <mat-select formControlName="profiles" multiple>
                <input matInput [placeholder]="translate.instant('LABEL.SEARCH')" [formControl]="profilesSearchControl"
                    class="padd-input">
                <mat-option *ngFor="let profile of filteredProfiles" [value]="profile.id">
                    {{ profile.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>branches</mat-label>
            <mat-select formControlName="branches" multiple>
                <input matInput [placeholder]="translate.instant('LABEL.SEARCH')" [formControl]="branchesSearchControl"
                    class="padd-input">
                <mat-option *ngFor="let branche of filteredBranches" [value]="branche.id">
                    {{ branche.name }} ({{ branche.codeLang }})
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Massimo di ore ordinarie settimanali</mat-label>
            <input matInput formControlName="maximumWeeklyHours" type="number" min="0"
                placeholder="Inserisci il massimo delle ore ORDINARIE settimanali" class="hours">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Massimo di ore extra settimanali</mat-label>
            <input matInput formControlName="maximumExtraHoursPerWeek" type="number" min="0"
                placeholder="Inserisci il massimo delle ore EXTRA settimanali" class="hours">
        </mat-form-field>
        <div class="boxDocument">
            <mat-card-header class="justify-content-center">
                <mat-card-title>Gestione delle date per la creazione del contratto</mat-card-title>
            </mat-card-header>
            <p>Le prime due date indicano il periodo specifico in cui il contratto sarà attivo ogni anno (ad esempio,
                per un contratto stagionale estivo, potresti inserire da giugno a settembre). Le ultime due date,
                invece, definiscono l’arco temporale complessivo del contratto (ad esempio, dal 1 gennaio 2024 al 1
                gennaio 2027). Quindi, il contratto sarà attivo ogni anno nel periodo indicato nelle prime due date, ma
                la sua validità complessiva si estenderà per l’intero arco temporale specificato nelle ultime due date.
                Se le ultime due date non vengono inserite, il contratto rimarrà in stato di bozza; mentre, se non viene
                inserita la data di fine, il contratto partirà dalla data di inizio e si rinnoverà automaticamente per
                tutti gli anni a seguire.</p>
            <mat-form-field appearance="fill">
                <mat-label>{{ 'COMPANIES.START_DATE' | translate }} - imposta la data in cui il contratto inizierà ad
                    essere attivo</mat-label>
                <input matInput [matDatepicker]="startDateOfValidity" formControlName="startDateOfValidity" />
                <mat-hint>GG/MM</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="startDateOfValidity"></mat-datepicker-toggle>
                <mat-datepicker #startDateOfValidity></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{ 'COMPANIES.END_DATE' | translate }} - imposta la data in cui il contratto finirà di essere
                    attivo</mat-label>
                <input matInput [matDatepicker]="endDateOfValidity" formControlName="endDateOfValidity" />
                <mat-hint>GG/MM</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="endDateOfValidity"></mat-datepicker-toggle>
                <mat-datepicker #endDateOfValidity></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{ 'COMPANIES.START_DATE' | translate }} - imposta la data di inizio del
                    contratto</mat-label>
                <input matInput [matDatepicker]="startDate" formControlName="startDate" />
                <mat-hint>GG/MM/AAAA</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{ 'COMPANIES.END_DATE' | translate }} - imposta la data di fine del contratto</mat-label>
                <input matInput [matDatepicker]="endDate" [min]="minEndDate | date : 'yyyy-MM-dd'"
                    formControlName="endDate" />
                <mat-hint>GG/MM/AAAA</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="boxDocument">
            <mat-card-header class="justify-content-center">
                <mat-card-title>Seleziona la Causale di Assenza e i Documenti Associati</mat-card-title>
            </mat-card-header>
            <p>Scegli la causale di assenza. Alcune causali potrebbero non richiedere documenti specifici, quindi puoi
                lasciare vuota la selezione del documento. <br>Se non trovi il documento necessario, puoi aggiungerlo
                tramite il pulsante "Aggiungi nuova tipologia documento". La selezione del documento e il pulsante
                compariranno solo dopo aver selezionato almeno una causale.</p>
            <mat-form-field appearance="fill">
                <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.SELEZIONA_LA_CAUSALE_DI_ASSENZA_LAVORO' |
                    translate}}</mat-label>
                <mat-select formControlName="workCausals" multiple>
                    <input matInput [placeholder]="translate.instant('LABEL.SEARCH')"
                        [formControl]="causalSearchControl" class="padd-input">
                    <mat-option *ngFor="let workCausal of filteredCausals" [value]="workCausal.id">
                        {{ workCausal.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <!-- Sezione per associare i documenti ai workCausals -->
            <div formArrayName="workCausalDocuments">
                <div *ngFor="let docControl of workCausalDocumentsArray.controls; let i = index" [formGroupName]="i">
                    <!-- Documento associato -->
                    <mat-form-field appearance="fill">
                        <mat-label>Seleziona tipologia Documento/i per <strong>{{
                                getWorkCausalName(docControl.get('workCausalId')?.value) }}</strong></mat-label>
                        <mat-select formControlName="documentId" placeholder="Seleziona documento" multiple>
                            <input matInput [placeholder]="translate.instant('LABEL.SEARCH')"
                                [formControl]="documentSearchControl" class="padd-input">
                            <mat-option *ngFor="let doc of filteredDocumentList" [value]="doc.id">{{ doc.label
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="d-flex justify-content-center" *ngIf="form.get('workCausals')?.value?.length > 0">
                <button mat-raised-button color="primary" (click)="addNewDocument()">Aggiungi Nuova Tipologia di
                    Documento</button>
            </div>
        </div>


        <div class="cardBox form-subject">
            <mat-card class="cardItem">
                <mat-card-header class="justify-content-center">
                    <mat-card-title>Schedulazione settimanale</mat-card-title>
                </mat-card-header>
                <p>In questa sezione è necessario definire il numero massimo di ore ordinarie e straordinarie consentite
                    per ogni giorno della settimana. Se non sono previste ore ordinarie o extra per un determinato
                    giorno, è necessario inserire "0" nel campo corrispondente.<span> (a breve verrà rilasciata la funzione che
                        permette di distribuire in modo veloce le ore delle giornate tramite dei button, ed inoltre verrà
                        impostato un limite sulle ore in base al numero di ore massime ordinarie ed extra selezionate in
                        precedenza)</span></p>
                <mat-card-content>
                    <ng-container formArrayName="weeklySchedules">
                        <div *ngFor="let weeklySchedule of weeklySchedules.controls; let p = index">
                            <label class="languageCodeLabel">{{ weekly[p].dayOfWeekLabel }}</label>

                            <div [formGroup]="weeklySchedule" class="form-subject languageCodeBox">
                                <mat-form-field appearance="fill" class="languageCodeItem">
                                    <mat-label>Massimo di ore ordinarie per giorno</mat-label>
                                    <input matInput formControlName="maxHours" type="number" min="0"
                                        placeholder="Inserisci il massimo delle ore ordinarie per giorno" class="hours">
                                </mat-form-field>

                                <mat-form-field appearance="fill" class="languageCodeItem">
                                    <mat-label>Massimo di ore extra per giorno</mat-label>
                                    <input matInput formControlName="maxExtraHours" type="number" min="0"
                                        placeholder="Inserisci il massimo delle ore extra per giorno" class="hours">
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-container>
                </mat-card-content>
            </mat-card>
        </div>
    </form>
    <mat-dialog-actions class="justify-content-end">
        <div class="buttons-right  my-2 d-flex align-items-center">

            <button style="background-color:transparent;" [matTooltip]="translate.instant('MARKETINGNOTES.back')"
                type="button" class="my-bounce btn btn-primary mx-2" (click)="back()">
                <mat-icon class="material-icons" style="color:#293683;">arrow_back</mat-icon>
            </button>

            <button style="background-color:transparent;" [matTooltip]="translate.instant('MARKETINGNOTES.save')"
                type="button" class="my-bounce btn btn-primary mx-2" (click)="onSave()" [disabled]="!form.valid">
                <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
            </button>
        </div>
    </mat-dialog-actions>
</div>