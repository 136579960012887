import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent, DataBindingDirective } from '@progress/kendo-angular-grid';
import { combineLatest, } from 'rxjs';
import { PecService } from 'src/app/services/pec.service';
import { LibService } from 'src/app/services/libService';
import { SubjectService } from 'src/app/services/subject.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { DatatableAction } from 'src/app/shared/data-table/data-table.component';
import { BehaviorSubject, of } from 'rxjs';
import { Router } from '@angular/router';

import { FileUploadModalComponent } from 'src/app/modals/file-upload-modal/file-upload-modal.component';
import { DocumentsGridComponent } from 'src/app/shared/documents-grid/documents-grid.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { DocumentTypeService } from 'src/app/services/document-type.service';
import { ModalFormComponent } from 'src/app/forms/modal-form/modal-form.component';
import { ToastrService } from 'ngx-toastr';
import { PecActionModalComponent } from 'src/app/pecs/pec-action-modal/pec-action-modal.component';

export class DatatableColumn<T> {
  name: string;
  title: Observable<string>;
  type?: 'text' | 'link' | 'icon' | 'html' | 'templateRef' = 'text';
  flex?: string;
  sticky?: boolean;
  color?: (element: T) => 'primary' | 'warn' | 'error';
  cssClass?: (element: T) => string[];
  sortDisabled?: boolean;
  routerLink?: (element: T) => string[];
  value(element: T): any {
    return null;
  }
  templateRef?: (element: T) => Observable<TemplateRef<any>>;
  tooltip?: (element: T) => Observable<String>;

  constructor(column: Partial<DatatableColumn<T>>) {
    Object.assign(this, column);
  }
}


@Component({
  selector: 'app-pecs',
  templateUrl: './pecs.component.html',
  styleUrls: ['./pecs.component.scss']
})
export class PecsComponent implements OnInit {

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  @ViewChild(GridComponent)

  public grid: GridComponent;

  search: any;
  items: any = []
  allItems: any = []
  whileEditing: boolean = false;
  disableUpload: boolean = false;

  documentTypes: any = []

  items$ = this.pecService.allWithPagination();

  private timeout?: number;


  dataSource: any;

  totalRows: number = 20;
  pageSize: number = 10;
  pageInit: number = 0;

  filterOptions: any = {};
  currentPage = 0;
  companyIdsFilter: any = []
  responsiblesFilter: any = []
  idsFilter: any = []
  orderOptions: any = {}
  allGlobalDocumentTypes: any = []

  pecStatusFilter:any = null;
  pecStatusFilterList:any = [
    {
      value:'pecDaGestire',
      text:'PEC Non Gestita'
    },
    {
      value:'pecConReclamo',
      text:'Pec con Reclamo Aperto'
    },
    {
      value:'soloReclamo',
      text:'Reclamo Aperto'
    },
    {
      value:'pecChiusa',
      text:'PEC Gestita (Chiusa)'
    },
    {
      value:'pecChiusaConReclamo',
      text:'PEC Con Reclamo Gestita (Chiusa)'
    },
    {
      value:'reclamoChiuso',
      text:'Reclamo Gestito (Chiuso)'
    },
  ];

  globalDocumentTypes$ = this.documentTypeService.getAllGeneric()

  actions: DatatableAction<any>[] = [
    {
      label: of(''),
      icon: item => {
        return 'edit'
      },
      onClick: item => this.openEditDialog(item),
      color: 'primary'
    },
    {
      label: of(''),
      icon: item => {
        return 'report'
      },
      onClick: item => this.changeStatus(item),
      color: 'primary'
    },
    {
      label: of(''),
      icon: item => {
        return 'upload_file'
      },
      onClick: item => this.openUploadDialog(item),
      color: 'primary'
    },
    {
      label: of(''),
      icon: item => {
        return 'folder'
      },
      onClick: item => this.viewDocuments(item?.documents),
      color: 'primary'
    },
    /*
    {
      label: of(''),
      icon: subject => {
        return 'delete'
      }, // 'delete',
      onClick: request => this.deleteRow(request),
      color: 'primary'
    }
    */
  ];

  pecStatusFilterChange(){
    this.loadDataSource()
  }

  changeStatus(item: any) {
    this.dialog.open(PecActionModalComponent, {
      data: {
        item: item,
      },
      width: '900px',
      scrollStrategy: new NoopScrollStrategy(),
    })
      .afterClosed()
      .subscribe(res => {
        console.log('uploadDialogres: ', res);
        if (res) {
          this.loadDataSource()
        }
      })
  }

  goToComplaint(item: any) {
    let queryParams: any = {
      type: 3,
      pecId: item.id
    }
    if (item?.practice?.code) queryParams.practiceCode = item?.practice?.code
    this.router.navigate(['request/0'], { queryParams: queryParams });
  }

  public openEditDialog(item: any) {

    this.dialog.open(ModalFormComponent, {
      data: {
        type: 'pecs',
        item: item,
      },
      width: '70%',
      scrollStrategy: new NoopScrollStrategy(),
    })
      .afterClosed()
      .subscribe(res => {
        console.log('uploadDialogres: ', res);
        if (res) {
          this.loadDataSource()
        }
      })
  }

  public openUploadDialog(item: any) {

    this.documentTypes = [...this.allGlobalDocumentTypes]

    this.dialog.open(FileUploadModalComponent, {
      data: {
        inputMode: 'pecs',
        toEntityId: item.id,
        documentTypes: of(this.documentTypes)
      },
      scrollStrategy: new NoopScrollStrategy(),
    })
      .afterClosed()
      .subscribe(res => {
        console.log('uploadDialogres: ', res);
        if (res) {
          this.loadDataSource()
        }
      })

  }

  public viewDocuments(documents: any) {
    console.log("DOCUMENTI --> ", documents)
    if (documents.length > 0) {
      let docs = this.dialog.open(DocumentsGridComponent, {
        height: '70%',
        width: '70%',
        scrollStrategy: new NoopScrollStrategy(),
      });
      let instance = docs.componentInstance;
      instance.documents = documents;
    } else {
      this.toastr.error(this.translate.instant('LABEL.NO_DOCS'), this.translate.instant('TOASTR.WARNING'));
    }

  }

  displayedColumns: string[] = [
    'id',
    'practiceId',
    'pecType',
    'description',
    'createdAt',
    'updatedAt',
    'emailPec',
    'subject',
    "status",
    "closingReason"
  ];

  columns: DatatableColumn<any>[] = [
    {
      name: "id",
      flex: '7',
      title: this.translate.get('LABEL.ID'),
      cssClass: item => ['column-padding'],
      value: item => item.id
    },
    {
      name: "practiceId",
      title: this.translate.get('REVIEWS.practice'),
      cssClass: item => ['column-padding'],
      value: item => item?.practice?.code
    },
    {
      name: "pecType",
      title: this.translate.get('REVIEWS.reason'),
      cssClass: item => ['column-padding'],
      value: item => item.pecType?.label,
    },
    {
      name: "description",
      title: this.translate.get('REVIEWS.description'),
      cssClass: item => ['column-padding'],
      value: item => item.description as string,
    },
    {
      name: "emailPec",
      title: this.translate.get('LABEL.EMAILPEC'),
      cssClass: item => ['column-padding'],
      value: item => item.emailPec as string,
    },
    {
      name: "status",
      type: "html",
      title: this.translate.get('LABEL.STATUS'),
      cssClass: item => [this.setStatus(item).cssClass],
      value: item => this.setStatus(item).html
    },
    {
      name: "closingReason",
      type: "html",
      title: this.translate.get('LABEL.REASON'),
      cssClass: item => ['column-padding'],
      value: item => item.closingReason
    },
    {
      name: "subject",
      title: this.translate.get('REVIEWS.subject'),
      cssClass: item => ['column-padding'],
      value: item => this.getSubjectName(item),
    },
    {
      name: "createdAt",
      title: this.translate.get('LABEL.CREATION_DATE'),
      cssClass: item => ['column-padding'],
      value: item => item.createdAt as string,
    },
    {
      name: "updatedAt",
      title: this.translate.get('LABEL.UPDATE_DATE'),
      cssClass: item => ['column-padding'],
      value: item => item.updatedAt as string,
    },
  ]

  setStatus(item: any) {

    let value = {
      cssClass: 'pecDaGestire',
      html: `<strong>PEC Non Gestita</strong>`
    }

    let claimComplaintsCount = item?.claimComplaints.length
    let claimLegalsCount = item?.claimLegals.length
    let claimTransactionsCount = item?.claimTransactions.length
    let claimsCount = item?.claims.length

    let complaintsCondition =   claimComplaintsCount > 0 || claimLegalsCount > 0 || claimTransactionsCount > 0 || claimsCount > 0

    let complaintsConditionWithPec =   complaintsCondition && item?.emailPec

    let closingDate = item?.closingDate

    if (complaintsCondition) {
      value = {
        cssClass: 'pecConReclamo',
        html: `<strong>Reclamo Aperto</strong>`
      }
    }

    if (complaintsConditionWithPec) {
      value = {
        cssClass: 'pecConReclamo',
        html: `<strong>PEC con Reclamo Aperto</strong>`
      }
    }

    if (closingDate){
      value = {
        cssClass: 'pecChiusa',
        html: `<strong>PEC Gestita (Chiusa)</strong>`
      }
    }

    
    if(complaintsCondition && closingDate){
      value = {
        cssClass: 'pecChiusa',
        html: `<strong>PEC Con Reclamo Gestita (Chiusa)</strong>`
      }
    }

    if(complaintsConditionWithPec && closingDate){
      value = {
        cssClass: 'pecChiusa',
        html: `<strong>Reclamo Gestito (Chiusa)</strong>`
      }
    }

    if(complaintsCondition && closingDate){
      value = {
        cssClass: 'pecChiusa',
        html: `<strong>Reclamo Gestito (Chiuso)</strong>`
      }
    }

    return value
  }

  getSubjectName(item: any) {
    let value = '';
    if (item?.subject) value = `${item?.subject?.name ? item?.subject?.name : ''} ${item?.subject?.surname ? item?.subject?.surname : ''}`
    if (item?.subjectAlias) value = item?.subjectAlias + ' (Alias)'
    return value;
  }

  constructor(private libService: LibService,
    public subjectService: SubjectService,
    public translate: TranslateService,
    private dialog: MatDialog,
    private documentTypeService: DocumentTypeService,
    private pecService: PecService,
    private toastr: ToastrService,
    private router: Router,
  ) { }

  loadDataSource(reset: boolean = false) {
    console.log('loadDataSource')
    this.libService.lockPage('');

    if(this.pecStatusFilter){
      this.filterOptions.status = this.pecStatusFilter
    }else{
      this.filterOptions.status = null
    }

    this.pecService.allWithPagination(this.filterOptions, this.orderOptions, this.pageInit, this.pageSize).subscribe((responseData: any) => {
      this.items = responseData.items;
      this.allItems = responseData.items;
      this.totalRows = responseData.count;
      this.libService.resetLockPage();
    }, (err: any) => {
      // this.lockrequest = false;
      this.libService.resetLockPage();
      //this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.WARNING'));
    });

  }

  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;

    this.pageInit = this.currentPage * this.pageSize;
    this.loadDataSource()
  }

  updateDataBySortParams(event: any) {

    console.log("updateDataBySortParams(event: any)")
    console.log(event)
    this.orderOptions = event

    this.loadDataSource()

  }

  ngOnInit(): void {
    this.libService.lockPage('');
    combineLatest([this.items$, this.globalDocumentTypes$]).subscribe({
      next: ([items, globalDocumentTypes]) => {
        this.libService.unlockPage();
        this.items = items.items;
        this.allItems = items.items;
        this.totalRows = items.count;
        this.allGlobalDocumentTypes = globalDocumentTypes;
        this.items.sort(function (a: any, b: any) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return b.updatedAt.localeCompare(a.updatedAt);
        });
        this.allItems.sort(function (a: any, b: any) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return b.updatedAt.localeCompare(a.updatedAt);
        });

      },
      error: (error) => {
        this.libService.showMessageError(error.message);
      }
    })
  }


  onFilter(): void {
    window.clearTimeout(this.timeout);

    if (!this.search || this.search.length == 0) {
      this.items = this.allItems
      this.filterOptions.search = null

    } else {
      this.filterOptions.search = this.search
    }

    if (this.search.length < 2 && this.search.length != 0) return

    this.timeout = window.setTimeout(() => this.loadDataSource(), 500);

  }

}
