import { BranchCompanyComponent } from './branches/branch-company/branch-company.component';
import { PhaseDetailComponent } from './phases/phase-detail/phase-detail.component';
import { MacrophaseDetailComponent } from './macrophases/macrophase-detail/macrophase-detail.component';
import { CategoryDetailComponent } from './categories/category-detail/category-detail.component';
import { BranchDetailComponent } from './branches/branch-detail/branch-detail.component';
import { TaxstepDetailComponent } from './taxsteps/taxstep-detail/taxstep-detail.component';
import { LocationsComponent } from './locations/locations.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { DomainsComponent } from './domains/domains.component';
import { PracticesComponent } from './practices/practices.component';
import { PlannerComponent } from './planner/planner.component';
import { ProfilesComponent } from './profiles/profiles.component';
import { SettingsComponent } from './settings/settings.component';
import { ActionsComponent } from './settings/actions/actions.component';
import { ActionSettingsComponent } from './settings/action-settings/action-settings.component';
import { ActionProfileSettingsComponent } from './settings/action-profile-settings/action-profile-settings.component';
import { SubjectTypeSettingsComponent } from './settings/subject-type-settings/subject-type-settings.component';
import { TranslationSettingsComponent } from './settings/translation-settings/translation-settings.component';
import { MarketingNoteSettingsComponent } from './settings/marketing-note-settings/marketing-note-settings.component';
import { CalendarSettingsComponent } from './settings/calendar-settings/calendar-settings.component';
import { ActionSettingsFormComponent } from './settings/action-settings/action-settings-form/action-settings-form.component';
import { WorkflowSettingsComponent } from './settings/workflow-settings/workflow-settings.component';
import { WorkflowSettingsFormComponent } from './settings/workflow-settings/workflow-settings-form/workflow-settings-form.component';
import { SubjectTypeSettingsFormComponent } from './settings/subject-type-settings/subject-type-settings-form/subject-type-settings-form.component';
import { TranslationSettingsFormComponent } from './settings/translation-settings/translation-settings-form/translation-settings-form.component';
import { MarketingNoteSettingsFormComponent } from './settings/marketing-note-settings/marketing-note-settings-form/marketing-note-settings-form.component';
import { JourneysComponent } from './journeys/journeys.component';
import { SubjectComponent } from './subject/subject.component';
import { BuildingComponent } from './building/building.component';
import { UsersComponent } from './users/users.component';
import { CockpitComponent } from './cockpit/cockpit.component';
import { CockpitByProfileComponent } from './cockpit-by-profile/cockpit-by-profile.component';
import { HomComponent } from './hom/hom.component';
import { CompaniesComponent } from './companies/companies.component';
import { AdLicDtailComponent } from './admin-license-old/ad-lic-dtail/ad-lic-dtail.component';
import { OperatorLicenseComponent } from './operator-license/operator-license.component';
import { ProfileItemComponent } from './profile-item/profile-item.component';
import { DomainItemComponent } from './domain-item/domain-item.component';
import { CertificatesComponent } from './certificates/certificates.component';
import { PaychecksPerTenComponent } from './paychecks-per-ten/paychecks-per-ten.component';
import { DatainComponent } from './datain/datain.component';
import { MyHeadquartersComponent } from './my-headquarters/my-headquarters.component';
import { FinalBalancerMultiComponent } from './final-balancer-multi/final-balancer-multi.component';
import { CancellationsApprovalComponent } from './cancellations-approval/cancellations-approval.component';
import { DpiComponent } from './dpi/dpi.component';
import { TransferReceiptsComponent } from './transfer-receipts/transfer-receipts.component';
import { CreditsComponent } from './credits/credits.component';
import { DashboardCreateComponent } from './dashboard-create/dashboard-create.component';
import { OrderDtailComponent } from './orders/order-dtail/order-dtail.component';
import { PracticeDtailComponent } from './practices/practice-dtail/practice-dtail.component';
//import { GenConfigComponent } from './gen-config/gen-config.component';
import { PhasesComponent } from './phases/phases.component';
import { CrmComponent } from './crm/crm.component';
import { CustomerRelationshipManagementComponent } from './customer-relationship-management/customer-relationship-management.component';
import { CrmSectionsComponent } from './crm/crm-sections/crm-sections.component';
import { MacrophasesComponent } from './macrophases/macrophases.component';
import { CategoriesComponent } from './categories/categories.component';
import { BranchesComponent } from './branches/branches.component';
import { DashboardListComponent } from './dashboard-list/dashboard-list.component';
import { UserAreaComponent } from './user-area/user-area.component';
import { CompanyDtailComponent } from './companies/company-dtail/company-dtail.component';
import { ClaimListComponent } from './claim-list/claim-list.component';
import { ClaimComponent } from './claim/claim.component';
import { FileUploadModalComponent } from './modals/file-upload-modal/file-upload-modal.component';
import { SubjectDetailComponent } from './subject/subject-detail/subject-detail.component';
import { SubjectSectionDetailComponent } from './subject-section/subject-section-detail/subject-section-detail.component';
import { BuildingDetailComponent } from './building/building-detail/building-detail.component';
import { TeamsComponent } from './teams/teams.component';
import { ObjectivesComponent } from './objectives/objectives.component';
import { TeamDetailComponent } from './teams/team-detail/team-detail.component';
import { ActivitiesComponent } from './activities/activities.component';
import { ActivitiesDetailComponent } from './activities/activities-detail/activities-detail.component';
import { SurveyCreatorComponent } from './survey/survey-creator/survey-creator.component';
import { RequestDetailComponent } from './request/request-detail/request-detail.component';
import { RequestListNewComponent } from './request/request-list/request-list.component';
import { OrderFeeComponent } from './order-fee/order-fee.component';
import { DetailPageGuard } from './guards/detail-page.guard';
import { GenericActionVerifierGuard } from './guards/generic-action-verifier.guard';
import { TargetsComponent } from './targets/targets.component';
import { TicketComponent } from './ticket/ticket.component';
import { ErpComponent } from './erp/erp.component';
import { TargetDetailComponent } from './targets/target-detail/target-detail.component';
import { TargetsListComponent } from './targets/targets-list/targets-list.component';
import { AdminLicenceComponent } from './admin-licence/admin-licence.component';
import { AdminLicenceDetailComponent } from './admin-licence/admin-licence-detail/admin-licence-detail.component';
import { OutgoingEmailResolverService } from './email-template/outgoing-email-resolver.service';
import { EmailTemplateComponent } from './email-template/email-template/email-template.component';
import { SurveysMenuComponent } from './surveys-menu/surveys-menu.component';
import { OrdersEndorsementComponent } from './orders-endorsement/orders-endorsement.component';
import { ActiveInvoicingComponent } from './active-invoicing/active-invoicing.component';
import { DebtCollectionComponent } from './debt-collection/debt-collection.component';
import { PluginFinanceComponent } from './plugin-finance/plugin-finance.component';
import { PluginProduzioneComponent } from './plugin-produzione/plugin-produzione.component';
import { PluginAcquistiComponent } from './plugin-acquisti/plugin-acquisti.component';
import { SubjectSectionComponent } from './subject-section/subject-section.component';
import { CalendarContractsComponent } from './settings/calendar-contracts/calendar-contracts.component';
import { CalendarContractsFormComponent } from './settings/calendar-contracts/calendar-contract-form/calendar-contracts-form';
import { AbsenceModulesComponent } from './settings/absence-modules/absence-module.component';
import { AbsenceModulesFormComponent } from './settings/absence-modules/absence-modules-form/absence-modules-form';


/*
import { WorktypeDetailComponent } from './worktypes/worktype-detail/worktype-detail.component';
import { ActivityTypesComponent } from './activity-types/activity-types.component';
import { ActivityTypesDetailComponent } from './activity-types/activity-types-detail/activity-types-detail.component';
import { ActivityReasonsDetailComponent } from './activity-reasons/activity-reasons-detail/activity-reasons-detail.component';
import { CostsConfigDetailComponent } from './costs-config/costs-config-detail/costs-config-detail.component';
import { LevelsDetailComponent } from './levels/levels-detail/levels-detail.component';
import { WorkCausalsComponent } from './work-causals/work-causals.component';
import { WorkCausalsDetailComponent } from './work-causals/work-causals-detail/work-causals-detail.component';
*/

const routes: Routes = [
  { path: 'macrophases', component: MacrophasesComponent },
  { path: 'phases', component: PhasesComponent },
  { path: 'branches', component: BranchesComponent },
  { path: 'categories', component: CategoriesComponent },
  { path: 'signin', component: SignInComponent },
  { path: 'domains', component: DomainsComponent },
  {
    path: 'final-balancer',
    loadChildren: () =>
      import('./final-balancer/final-balancer.module').then(
        (m) => m.FinalBalancerModule
      ),
  },
  {
    path: 'planner',
    loadChildren: () =>
      import('./planner/planner.module').then((m) => m.PlannerModule),
  },
  { path: 'orders', component: PracticesComponent },
  { path: 'practices', component: PracticesComponent },
  /*
  { path: 'crm', component: CrmComponent,  children: [
    {
      path: 'subjects',
      data: { sectionType: 'subjects' },
      component: CrmSectionsComponent,
    },
    {
      path: 'requests',
      data: { sectionType: 'requests' },
      component: CrmSectionsComponent,
    },
    {
      path: 'activities',
      data: { sectionType: 'activities' },
      component: CrmSectionsComponent,
    },
    {
      path: 'claims',
      data: { sectionType: 'claims' },
      component: CrmSectionsComponent,
    },
  ], },
  */
  { path: 'crm', component: CustomerRelationshipManagementComponent, data: {keyFrontEnd: "OP-CRM"}, canActivate: [GenericActionVerifierGuard] },
  { path: 'plannerold', component: PlannerComponent },
  { path: 'teams', component: TeamsComponent },
  { path: 'teams/:id', component: TeamDetailComponent },
  { path: 'profiles', component: ProfilesComponent, data: {keyFrontEnd: "AMM-PROFILI"}, canActivate: [GenericActionVerifierGuard] },
  { path: 'settings', component: SettingsComponent }, //data: {keyFrontEnd: "settings_index"}, canActivate: [GenericActionVerifierGuard] },
  { path: 'settings/actions', component: ActionsComponent },  //data: {keyFrontEnd: "actions_index"}, canActivate: [GenericActionVerifierGuard] },
  { path: 'settings/action-profile-settings', component: ActionProfileSettingsComponent },  //data: {keyFrontEnd: "action_settings_index"}, canActivate: [GenericActionVerifierGuard] },
  { path: 'settings/action-settings', component: ActionSettingsComponent },  //data: {keyFrontEnd: "action_settings_index"}, canActivate: [GenericActionVerifierGuard] },
  { path: 'settings/action-settings/:id', component: ActionSettingsFormComponent },
  { path: 'settings/workflow-settings', component: WorkflowSettingsComponent },  //data: {keyFrontEnd: "action_settings_index"}, canActivate: [GenericActionVerifierGuard] },
  { path: 'settings/workflow-settings/:id', component: WorkflowSettingsFormComponent },
  { path: 'settings/subject-type-settings', component: SubjectTypeSettingsComponent },  //data: {keyFrontEnd: "action_settings_index"}, canActivate: [GenericActionVerifierGuard] },
  { path: 'settings/subject-type-settings/:id', component: SubjectTypeSettingsFormComponent },
  { path: 'settings/translation-settings', component: TranslationSettingsComponent },  //data: {keyFrontEnd: "action_settings_index"}, canActivate: [GenericActionVerifierGuard] },
  { path: 'settings/calendar-settings', component: CalendarSettingsComponent }, 
  { path: 'settings/calendar-contracts-form/:id', component: CalendarContractsFormComponent }, 
  { path: 'settings/calendar-contracts', component: CalendarContractsComponent },  
  { path: 'settings/translation-settings/:id', component: TranslationSettingsFormComponent},
  { path: 'settings/marketing-note-settings', component: MarketingNoteSettingsComponent, data: {keyFrontEnd: "AMM-MARKETING-NOTES"}, canActivate: [GenericActionVerifierGuard]  },  //data: {keyFrontEnd: "action_settings_index"}, canActivate: [GenericActionVerifierGuard] },
  { path: 'settings/marketing-note-settings/:id', component: MarketingNoteSettingsFormComponent, data: {keyFrontEnd: "AMM-MARKETING-NOTES"}, canActivate: [GenericActionVerifierGuard] },
  { path: 'settings/absence-modules', component: AbsenceModulesComponent },
  { path: 'settings/absence-modules-form/:id', component: AbsenceModulesFormComponent },
  { path: 'settings/translation-settings/:id', component: TranslationSettingsFormComponent },
  { path: 'journeys', component: JourneysComponent },
  { path: 'subjects', component: SubjectComponent },
  { path: 'subject/:id', component: SubjectSectionDetailComponent },
  { path: 'subjects/:id', component: SubjectSectionDetailComponent },
  { path: 'buildings', component: BuildingComponent },
  { path: 'buildings/:id', component: BuildingDetailComponent },
  { path: 'users', component: UsersComponent, data: {keyFrontEnd: "AMM-UTENTI"}, canActivate: [GenericActionVerifierGuard] },
  { path: 'ad-license', component: AdminLicenceComponent },
  { path: 'ad-license/:id', component: AdminLicenceDetailComponent },
  { path: 'op-license', component: OperatorLicenseComponent },
  { path: 'cockpit', component: CockpitByProfileComponent },
  { path: 'companies', component: CompaniesComponent },
  { path: 'companies/:id', component: CompanyDtailComponent },
  { path: 'certificates', component: CertificatesComponent },
  {
    path: 'paychecks-per-ten',
    component: PaychecksPerTenComponent,
    data: { keyFrontEnd: "AMM-CEDOLINO-AL-10" },
    canActivate: [GenericActionVerifierGuard]
  },
  {
    path: 'paychecks-per-ten/calendar-settings',
    component: CalendarSettingsComponent,
    data: { keyFrontEnd: "AMM-HOLIDAY-CALENDAR" },
    canActivate: [GenericActionVerifierGuard]
   },  
  { path: 'datain', component: DatainComponent },
  {
    path: 'paychecks-per-ten/calendar-contracts',
    component: CalendarContractsComponent,
    data: { keyFrontEnd: "AMM-CALENDAR_CONTRACTS" },
    canActivate: [GenericActionVerifierGuard]
  },
  {
    path: 'paychecks-per-ten/absence-modules',
    component: AbsenceModulesComponent,
    data: { keyFrontEnd: "AMM-ABSENCE_MODULES" },
    canActivate: [GenericActionVerifierGuard]
  },
  { path: 'my-branch', component: MyHeadquartersComponent },
  { path: 'final-balancer-multi', component: FinalBalancerMultiComponent },
  { path: 'dpi', component: DpiComponent },
  { path: 'transfer-receipts', component: TransferReceiptsComponent },
  { path: 'credits', component: CreditsComponent },
  { path: 'home', component: HomComponent },
  { path: 'profile/:id', component: ProfileItemComponent },
  { path: 'domain/:id', component: DomainItemComponent },
  { path: 'orderOld/:id', component: OrderDtailComponent },
  { path: 'order/:id', component: PracticeDtailComponent },
  { path: 'practices/:id', component: PracticeDtailComponent },
  { path: 'dashboards', component: DashboardListComponent },
  { path: 'locations', component: LocationsComponent },
  //{ path: 'dashboard-edit/:id', component: DashboardCreateComponent},
  { path: 'dashboard/:id', component: DashboardComponent },
  //{ path: 'genconfig', component: GenConfigComponent },
  { path: 'outgoing-email/:id', component: EmailTemplateComponent, resolve: { settings: OutgoingEmailResolverService } },
  { path: 'user-area', component: UserAreaComponent },
  { path: 'requests', component: RequestListNewComponent },
  { path: 'request/:id', component: RequestDetailComponent },
  { path: 'claims', component: ClaimListComponent },
  { path: 'claim/:id', component: ClaimComponent },
  { path: 'activity/:id', component: ActivitiesDetailComponent },
  { path: 'survey-wizard/:id', component: SurveyCreatorComponent },
  { path: 'test-request/:id', component: RequestDetailComponent },
  { path: 'targets', component: TargetsComponent },
  { path: 'orderfee', component: OrderFeeComponent },
  { path: 'helpdesk', component: TicketComponent },
  { path: 'erp', component: ErpComponent },
  { path: 'target/:id', component: TargetDetailComponent },
  { path: 'branch/:id', component: BranchDetailComponent },
  { path: 'branch-company/:id', component: BranchCompanyComponent },
  { path: 'category/:id', component: CategoryDetailComponent },
  { path: 'macrophase/:id', component: MacrophaseDetailComponent },
  { path: 'phase/:id', component: PhaseDetailComponent },
  { path: 'fiscalsteps/:id', component: TaxstepDetailComponent },
  { path: 'surveysmenu', component: SurveysMenuComponent },
  { path: 'ordersendorsement', component: OrdersEndorsementComponent },
  //  { path: 'workType/:id', component: WorktypeDetailComponent },
  { path: 'activeinvoicing', component: ActiveInvoicingComponent },
  { path: 'debtcollection', component: DebtCollectionComponent },
  { path: 'pluginacquisti', component: PluginAcquistiComponent },
  { path: 'pluginproduction', component: PluginProduzioneComponent },
  { path: 'pluginfinance', component: PluginFinanceComponent },
  //  { path: 'level/:id', component: LevelsDetailComponent },
  // { path: 'causal/:id', component: WorkCausalsDetailComponent },
  //  { path: 'cost-config/:id', component: CostsConfigDetailComponent },
  // { path: 'activityType/:id', component: ActivityTypesDetailComponent },
  //  { path: 'activityTypeReason/:id', component: ActivityReasonsDetailComponent },
  //  { path: 'prova', component: ActivityTypesComponent },
  // { path: 'targetTypesList', component: TargetsListComponent },
  { path: '**', component: CockpitComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthService],
})
export class AppRoutingModule { }
