import { Component, OnInit, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { PecService } from 'src/app/services/pec.service';
import { LibService } from 'src/app/services/libService';
import { PecTypeService } from 'src/app/services/pecType.service';
import { combineLatest } from 'rxjs';
import { SubjectService } from 'src/app/services/subject.service';
import { OrderService } from 'src/app/services/order.service';
import { AuthService } from 'src/app/auth/auth.service';

interface formInterface {
  id: number,
  practiceId: number,
  pecType: number,
  description: string,
  authorId: number,
  itemId: number,
  stateId: number,
  subject: subject,
  insertSubjectType: number,
  subjectAlias?: string
};

interface subject {
  id?: number,
  name?: string,
  surname?: string,
  legalEntity?: string,
  email?: string,
  telephone2?: string,
}

@Component({
  selector: 'app-pec-form',
  templateUrl: './pec-form.component.html',
  styleUrls: ['./pec-form.component.scss']
})
export class PecFormComponent implements OnInit {

  @Input() item: any;
  @Output() closeModal = new EventEmitter;

  form: FormGroup;
  pecTypes: any = []

  pecTypes$ = this.pecTypeService.index();

  userName = this.authService.user().name;

  practices: any = []

  insertSubjectTypes: any = [
    'search', 'new'
  ]

  constructor(
    private toastr: ToastrService,
    public translate: TranslateService,
    private fb: FormBuilder,
    private pecService: PecService,
    private libService: LibService,
    private pecTypeService: PecTypeService,
    public subjectService: SubjectService,
    public orderService: OrderService,
    public authService: AuthService,
  ) {
  }

  getInsertSubjectType() {
    return this.form?.get('insertSubjectType')?.value;
  }


  selSubject(event: any) {
    //console.log('Selected: ', event);
    this.form?.get('subject')?.patchValue(event)
    this.researchSubjectPractices()
  }

  getSubject() {
    return this.form?.get('subject')?.value;
  }

  researchSubjectPractices() {
    let subjectId = this.form.get('subject')?.value?.id
    if (subjectId) {
      this.libService.lockPage('');
      this.orderService.getOrderBySubjectId(this.userName, subjectId, 'subject').subscribe((res: any) => {
        this.practices = res
        this.libService.unlockPage();
      })
    }
  }

  ngOnInit(): void {

    combineLatest([this.pecTypes$]).subscribe(([pecTypes]) => {
      this.pecTypes = pecTypes

    })

         // Creazione Form
         this.form = this.fb.group({
          id: [null],
          practiceId: [null],
          pecType: [null],
          pecTypeValue: [null, [Validators.required]],
          description: [null],
          authorId: [null],
          itemId: [null],
          stateId: [null],
          emailPec: [null],
          subject: this.fb.group({
            id: [null],
            name: [null],
            surname: [null],
            legalEntity: [null],
            email: [null],
            telephone2: [null],
          }),
          insertSubjectType: [null],
          subjectAlias: [null]
        })
    
        this.form.patchValue(this.item)
        this.form?.get('pecTypeValue')?.setValue(this.item?.pecType?.id)
        this.setInsertSubjectType()
        this.researchSubjectPractices()
        //console.log(this.form)


  }

  insertSubjectTypeChange(event: any) {
    //console.log('insertSubjectTypeChange')
    //console.log(event)
    this.form.get('subject')?.reset();
    this.form.get('subjectAlias')?.reset();
    this.form.get('practiceId')?.reset();
  }

  setInsertSubjectType() {
    let value = 'search'
    if (this.form?.get('subjectAlias')?.value) value = 'alias'
    this.form?.get('insertSubjectType')?.setValue(value)
  }

  ngOnChanges(changes: SimpleChanges): void { }

  checkduplicate() {

    let item = this.form?.get('subject')?.value
    let insertSubjectType = this.form?.get('insertSubjectType')?.value
    let call$ = this.subjectService.findSubjectByPhoneAndMail(item)
    let id = item?.id
    let message = ''
    if (id === null) {
      call$.subscribe((response: any) => {
       // console.log(response)
        if (response && insertSubjectType == 'new') {
          message += this.translate.instant('TOASTR.MESSAGE17') + (item.name ? item.name : '') + ' ' + (item.surname ? item.surname : '') + this.translate.instant('TOASTR.MESSAGE18');
          if (message != '') this.toastr.error(message, this.translate.instant('TOASTR.WARNING'), { enableHtml: true, timeOut: 10000 });
        } else {
          this.createOrUpdate()
        }

      })
    }else{
      this.createOrUpdate()
    }

  }

  createOrUpdate() {
    this.libService.lockPage('');
    let id = this.form?.get('id')?.value || null

    // Select current PecType
    let pecTypeValue = this.form?.get('pecTypeValue')?.value
    this.form?.get('pecType')?.setValue({id: pecTypeValue})

    if (id) {
      this.pecService.update(this.item?.id?.toString(), this.form.getRawValue()).subscribe((res: any) => {
        //console.log(res);
        this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
        this.closeModal.emit(true);
        this.libService.unlockPage();
      })
    } else {
      this.pecService.create(this.form.getRawValue()).subscribe((res: any) => {
        //console.log(res);
        this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
        this.closeModal.emit(true);
        this.libService.unlockPage();
      })
    }
  }

  save() {
    this.checkduplicate()

  }

  itIsAFreeSubject() {
    return this.form?.get('freeSubject')?.value || false
  }

  theSubjectIsPresent() {
    return this.form?.get('subject')?.value || false
  }

  fullname() {
    let complainant: any = this.item?.complainant
    return `${complainant?.name} ${complainant?.surname}`
  }

}