<div class="full-w">

  <form [formGroup]="form" (submit)="save()" class="form-container">

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'REVIEWS.reason' | translate }}</mat-label>
      <mat-select formControlName="pecTypeValue">
        <mat-option *ngFor="let pecType of pecTypes" [value]="pecType.id"
          [disabled]="pecType.disabled">
          {{ pecType.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <br>

    <div appearance="fill" class="form-subject">
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title> {{ 'LABEL.MITTENTEPEC' | translate }} </mat-card-title>

        </mat-card-header>
        <mat-card-content>


          <mat-form-field appearance="fill" class="form-subject">
            <mat-label>{{ 'REVIEWS.insertSubjectType' | translate }}</mat-label>
            <mat-select formControlName="insertSubjectType" (selectionChange)="insertSubjectTypeChange($event)" >
              <mat-option *ngFor="let insertSubjectType of insertSubjectTypes" [value]="insertSubjectType">
                {{ 'INSERTSUBJECTTYPE.' + insertSubjectType | translate }} 
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div *ngIf="getInsertSubjectType() == 'search'">

            <app-fulltextsearch2 class="form-subject" [service]="subjectService" searchType="Soggetto"
              (selected)="selSubject($event)" [edit]="false"></app-fulltextsearch2>

            <div *ngIf="theSubjectIsPresent()">

              <mat-form-field appearance="fill" class="form-subject">
                <mat-label>{{ 'REQUEST.NAME' | translate }}</mat-label>
                <input matInput readonly [value]="getSubject()?.name" [placeholder]="translate.instant('REQUEST.NAME')">
              </mat-form-field>

              <mat-form-field appearance="fill" class="form-subject">
                <mat-label>{{ 'REQUEST.SURNAME' | translate }}</mat-label>
                <input matInput readonly [value]="getSubject()?.surname"
                  [placeholder]="translate.instant('REQUEST.SURNAME')">
              </mat-form-field>

              <mat-form-field *ngIf="item.subject?.legalEntity" appearance="fill" class="form-subject">
                <mat-label>{{ 'REQUEST.LEGAL_ENTITY' | translate }}</mat-label>
                <input matInput readonly [value]="getSubject()?.legalEntity"
                  [placeholder]="translate.instant('REQUEST.LEGAL_ENTITY')">
              </mat-form-field>

              <mat-form-field *ngIf="item.subject?.email" appearance="fill" class="form-subject">
                <mat-label>{{ 'REQUEST.EMAIL' | translate }}</mat-label>
                <input matInput readonly [value]="getSubject()?.email"
                  [placeholder]="translate.instant('REQUEST.EMAIL')">
              </mat-form-field>

              <mat-form-field *ngIf="item.subject?.telephone2" appearance="fill" class="form-subject">
                <mat-label>{{ 'LABEL.PHONE' | translate }}</mat-label>
                <input matInput readonly [value]="getSubject()?.telephone2"
                  [placeholder]="translate.instant('LABEL.PHONE')">
              </mat-form-field>

              <mat-form-field appearance="fill" class="form-subject">
                <mat-label>{{ 'REVIEWS.practice' | translate }}</mat-label>
                <mat-select formControlName="practiceId">
                  <mat-option *ngFor="let practice of practices" [value]="practice.PracticeId">
                    {{ practice.code }} - {{ practice.streetName }} {{ practice.streetNumber }} {{ practice.city }}
                  </mat-option>
                </mat-select>
              </mat-form-field>


            </div>

          </div>

          <div formGroupName="subject" *ngIf="getInsertSubjectType() == 'new'" class="form-container">
        
            <mat-form-field appearance="fill" class="form-subject">
              <mat-label>{{ 'REQUEST.NAME' | translate }}</mat-label>
              <input matInput formControlName="name" [placeholder]="translate.instant('REQUEST.NAME')">
            </mat-form-field>

            <mat-form-field appearance="fill" class="form-subject">
              <mat-label>{{ 'REQUEST.SURNAME' | translate }}</mat-label>
              <input matInput formControlName="surname"
                [placeholder]="translate.instant('REQUEST.SURNAME')">
            </mat-form-field>

            <mat-form-field  appearance="fill" class="form-subject">
              <mat-label>{{ 'REQUEST.LEGAL_ENTITY' | translate }}</mat-label>
              <input matInput formControlName="legalEntity"
                [placeholder]="translate.instant('REQUEST.LEGAL_ENTITY')">
            </mat-form-field>

            <mat-form-field  appearance="fill" class="form-subject">
              <mat-label>{{ 'REQUEST.EMAIL' | translate }}</mat-label>
              <input matInput formControlName="email"
                [placeholder]="translate.instant('REQUEST.EMAIL')">
            </mat-form-field>

            <mat-form-field  appearance="fill" class="form-subject">
              <mat-label>{{ 'LABEL.PHONE' | translate }}</mat-label>
              <input matInput formControlName="telephone2"
                [placeholder]="translate.instant('LABEL.PHONE')">
            </mat-form-field>
   
          </div>

          <div *ngIf="getInsertSubjectType() == 'alias'">
            <mat-form-field appearance="fill" class="form-subject">
              <mat-label>{{ 'REVIEWS.subjectAlias' | translate }}</mat-label>
              <input matInput formControlName="subjectAlias" [placeholder]="translate.instant('REVIEWS.subjectAlias')">
            </mat-form-field>
          </div>



        </mat-card-content>
      </mat-card>
    </div>

    <br>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'LABEL.EMAILPEC' | translate }}</mat-label>
      <input matInput formControlName="emailPec" [placeholder]="translate.instant('LABEL.EMAILPEC')">
    </mat-form-field>
    

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'REVIEWS.description' | translate }}</mat-label>
      <textarea matInput [placeholder]="translate.instant('REVIEWS.description')"
        formControlName="description"></textarea>
    </mat-form-field>

    <div class="buttonBox">
      <button [disabled]="!form.valid" class="submitButton" type="submit" mat-raised-button color="primary">
        <mat-icon>save</mat-icon> {{ 'LABEL.SAVE' | translate }}
      </button>
    </div>
    <br>
  </form>

</div>