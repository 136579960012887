<div class="b2">

    <div *ngIf="order && !interfaceChangesForDubai()" id="fixedresume" class="com-cont" [ngClass]="{
        'openBox': orderSummaryBoxStatus,
        'closeBox': !orderSummaryBoxStatus,
        'limitWidth': !showFieldsNotYetImplemented
    }" cdkDrag [cdkDragFreeDragPosition]="dragPosition">

        <mat-toolbar color="primary" class="moveCursor" cdkDragHandle>
            <button (click)="toggleOrderSummaryBox($event)" mat-icon-button>
                <mat-icon>{{orderSummaryBoxIcon}}</mat-icon>
            </button>
            <span (click)="toggleOrderSummaryBox($event)" class="title">{{'LABEL.RiepilogoOrdine' | translate }}</span>
        </mat-toolbar>

        <div class="box" *ngIf="orderSummaryBoxStatus">

            <div class="card-title">
                <span class="material-icons-outlined">vpn_key</span>
                <div class="c1">&nbsp;{{'LABEL.Codice' | translate }}</div>
                <div class="c2_1"><b>{{order.code}}</b></div>
                <div class="c3_1">&nbsp; </div>
                <div class="c4_1">

                </div>
            </div>
            <div class="card-title">
                <span class="material-icons-outlined">person</span>
                <div class="c1">&nbsp;{{'LABEL.UltimaModifica' | translate }}</div>
                <div class="c2"><b>{{order.who}}</b></div>
            </div>
            <div class="card-title">
                <div class="c1"><span class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.RicavoStandard'
                    |
                    translate }}</div>
                <div class="c1"><b>{{getRicavoTotale() | number: '1.2-2'}}</b></div>
                <div class="c1"><span class="material-icons-outlined">payments</span>&nbsp;{{'LABEL.CostoStandard' |
                    translate }}</div>
                <div class="c1"><b>{{getCostoTotale() | number: '1.2-2'}}</b></div>

                <div *ngIf="showFieldsNotYetImplemented" class="c1"><span
                        class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.MaterialePreventivato' |
                    translate }}</div>
                <div *ngIf="showFieldsNotYetImplemented" class="c1"><b>{{getMaterialePreventivato()}}</b></div>

                <div class="c1"><span class="material-icons-outlined">savings</span>&nbsp;{{'LABEL.MargineStandard' |
                    translate }}</div>
                <div class="c1"><b>{{getMargineTotale() | number: '1.2-2'}}%</b></div>
            </div>
            <div class="card-title">
                <div class="c1"><span class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.RicavoReale' |
                    translate }}</div>
                <div class="c1">
                    <b>{{getRicavoReale() | number: '1.2-2'}}</b>
                </div>
                <div class="c1">
                    <div>
                        <span class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.CostoOneriSicurezza'
                        |
                        translate }}
                    </div>
                </div>
                <div class="c1">

                    <b>{{getOneriReale() | number: '1.2-2'}}</b>
                </div>

                <div class="c1"><span
                        class="material-icons-outlined">account_balance</span>&nbsp;{{'LABEL.CostoOneriFinanziamento'
                    |
                    translate }}
                </div>
                <div class="c1">

                    <b>{{getOneriFinanziamento() | number: '1.2-2'}}</b>
                </div>

                <div class="c1" [ngStyle]="{color:getColorMargin()}"><span
                        class="material-icons-outlined">savings</span>&nbsp;{{'LABEL.MargineReale' | translate
                    }}</div>
                <div class="c1" [ngStyle]="{color:getColorMargin()}"><b>{{getMargineReale() | number: '1.2-2'}}%</b>
                </div>
            </div>
            <div class="card-title">
                <div class="c1"><span class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.DeliberatoReale'
                    |
                    translate }}</div>
                <div class="c1">
                    <b>{{getValueDeliber() | number: '1.2-2'}}</b>
                </div>
                <div class="c1"><span class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.CostoCessione' |
                    translate }}
                </div>
                <div class="c1">
                    <b>{{getCostoCessione() | number: '1.2-2'}}</b>
                </div>

                <div class="c1"><span
                        class="material-icons-outlined">account_balance</span>&nbsp;{{'LABEL.MaggFinanziamento' |
                    translate }}
                </div>
                <div class="c1">
                    <b>{{getMaggiorazioneFinanziamento() | number: '1.2-2'}}</b>
                </div>

                <div class="c1"><span class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.Venduto' |
                    translate }}</div>
                <div class="c1"><b>{{getCostoVenduto() | number: '1.2-2'}}</b></div>
            </div>

            <div *ngIf="showFieldsNotYetImplemented" class="card-title">
                <div class="c1"></div>
            </div>

            <div *ngIf="showFieldsNotYetImplemented" class="card-title">

                <div class="c1"><span
                        class="material-icons-outlined">hourglass_empty</span>&nbsp;{{'LABEL.OreDeliberate'
                    |
                    translate }}</div>
                <div class="c1">
                    <b>{{getOreDeliberate()}}</b>
                </div>

                <div class="c1"><span class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.ValoreProdotto'
                    |
                    translate }}
                </div>
                <div class="c1">
                    <b>{{getValoreProdotto()}}</b>
                </div>

                <div class="c1"><span
                        class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.MaterialeConsuntivato' |
                    translate }}</div>
                <div class="c1"><b>{{getMaterialeConsuntivato()}}</b></div>

                <div class="c1"><span class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.Certificato' |
                    translate }}</div>
                <div class="c1"><b>{{getCertificato()}}</b></div>
            </div>

            <div *ngIf="showFieldsNotYetImplemented" class="card-title">

                <div class="c1"><span
                        class="material-icons-outlined">hourglass_empty</span>&nbsp;{{'LABEL.OrePianificate'
                    |
                    translate }}</div>
                <div class="c1">
                    <b>{{getOrePianificate()}}</b>
                </div>

                <div class="c1"><span
                        class="material-icons-outlined">percent</span>&nbsp;{{'LABEL.PercentualeAvanzamentoSAL' |
                    translate }}
                </div>
                <div class="c1">
                    <b>{{getPercentualeAvanzamentoSAL()}}</b>
                </div>

                <div class="c1">&nbsp;</div>
                <div class="c1"></div>

                <div class="c1"><span class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.Fatturato' |
                    translate }}</div>
                <div class="c1"><b>{{getFatturato()}}</b></div>
            </div>

            <div *ngIf="showFieldsNotYetImplemented" class="card-title">

                <div class="c1"><span
                        class="material-icons-outlined">hourglass_empty</span>&nbsp;{{'LABEL.OreConsuntivate' |
                    translate }}</div>
                <div class="c1">
                    <b>{{getOreConsuntivate()}}</b>
                </div>

                <div class="c1">
                    <span class="material-icons-outlined">attach_money</span>&nbsp;<span>{{'LABEL.ValoreTragitto' |
                        translate }}
                    </span>
                </div>
                <div class="c1">
                    <b>{{preventivoTragitti() | number: '1.2-2'}}</b>
                </div>

                <div class="c1"><span
                        class="material-icons-outlined">percent</span>&nbsp;{{'LABEL.PercentualeIncidenzaMateriali'
                    |
                    translate }}</div>
                <div class="c1"><b>{{getPercentualeIncidenzaMateriali()}}</b></div>

                <div class="c1"><span class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.Incassato' |
                    translate }}</div>
                <div class="c1"><b>{{getIncassato()}}</b></div>
            </div>

        </div>
    </div>


</div>
<div class="container-fluid my-container">

    <div class="b2">

        <div class="com-cont" style="width:40%">
            <div class="card-title">
                <div class="cb">
                    <span>{{ getStatusLabel(practice?.StateId) | translate}}</span>
                </div>
            </div>
            <div class="card-title" *ngIf="isWarningApprove() == true" style="color:#833d29;"> <mat-icon
                    class="material-icons">warning</mat-icon>{{'LABEL.WARNING_APPROVAL' | translate}}</div>
            <div class="card-title" *ngIf="isWarningApproveOver() == true" style="color:#833d29;"> <mat-icon
                    class="material-icons">warning</mat-icon>{{'LABEL.WARNING_APPROVAL_OVER' | translate}}</div>
            <div class="card-title">
                <div *ngIf="(!(order==null))"><b>{{order.code}}.{{order.version}} - {{order.note}}</b>
                    <br><b>{{'LABEL.BRANCH' | translate }}: {{currentBranch?.code}} ({{currentBranch?.codCountry}})</b>
                </div>
            </div>
            
            
            <div class="card-title" *ngIf="isEnergyPractice()">
                <b>Totale kW: {{energyPracticeInfo().totalPower}}</b><br>
            </div>
            

            <div class="card-title">
                <div *ngIf="childrenPractice.length != 0">
                    <b>{{'LABEL.PRATICACOLLEGATA' | translate }}:</b>
                    <ul>
                        <li class="practiceLink" *ngFor="let child of childrenPractice"
                            (click)="navigateToTargetPractice(child.code)">
                            {{child.code}} ({{'TYPOLOGIESRELATIONSHIPSBETWEENPRACTICES.' + child.type | translate }})
                        </li>
                    </ul>
                </div>
            </div>
            <div *ngIf="(!(order==null))" class="card-title">
                <div
                    *ngIf="(!(order == null)) && (!(order.OPRel == null)) && (order.OPRel.length > 0) && (!(order.OPRel[0].codeOldSystem == null))">
                    {{'LABEL.CODE_OLD_SYSTEM' | translate}}: <b>{{order.OPRel[0].codeOldSystem}}</b></div>
            </div>
            <div *ngIf="(!(order == null) && order.isSalCento==true)" class="card-title">
                <mat-icon class="material-icons">warning</mat-icon><b>SAL 100</b>
            </div>
        </div>
    </div>
    <div class="buttons-right mar-top-icons">

        <!-- COMPONENTE BOTTONE CAMBIO STATE -->
        <app-practice-state-migration [form]="orderFormGroup" *ngIf="practice && documents" [practice]="practice"
            [components]="components"></app-practice-state-migration>

        <!-- COMPONENTE BOTTONE APERTURA RECLAMO -->
        <app-practice-button-complaint-opening [form]="orderFormGroup" *ngIf="practice && documents"
            [practice]="practice" [components]="components"></app-practice-button-complaint-opening>

        <!-- COMPONENTE BOTTONE CAMBIA STATO -->
        <app-change-state-button [form]="orderFormGroup" *ngIf="practice && documents" [practice]="practice"
            [costoVenduto]="getCostoVenduto()" [components]="components" [order]="order"></app-change-state-button>

    </div>
    <br>
    <mat-stepper class="example-stepper" [orientation]="(stepperOrientation | async)!"
        (selectionChange)="selectionChangeStepper($event)" #stepper>
        <mat-step [stepControl]="buildingFormGroup" label="{{'LABEL.BUILDING' | translate}}" state="checked"
            #first_step>
            <ng-container *ngIf="stepper.selected == null || stepper.selected == first_step">
                <form [formGroup]="buildingFormGroup" class="myform">
                    <h3>{{'LABEL.Edificio' | translate }}</h3>
                    <h4 *ngIf="(buildingFormGroup.controls['building'] == null) || !(buildingFormGroup.controls['building'].value?.id > 0)"
                        style="color:red;">{{'LABEL.NessunEdificioSelezionato' | translate }}</h4>
                    <table
                        *ngIf="!(buildingFormGroup.controls['building'] == null) && buildingFormGroup.controls['building'].value?.id > 0"
                        class="container">
                        <tbody>
                            <tr>
                                <th style="width:30%;">{{'LABEL.Codice' | translate }}</th>
                                <td style="width:52%;">{{ buildingFormGroup.controls['building'].value?.id }}</td>
                                <td style="width:6%;">
                                    <span [matTooltip]="translate.instant('LABEL.Vedi')"
                                        (click)="clickEdificio( buildingFormGroup.controls['building'].value?.id)"><i
                                            class="my-bounce material-icons icsave">travel_explore</i></span>
                                </td>
                                <td style="width:6%;">
                                    <span [matTooltip]="translate.instant('LABEL.CompilaRelazioni')"
                                        (click)="compileRelations()"><i
                                            class="my-bounce material-icons icsave">file_upload</i></span>
                                </td>

                            </tr>
                            <tr>
                                <th style="width:30%;">{{'LABEL.SEDERIF' | translate}}</th>
                                <td style="width:52%;">{{ buildingFormGroup.controls['building'].value?.company }}</td>
                                <td style="width:6%;"></td>
                                <td style="width:6%;"></td>
                                <td style="width:6%;"></td>
                            </tr>
                            <tr>
                                <th style="width:30%;">{{'LABEL.Nome' | translate}}</th>
                                <td style="width:52%;">{{ buildingFormGroup.controls['building'].value?.name }}</td>
                                <td style="width:6%;"></td>
                                <td style="width:6%;"></td>
                                <td style="width:6%;"></td>
                            </tr>
                            <tr>
                                <th style="width:30%;">{{'LABEL.Tipo' | translate}}</th>
                                <td style="width:52%;">{{ 'LABEL.'+ buildingFormGroup.controls['building'].value?.type |
                                    translate }}</td>
                                <td style="width:6%;"></td>
                                <td style="width:6%;"></td>
                                <td style="width:6%;"></td>
                            </tr>
                            <tr>
                                <th style="width:30%;">{{'LABEL.ADDRESS' | translate}}</th>
                                <td style="width:58%;">{{ buildingFormGroup.controls['building'].value?.streetName + ' '
                                    +
                                    buildingFormGroup.controls['building'].value?.streetNumber + ', ' +
                                    buildingFormGroup.controls['building'].value?.city + ' ' +
                                    buildingFormGroup.controls['building'].value?.zip + ' (' +
                                    buildingFormGroup.controls['building'].value?.province + ') ' +
                                    buildingFormGroup.controls['building'].value?.state }}</td>
                                <td style="width:6%;"></td>
                                <td style="width:6%;"></td>
                            </tr>
                        </tbody>
                    </table>
                </form>
                <div class="mrg-com-cont">
                    <mat-expansion-panel [matBadge]="getNumberDocumentsBuilding()">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <b>{{'LABEL.Documenti' | translate}}</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div
                            *ngIf="!(buildingFormGroup.controls['building'] == null) && !(buildingFormGroup.controls['building'].value == null) && !(buildingFormGroup.controls['building'].value.documents == null)">
                            <div *ngFor="let document of requestDocuments">

                                <div *ngIf="!(document == null)" class="mycard">
                                    <div>
                                        <span style="font-size: 16px;"><b>{{document.name}}</b> <br></span>
                                        <span *ngIf="!(document.typeName == null)"
                                            matTooltip="{{'FILE-UPLOAD.DOCUMENT-TYPE' | translate}}"
                                            style="font-size: 12px;"><b>{{'LABEL.' + document.typeName!.uiLabel |
                                                translate}}</b></span>
                                        <span *ngIf="(document.typeName == null)"
                                            matTooltip="{{'FILE-UPLOAD.DOCUMENT-TYPE' | translate}}"
                                            style="font-size: 12px;"><b>{{'LABEL.NULL_DOCUMENT_TYPE' |
                                                translate}}</b></span>
                                    </div>
                                    <div class="d-flex">
                                        <a class="btn btn-primary btn-sm me-3 text-dark"
                                            (click)="downloadDocument(document)">{{ 'FILE-UPLOAD.DOWNLOAD' |
                                            translate}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </div>
                <div class="mrg-com-cont">
                    <mat-expansion-panel [matBadge]="getNumberSurveys()">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <b>{{'LABEL.Questionari' | translate}}</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div
                            *ngIf="!(buildingFormGroup.controls['building'] == null) && !(buildingFormGroup.controls['building'].value == null) && !(buildingFormGroup.controls['building'].value.surveys == null)">
                            <div *ngFor="let survey of buildingFormGroup.controls['building'].value.surveys">
                                <div class="mycard">
                                    {{survey.surveyMasterOrigin.name}}&nbsp;{{'LABEL.del' |
                                    translate}}&nbsp;<b>{{survey.updatedAt | date:'short'}}</b>
                                    <br /><br />
                                    <div *ngFor="let a of survey.answers">
                                        {{a.question.text}}:&nbsp;<b>{{a.value}}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </div>
                <div>
                    <button style="background-color:transparent;" type="button" class="btn btn-primary" matStepperNext>
                        <mat-icon class="material-icons" style="color:#293683;">arrow_forward</mat-icon>
                    </button>
                </div>
            </ng-container>
        </mat-step>
        <mat-step [stepControl]="subjectFormGroup" label="{{'LABEL.SUBJECTS' | translate}}" state="checked"
            #second_step>
            <ng-container *ngIf="stepper.selected == null || stepper.selected == second_step">
                <h3>{{ 'LABEL.SUBJECTS' | translate }}</h3>
                <h4
                    *ngIf="!(subjectFormGroup.controls['subject'].value?.id > 0) && !(condominiumFormGroup.controls['condominium'].value?.id > 0) && !(adminFormGroup.controls['admin'].value?.id > 0) ">
                    {{ 'ORDERS.NO_OBJ_SEL' | translate }}</h4>

                <table *ngIf="condominiumFormGroup.controls['condominium'].value?.id > 0" class="container">
                    <tbody>
                        <tr>
                            <th style="width:30%;">{{'LABEL.CODE' | translate}}</th>
                            <td style="width:40%;">{{ condominiumFormGroup.controls['condominium'].value?.id }}</td>
                            <td style="width:6%;">
                                <span [matTooltip]="translate.instant('LABEL.Vedi')"
                                    (click)="clickCondominio(condominiumFormGroup.controls['condominium'].value?.id)"><i
                                        class="my-bounce material-icons icsave">travel_explore</i></span>
                            </td>

                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.CODE' | translate}} Delta</th>
                            <td style="width:40%;">{{ condominiumFormGroup.controls['condominium'].value?.sysCodeDelta
                                }}
                            </td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.SEDERIF' | translate}}</th>
                            <td style="width:40%;">{{ condominiumFormGroup.controls['condominium'].value?.company }}
                            </td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.Nome' | translate}}</th>
                            <td style="width:40%;">{{ condominiumFormGroup.controls['condominium'].value?.name }}</td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.Tipo' | translate}}</th>
                            <td style="width:40%;"><b>{{ condominiumFormGroup.controls['condominium'].value?.type }}</b>
                            </td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.PHONE' | translate}} 1</th>
                            <td style="width:40%;">{{ condominiumFormGroup.controls['condominium'].value?.telephone1 }}
                            </td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.PHONE' | translate}} 2</th>
                            <td style="width:40%;">{{ condominiumFormGroup.controls['condominium'].value?.telephone2 }}
                            </td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                    </tbody>
                </table>
                <hr *ngIf="condominiumFormGroup.controls['condominium'].value?.id > 0">
                <table *ngIf="subjectFormGroup.controls['subject'].value?.id > 0" class="container">
                    <tbody>
                        <tr>
                            <th style="width:30%;">{{'LABEL.CODE' | translate}}</th>
                            <td style="width:40%;">{{ subjectFormGroup.controls['subject'].value?.id }}</td>
                            <td style="width:6%;">
                                <span [matTooltip]="translate.instant('LABEL.Vedi')"
                                    (click)="clickSoggetto(subjectFormGroup.controls['subject'].value?.id)"><i
                                        class="my-bounce material-icons icsave">travel_explore</i></span>
                            </td>

                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.CODE' | translate}} Delta</th>
                            <td style="width:40%;">{{ subjectFormGroup.controls['subject'].value?.sysCodeDelta }}</td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.SEDERIF' | translate}}</th>
                            <td style="width:40%;">{{ subjectFormGroup.controls['subject'].value?.company }}</td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.Nome' | translate}}</th>
                            <td style="width:40%;">{{ subjectFormGroup.controls['subject'].value?.name }}</td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.Tipo' | translate}}</th>
                            <td style="width:40%;"><b>{{ subjectFormGroup.controls['subject'].value?.type }}</b></td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.PHONE' | translate}} 1</th>
                            <td style="width:40%;">{{ subjectFormGroup.controls['subject'].value?.telephone1 }}</td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.PHONE' | translate}} 2</th>
                            <td style="width:40%;">{{ subjectFormGroup.controls['subject'].value?.telephone2 }}</td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.EMAIL' | translate}}</th>
                            <td style="width:40%;">{{ subjectFormGroup.controls['subject'].value?.email }}</td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;">
                                <form [formGroup]="subjectFormGroup" *ngIf="!interfaceChangesForDubai()">
                                    <mat-checkbox class="cb-margin" formControlName="isSigning">
                                        {{'LABEL.FirmaElettronica' | translate }}</mat-checkbox>
                                </form>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr *ngIf="subjectFormGroup.controls['subject'].value?.id > 0">
                <table *ngIf="adminFormGroup.controls['admin'].value?.id > 0" class="container">
                    <tbody>
                        <tr>
                            <th style="width:30%;">{{'LABEL.CODE' | translate}}</th>
                            <td style="width:40%;">{{ adminFormGroup.controls['admin'].value?.id }}</td>

                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.CODE' | translate}} Delta</th>
                            <td style="width:40%;">{{ adminFormGroup.controls['admin'].value?.sysCodeDelta }}</td>
                            <td style="width:30%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.SEDERIF' | translate}}</th>
                            <td style="width:40%;">{{ adminFormGroup.controls['admin'].value?.company }}</td>
                            <td style="width:30%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.Nome' | translate}}</th>
                            <td style="width:40%;">{{ adminFormGroup.controls['admin'].value?.name }}</td>
                            <td style="width:30%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.Tipo' | translate}}</th>
                            <td style="width:40%;"><b>{{ adminFormGroup.controls['admin'].value?.type }}</b></td>
                            <td style="width:30%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.PHONE' | translate}} 1</th>
                            <td style="width:40%;">{{ adminFormGroup.controls['admin'].value?.telephone1 }}</td>
                            <td style="width:30%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.PHONE' | translate}} 2</th>
                            <td style="width:40%;">{{ adminFormGroup.controls['admin'].value?.telephone2 }}</td>
                            <td style="width:30%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.EMAIL' | translate}}</th>
                            <td style="width:40%;">{{ adminFormGroup.controls['admin'].value?.email }}</td>
                            <td style="width:30%;">
                                <form [formGroup]="adminFormGroup">
                                    <mat-checkbox class="cb-margin" formControlName="isSigning"
                                        *ngIf="!interfaceChangesForDubai()">
                                        {{'LABEL.FirmaElettronica' | translate }}</mat-checkbox>
                                </form>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div>
                    <button style="background-color:transparent;" type="button" class="btn btn-primary"
                        matStepperPrevious>
                        <mat-icon class="material-icons" style="color:#293683;">arrow_back</mat-icon>
                    </button>
                    <button style="background-color:transparent;" type="button" class="btn btn-primary" matStepperNext>
                        <mat-icon class="material-icons" style="color:#293683;">arrow_forward</mat-icon>
                    </button>
                </div>
            </ng-container>
        </mat-step>
        <mat-step [stepControl]="orderFormGroup" label="{{'LABEL.PREVENTIVI' | translate}}" state="checked" #third_step>
            <ng-container>
                <span *ngFor="let v of versions">

                    <mat-card *ngIf="(v.version == actualversion)" class="mrg-com-cont bck-sel">
                        <mat-card-title>
                            <span style="display: flex; flex-direction: row; justify-content:space-between !important;">
                                <span class="card-title">
                                    <span class="material-icons">check_circle</span>&nbsp;
                                    <span> {{ order.code }}.{{ order.version }}</span>&nbsp;
                                    <span *ngIf="!(v.name == null) && !(v.name == 'null')">&nbsp;-&nbsp;{{ v.name
                                        }}</span>
                                </span>
                                <span class="card-title-rigth">
                                    <div [matTooltip]="getToolTipPrint()" class="bt-consegna"
                                        *ngIf="!interfaceChangesForDubai()" (click)="downloadPrev(order)">
                                        <span class="material-icons">print</span>
                                    </div>
                                </span>
                            </span>
                        </mat-card-title>
                    </mat-card>
                </span>
                <mat-expansion-panel *ngIf="!(versions == null) && (versions.length > 1)"
                    [matBadge]="versions.length -1">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{'LABEL.Versioni' | translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <span *ngFor="let v of versions">
                        <mat-expansion-panel *ngIf="!(v.version == actualversion)" class="mrg-com-cont icnc">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span
                                        style="display: flex; flex-direction: row; justify-content:space-between !important; width:100%;">
                                        <div
                                            style="display: flex; flex-direction: row; justify-content:flex-start !important;">
                                            <span class="card-title hvA">
                                                <span class="material-icons">done</span>
                                                <span> {{ order.code }}.{{ v.version }}</span>&nbsp;
                                                <span *ngIf="!(v.name == null) && !(v.name == 'null')">&nbsp;-&nbsp;{{
                                                    v.name
                                                    }}&nbsp;</span>
                                            </span>
                                        </div>
                                    </span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div [innerHTML]="getDetail(v)"></div>
                        </mat-expansion-panel>

                    </span>

                </mat-expansion-panel>
                <form [formGroup]="orderFormGroup">
                    <div class="com-cont">
                        <!-- DATI GENERALI -->
                        <mat-card class="mrg-com-cont">
                            <mat-card-title class="card-title">
                                <span class="material-icons">assignment</span>&nbsp;
                                <span> {{ 'LABEL.GENERAL_DATA' | translate }}</span>
                                <div *ngIf="!(requests == null) && (requests.length > 0) && !(requests[0] == null)"
                                    [matTooltip]="getToolTipRequest(requests[0])">
                                    <div class="little-text">
                                        &nbsp;{{ 'REQUEST.REQUEST' | translate
                                        }}&nbsp;<b>{{requests[0].id}}</b>&nbsp;-&nbsp;{{requests[0].assignmentOperator}}
                                    </div>
                                </div>
                            </mat-card-title>
                            <mat-card-content>
                                <div class="form-row">

                                    <div class="card-row">
                                        <mat-form-field class="mrg-com-cont full" appearance="fill">
                                            <mat-label>{{'REQUEST.COMPANY' | translate }}</mat-label>
                                            <mat-select formControlName="sede" [disabled]="true">
                                                <mat-option (onSelectionChange)="onChangeFirstLevel('companies',$event)"
                                                    *ngFor="let company of companies" [value]="company">
                                                    {{company.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="card-row">
                                        <span class="mrg-com-cont-sm full"
                                            *ngIf="(!(resourcesRespComm == null) && (resourcesRespComm.length > 0))">
                                            <app-select-search appearance="fill" [noUsers]="!editableEstimate()"
                                                formControlName="responsabileCommerciale" [label]="getLabelResp()"
                                                [items]="resourcesRespComm" viewField="name">
                                            </app-select-search>
                                        </span>
                                        <span class="mrg-com-cont-sm full"
                                            *ngIf="(!(resourcesCoord == null) && (resourcesCoord.length > 0))">
                                            <app-select-search appearance="fill" formControlName="coordinatore"
                                                [noUsers]="!editableEstimate()" [label]="getLabelCoord()"
                                                [items]="resourcesCoord" viewField="name">
                                            </app-select-search>
                                        </span>
                                    </div>

                                    <div class="card-row">
                                        <mat-form-field class="mrg-com-cont full" appearance="fill">
                                            <mat-label>{{'LABEL.NOTE' | translate}}</mat-label>
                                            <textarea matInput formControlName="note" [readonly]="!editableEstimate()">
                                        </textarea>
                                        </mat-form-field>
                                    </div>

                                    <div class="card-row" *ngIf="isItAFrenchPractice()">
                                        <mat-form-field class="mrg-com-cont full" appearance="fill">
                                            <mat-label>{{'LABEL.ORDREDESERVICE' | translate}}</mat-label>
                                            <textarea matInput formControlName="ordreDeService" [readonly]="!editableEstimate()">
                                        </textarea>
                                        </mat-form-field>
                                    </div>

                                    <div class="card-row" *ngIf="!interfaceChangesForDubai()">
                                        <mat-form-field class="mrg-com-cont full" appearance="fill">
                                            <mat-label>{{'LABEL.PaymentMethod' | translate }}</mat-label>
                                            <mat-select formControlName="paymentMethodId"
                                                [disabled]="(!editableEstimate() && isStateCategoryWarranty() == false)">
                                                <mat-option [disabled]="disablepaymentOption(paymentMethod)"
                                                    [selected]="paymentMethod.id == orderFormGroup.controls['paymentMethodId'].value"
                                                    *ngFor="let paymentMethod of paymentMethods"
                                                    [value]="paymentMethod.id">
                                                    {{'PAYMENTMETHODS.' + paymentMethod.code | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="card-row">

                                        <span class="mrg-com-cont full" appearance="fill"
                                            *ngIf="!interfaceChangesForDubai() && !isFRAndMCLob()">
                                            <mat-form-field class="example-full-width dis">
                                                <mat-label>{{'LABEL.OneriSicurezza' | translate}}</mat-label>
                                                <input matInput formControlName="oneriSicurezza" readonly>
                                            </mat-form-field>

                                        </span>

                                        <span class="mrg-com-cont full" appearance="fill"
                                            *ngIf="!interfaceChangesForDubai()">
                                            <mat-slide-toggle (change)="changeFinancingToggleActivation($event)"
                                                [disabled]="!editableEstimate()" color="primary"
                                                *ngIf="financingToggleActivation"
                                                formControlName="financingSectionActivation">Abilita
                                                Finanziamento</mat-slide-toggle>
                                        </span>

                                    </div>

                                </div>
                            </mat-card-content>
                        </mat-card>
                        <!-- FINANZIAMNENTO -->
                        <mat-card class="mrg-com-cont" *ngIf="theFundingHasBeenActivated()">
                            <mat-card-title class="card-title">
                                <span class="material-icons">account_balance</span>&nbsp;
                                <span> {{ 'LABEL.FINANZIAMENTO' | translate }}</span>
                            </mat-card-title>
                            <mat-card-content>
                                <div class="form-row">

                                    <div class="card-row">
                                        <p>1. Scegli la <strong>tipologia di finanziamento</strong> ed indica il
                                            <strong>numero di condomini
                                                totale</strong> e il <strong>numero di condomini che aderisce al
                                                finanziamento</strong>:
                                        </p>
                                    </div>

                                    <div class="card-row">

                                        <mat-form-field class="mrg-com-cont" appearance="fill">
                                            <mat-label>{{'LABEL.NumeroRate' | translate }}</mat-label>
                                            <mat-select (selectionChange)="changeRataFinanziamento($event)"
                                                formControlName="fundingId">
                                                <mat-option [disabled]="funding.disabled"
                                                    [selected]="funding.id == orderFormGroup.controls['fundingId'].value"
                                                    *ngFor="let funding of fundings" [value]="funding.id">
                                                    {{funding.label}}{{getIva(funding)}} <span *ngIf="funding.type">-
                                                        {{capitalizeFirstLetter(funding?.type)}} </span>-
                                                    {{funding.source}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>


                                        <mat-form-field class="mrg-com-cont" appearance="fill">
                                            <mat-label>{{'LABEL.numberOfCondominiums' | translate }}</mat-label>
                                            <input type="number" matInput formControlName="numberOfCondominiums"
                                                (focusout)="setFinanziamento()">
                                        </mat-form-field>

                                        <mat-form-field class="mrg-com-cont" appearance="fill">
                                            <mat-label>{{'LABEL.numberOfCondominiumsThatFinance' | translate
                                                }}</mat-label>
                                            <input type="number" matInput
                                                formControlName="numberOfCondominiumsThatFinance"
                                                (focusout)="setFinanziamento()">
                                        </mat-form-field>



                                    </div>

                                    <div class="card-row">
                                        <div>
                                            <p style="margin-bottom:3px;">2. Inserisci la <strong>percentuale sul
                                                    totale</strong> oppure
                                                il <strong>valore effettivo</strong> che si vuole finanziare:</p>
                                            <p style="color:red;display:block;" *ngIf="getMaximumFundableMultiplier()">
                                                La
                                                percantuale
                                                massima finanziabile è:
                                                <strong>{{getMaximumFundableMultiplier()}}%</strong>
                                            </p>
                                        </div>

                                    </div>

                                    <div class="card-row">

                                        <mat-form-field class="mrg-com-cont" appearance="fill">
                                            <mat-label>{{'LABEL.percentageFinancing' | translate }}</mat-label>
                                            <input type="number" matInput formControlName="percentageFinancing" min="0"
                                                [max]="getMaximumFundableMultiplier()"
                                                (focusout)="setFinanziamento('percentageFinancing')">
                                        </mat-form-field>

                                        <mat-form-field class="mrg-com-cont" appearance="fill">
                                            <mat-label>{{'LABEL.percentageFinancingWithVAT' | translate }}</mat-label>
                                            <input type="number" matInput formControlName="percentageFinancingWithVAT"
                                                (focusout)="setFinanziamento('percentageFinancingWithVAT')">
                                        </mat-form-field>

                                    </div>

                                    <hr style="width:100%">

                                    <div class="card-row">
                                        <div>
                                            <p style="margin-bottom:3px;">3. Esito della configurazione del
                                                finanziamento:</p>
                                            <p style="color:red;display:block;"
                                                *ngIf="financedByBancaSellaWithVATValidationMessage()"
                                                [innerHTML]="financedByBancaSellaWithVATValidationMessage()"></p>
                                        </div>
                                    </div>

                                    <div class="card-row">
                                        <mat-form-field class="mrg-com-cont" appearance="fill">
                                            <mat-label>{{'LABEL.advanceFinancing' | translate }}</mat-label>
                                            <input type="number" matInput formControlName="advanceFinancing" min="0"
                                                max="100" [readonly]="true">
                                        </mat-form-field>

                                        <mat-form-field class="mrg-com-cont" appearance="fill">
                                            <mat-label>{{'LABEL.advanceFinancingWithVAT' | translate }}</mat-label>
                                            <input type="number" matInput formControlName="advanceFinancingWithVAT"
                                                [readonly]="true">
                                        </mat-form-field>
                                    </div>

                                    <div class="card-row">

                                        <mat-form-field class="mrg-com-cont" appearance="fill"
                                            [ngClass]="{'errorfinancedByBancaSellaWithVAT': financedByBancaSellaWithVATValidation()}">
                                            <mat-label>{{'LABEL.financedByBancaSellaWithVAT' | translate }}</mat-label>
                                            <input type="number" matInput formControlName="fundedValue"
                                                [readonly]="true">
                                        </mat-form-field>

                                        <mat-form-field class="mrg-com-cont" appearance="fill">
                                            <mat-label>{{'LABEL.bankTaxForFinancing' | translate }}</mat-label>
                                            <input type="number" matInput formControlName="bankTaxForFinancing"
                                                [readonly]="true">
                                        </mat-form-field>

                                        <mat-form-field class="mrg-com-cont" appearance="fill">
                                            <mat-label>{{'LABEL.maximumFinancingInstallmentValue' | translate
                                                }}</mat-label>
                                            <input type="number" matInput
                                                formControlName="maximumFinancingInstallmentValue" [readonly]="true">
                                        </mat-form-field>

                                    </div>



                                    <div class="card-row">

                                        <mat-form-field class="mrg-com-cont" appearance="fill"
                                            [ngClass]="{'errorMaximumFinancingInstallment': maximumFinancingInstallmentValidation()}">
                                            <mat-label>{{'LABEL.installmentValueCondominium' | translate }}</mat-label>
                                            <input type="number" matInput formControlName="installmentValueCondominium"
                                                [readonly]="true">
                                        </mat-form-field>

                                        <span class="mrg-com-cont-sm">
                                            <mat-form-field class="example-full-width">
                                                <mat-label>{{'LABEL.MaggiorazioneFinanziamento' |
                                                    translate}}</mat-label>
                                                <input type="number" matInput
                                                    formControlName="maggiorazioneFinanziamento" [readonly]="true">
                                            </mat-form-field>
                                        </span>

                                    </div>

                                </div>
                            </mat-card-content>
                        </mat-card>
                        <!-- LAVORAZIONI -->
                        <mat-card class="mrg-com-cont">
                            <mat-card-title class="card-title row-panel">
                                <span class="row-panel-group-rigth">
                                    <span class="material-icons">engineering</span>&nbsp;
                                    <span> {{ 'ORDERS.LAVORAZIONI' | translate }} ( {{ 'LABEL.PREZZO_NETTO_TOTALE' |
                                        translate }}: {{getRicavoReale() | number: '1.2-2'}} )</span>
                                </span>
                                <button *ngIf="editableEstimate()" aria-label="Aggiungi lavorazione"
                                    style="background-color:transparent;" type="button" class="btn btn-primary"
                                    (click)="addRow(order.rows.length)">
                                    <mat-icon class="material-icons" style="color:#293683;">plus_one</mat-icon>
                                </button>
                            </mat-card-title>
                            <mat-card-content>
                                <span *ngFor="let r of order?.rows; let i = index" [ngClass]="getRowClass(i)">
                                    <mat-card class="card-title row-panel">
                                        <div *ngIf="!(orderFormGroup.controls['macrophases'].value[i]==null)"
                                            class="form-row">
                                            <mat-form-field class="mrg-com-cont" appearance="fill">
                                                <mat-label>{{'LABEL.BRANCH' | translate}}</mat-label>
                                                <mat-select [disabled]="true"
                                                    [formControl]="orderFormGroup.controls['macrophases'].value[i].controls['branch']"
                                                    (selectionChange)="onChangeBranchesSelect($event)" required>
                                                    <mat-option (onSelectionChange)="onChange('branches',i,$event)"
                                                        *ngFor="let branch of filterBranchesForSelect()"
                                                        [value]="branch" [disabled]="!(branch.isActive == true)">
                                                        {{branch.name}} ({{branch.codCountry}})
                                                    </mat-option>
                                                </mat-select>
                                                <mat-hint
                                                    *ngIf="orderFormGroup.controls['macrophases'].value[i].controls['branch']">
                                                    <b>{{orderFormGroup.controls['macrophases'].value[i].controls['branch']?.value?.name}}</b>
                                                </mat-hint>
                                            </mat-form-field>
                                            <mat-form-field class="mrg-com-cont" appearance="fill">
                                                <mat-label>{{'LABEL.CATEGORY' | translate}}</mat-label>
                                                <mat-select
                                                    [formControl]="orderFormGroup.controls['macrophases'].value[i].controls['category']"
                                                    required
                                                    [disabled]="!editableEstimate() || !(orderFormGroup.controls['macrophases'].value[i].controls['branch'].value?.id > 0)">
                                                    <mat-option (onSelectionChange)="onChange('categories',i,$event)"
                                                        *ngFor="let category of filterCategories(orderFormGroup.controls['macrophases'].value[i].controls['branch'].value)"
                                                        [value]="category"
                                                        [disabled]="!(category.isActive == true && uniqueCategoryForSolare(orderFormGroup.controls['macrophases'], category.code))">
                                                        {{category.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-hint
                                                    *ngIf="orderFormGroup.controls['macrophases'].value[i].controls['category'].value.id > 0">
                                                    <b>{{orderFormGroup.controls['macrophases'].value[i].controls['category'].value.name}}</b>
                                                </mat-hint>
                                            </mat-form-field>
                                            <mat-form-field class="mrg-com-cont" appearance="fill">
                                                <mat-label>{{'LABEL.MACROPHASE' | translate}}</mat-label>
                                                <mat-select
                                                    [formControl]="orderFormGroup.controls['macrophases'].value[i].controls['macrophase']"
                                                    required
                                                    [disabled]="!editableEstimate() || !(orderFormGroup.controls['macrophases'].value[i].controls['category'].value?.id > 0)||(isMacroPhaseChangeble(i)==false)">
                                                    <mat-option (onSelectionChange)="onChange('macrophases',i,$event)"
                                                        *ngFor="let mPhase of filterMacroPhases(orderFormGroup.controls['macrophases'].value[i].controls['category'].value)"
                                                        [value]="mPhase" [disabled]="!(mPhase.isActive == true)">
                                                        {{mPhase.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-hint
                                                    *ngIf="orderFormGroup.controls['macrophases'].value[i].controls['macrophase'].value.id > 0">
                                                    <b>{{orderFormGroup.controls['macrophases'].value[i].controls['macrophase'].value.name}}</b>
                                                </mat-hint>
                                            </mat-form-field>

                                            <mat-form-field class="mrg-com-cont" appearance="fill"
                                                *ngIf="isMultiservice">
                                                <mat-label>{{'TYPOLOGYSERVICEPASSAGES.title' |
                                                    translate}}</mat-label>
                                                <mat-select [disabled]="!editableEstimate()"
                                                    [formControl]="this.orderFormGroup.controls['macrophases'].value[i].controls['TypologyServicePassageId']">
                                                    <mat-option
                                                        *ngFor="let typologyServicePassage of typologyServicePassages"
                                                        [value]="typologyServicePassage.id">
                                                        {{'TYPOLOGYSERVICEPASSAGES.' +
                                                        typologyServicePassage.code | translate}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <mat-form-field
                                                *ngIf="(!(orderFormGroup.controls['sede'].value ==null) && orderFormGroup.controls['sede'].value.CompaniesSocietiesRel[0].nation == 'IT')"
                                                class="mrg-com-cont" appearance="fill">
                                                <mat-label>{{'LABEL.CessioneCredito' | translate}}</mat-label>
                                                <mat-select [disabled]="!editableEstimate()"
                                                    [formControl]="orderFormGroup.controls['macrophases'].value[i].controls['percCredit']">
                                                    <mat-option *ngFor="let taxStep of taxSteps" [value]="taxStep">
                                                        {{taxStep.percStep}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <span class="block-cb-master">

                                                <span class="block-cb-slave"
                                                    *ngIf="practice.BusinessLineId != 40 && !interfaceChangesForDubai()">
                                                    <mat-checkbox class="cb-margin" [disabled]="!editableEstimate()"
                                                        [formControl]="orderFormGroup.controls['macrophases'].value[i].controls['isNotturno']">
                                                        {{'WORKTYPES.NIGTH' | translate}}</mat-checkbox>
                                                    <mat-checkbox class="cb-margin" [disabled]="!editableEstimate()"
                                                        [formControl]="orderFormGroup.controls['macrophases'].value[i].controls['isTrasferta']">
                                                        {{'WORKTYPES.TRAS' | translate}}</mat-checkbox>
                                                    <mat-checkbox class="cb-margin" [disabled]="!editableEstimate()"
                                                        [formControl]="orderFormGroup.controls['macrophases'].value[i].controls['isFestivo']">
                                                        {{'WORKTYPES.FEST' | translate}}</mat-checkbox>
                                                </span>
                                                <span class="block-cb-slave">
                                                    <mat-form-field *ngIf="isMultiservice" class="field-row-phase">
                                                        <input matInput type="number" min="1"
                                                            [readonly]="!editableEstimate()"
                                                            [formControl]="this.orderFormGroup.controls['macrophases'].value[i].controls['howMany']"
                                                            placeholder="Numero ripetizioni"
                                                            (ngModelChange)="changeHowMany(i,$event)">
                                                    </mat-form-field>

                                                </span>
                                            </span>


                                            <div class="flex-container boxRowDetails"
                                                *ngIf="!(orderFormGroup.controls['macrophases'].value[i]==null)">
                                                <div class="margin-bottom">
                                                    <strong class="strong-text inline">{{'LABEL.QTYMANODOPERA' |
                                                        translate}}:</strong>
                                                    <p class="margin-left margin-bottom inline">
                                                        {{getValueForResume(this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value,
                                                        "qtOreManodopera")}}</p>
                                                </div>
                                                <div class="margin-bottom">
                                                    <strong class="strong-text inline">{{'LABEL.QTYTOPRODUCT' |
                                                        translate}}:</strong>
                                                    <p class="margin-left margin-bottom inline">
                                                        {{getValueForResume(this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value,
                                                        "qtToProduct")}}</p>
                                                </div>
                                                <div class="margin-bottom">
                                                    <strong class="strong-text inline">{{'LABEL.NETPRICE' |
                                                        translate}}:</strong>
                                                    <p class="margin-left margin-bottom inline">
                                                        {{getValueForResume(this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value,
                                                        "priceNet")}}</p>
                                                </div>
                                            </div>

                                        </div>

                                        <span class="form-column-rev">
                                            <span class="form-row-rev">
                                                <span *ngIf="editableEstimate()" class="material-icons-outlined icnc"
                                                    (click)="removeRow(i)">remove</span>

                                                <mat-icon *ngIf="openDetails[i].open == false"
                                                    [matBadge]="getNumberBadge(i)" matBadgeColor="primary"
                                                    (click)="expandRow(i)">expand_more</mat-icon>
                                                <mat-icon *ngIf="openDetails[i].open == true"
                                                    [matBadge]="getNumberBadge(i)" matBadgeColor="primary"
                                                    (click)="expandRow(i)">expand_less</mat-icon>

                                            </span>
                                            <span class="form-row-rev">
                                                <mat-icon
                                                    *ngIf="(editableEstimate() && orderFormGroup.controls['macrophases'].value[i].controls['macrophase'].value.isFreeDatas==true) || isStateCategoryWarranty()==true"
                                                    (click)="addFreeRow(i)">add</mat-icon>
                                            </span>
                                        </span>

                                    </mat-card>

                                    <div *ngIf="openDetails[i].open == true">
                                        <table class="table-responsive">
                                            <tbody class="phaseBlock">
                                                <tr class="firstRow">
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                <tr [ngClass]="addPhaseClass(i, j)"
                                                    *ngFor="let phase of  this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value; let j = index">
                                                    <!--<td><pre>{{this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['isFreeDatas'].value|json}}</pre></td>-->
                                                    <td>
                                                        <mat-checkbox class="cb-margin" [disabled]="!editableEstimate()"
                                                            (change)="changeSelected($event, i, j)"
                                                            [formControl]="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['selected']">
                                                        </mat-checkbox>
                                                    </td>
                                                    <td>
                                                        <span class="group-phase-name">
                                                            <span class="phase-name">
                                                                <b>
                                                                    {{this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['phase'].value.name}}</b>
                                                                <i class="isRequired" *ngIf="isMandatory(i, j)"> *</i>
                                                            </span>
                                                            <input class="input-phase-desc"
                                                                *ngIf="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['isFreeDescription'].value == true"
                                                                [formControl]="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['freeDescription']"
                                                                [placeholder]="translate.instant('LABEL.isFreeDescription')">
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div *ngIf="!interfaceChangesForDubai()">
                                                            <div *ngIf="!(this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['isFreeDatas'].value==true)"
                                                                class="info-row-phase">
                                                                <label>{{'LABEL.ProdOrariaRif' | translate}}</label>
                                                                <span>&nbsp;<b>{{this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['phase'].value.qProdOraria}}</b></span>
                                                            </div>

                                                            <mat-form-field
                                                                *ngIf="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['isFreeDatas'].value==true"
                                                                class="field-row-phase">
                                                                <input [readonly]="isReview == true" matInput
                                                                    type="number"
                                                                    [formControl]="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['qProdOrariaRif']"
                                                                    [placeholder]="translate.instant('LABEL.ProdOrariaRif')"
                                                                    (ngModelChange)="changeAmmQt(i,j,$event)">
                                                            </mat-form-field>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="info-row-phase">
                                                            <label *ngIf="!isMultiservice">{{'LABEL.OreManodopera' |
                                                                translate}}</label>
                                                            <label
                                                                *ngIf="isMultiservice">{{'LABEL.OrePerRisorsaAPassaggio'
                                                                | translate}}</label>
                                                            <span>&nbsp;<b>{{this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['qtOreManodopera'].value}}</b></span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div *ngIf="!interfaceChangesForDubai()">
                                                            <div *ngIf="!(this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['isFreeDatas'].value==true)"
                                                                class="info-row-phase">
                                                                <label>{{'LABEL.CostoMaterialeRif' | translate}}</label>
                                                                <span>&nbsp;<b>{{this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['phase'].value.cMater}}</b></span>
                                                            </div>

                                                            <mat-form-field
                                                                *ngIf="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['isFreeDatas'].value==true"
                                                                class="field-row-phase">
                                                                <input [readonly]="isReview == true" matInput
                                                                    type="number"
                                                                    [formControl]="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['cMaterRif']"
                                                                    [placeholder]="translate.instant('LABEL.CostoMaterialeRif')"
                                                                    (ngModelChange)="changeAmmQt(i,j,$event)">
                                                            </mat-form-field>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div *ngIf="!interfaceChangesForDubai()">
                                                            <div class="info-row-phase">
                                                                <label>{{'LABEL.TotaleListino' | translate}}</label>
                                                                <span>&nbsp;<b>{{this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['price'].value}}</b></span>
                                                            </div>
                                                        </div>

                                                    </td>

                                                    <td>
                                                        <mat-form-field
                                                            *ngIf="!interfaceChangesForDubai() && this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['selected'].value==true"
                                                            class="field-row-phase">
                                                            <input
                                                                [readonly]="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j]?.controls['isFixedPrice']?.value || isReview == true || (!editableEstimate() && isStateCategoryWarranty() == false)"
                                                                matInput type="number" min="20" max="100"
                                                                [formControl]="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['percAmmaloramento']"
                                                                [placeholder]="translate.instant('LABEL.ISPERCAMMALORAMENTO')"
                                                                (ngModelChange)="changeAmmQt(i,j,$event)">
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            *ngIf="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['selected'].value==true"
                                                            class="field-row-phase">
                                                            <input
                                                                [readonly]="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j]?.controls['isFixedPrice']?.value || (!editableEstimate() && isStateCategoryWarranty() == false)"
                                                                matInput type="number" min="1"
                                                                [formControl]="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['qtToProduct']"
                                                                [placeholder]="translate.instant('LABEL.QTYTOPRODUCT')"
                                                                (ngModelChange)="changeAmmQt(i,j,$event)">
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            *ngIf="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['selected'].value==true"
                                                            class="field-row-phase">
                                                            <input
                                                                [readonly]="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j]?.controls['isFixedPrice']?.value || isReview == true || isStateCategoryWarranty()==true || !editableEstimate()"
                                                                matInput type="number"
                                                                [formControl]="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['priceNet']"
                                                                [placeholder]="translate.instant('LABEL.NETPRICE')"
                                                                (ngModelChange)="setFinanziamento()">
                                                        </mat-form-field>
                                                    </td>
                                                    <td
                                                        *ngIf="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['phase'].value.isOtherCost == true">
                                                        <mat-form-field
                                                            *ngIf="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['selected'].value==true"
                                                            class="field-row-phase">
                                                            <input
                                                                [readonly]="isReview == true || isStateCategoryWarranty()==true || !editableEstimate()"
                                                                matInput type="number"
                                                                [formControl]="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['otherCost']"
                                                                placeholder="Altri costi">
                                                        </mat-form-field>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </span>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </form>
                <div>
                    <button style="background-color:transparent;" type="button" class="btn btn-primary"
                        matStepperPrevious>
                        <mat-icon class="material-icons" style="color:#293683;">arrow_back</mat-icon>
                    </button>
                    <button style="background-color:transparent;" type="button" class="btn btn-primary" matStepperNext>
                        <mat-icon class="material-icons" style="color:#293683;">arrow_forward</mat-icon>
                    </button>
                </div>
            </ng-container>
        </mat-step>
        <mat-step label="Finance" state="checked" #four_step>
            <ng-container>
                <mat-expansion-panel style="margin:5px; width: 100%;">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class="my-bounce material-icons">redeem</span>
                            <div class="fw-bolder text-dark"> {{ 'LABEL.Certificati' | translate }}</div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row g-6 g-xl-9">

                        <iframe id="CertificatesIframe" style="width: 100%; height: 769px;" [src]="certurl | safe"
                            title="Invoice Certificates"></iframe>

                    </div>
                </mat-expansion-panel>

                <div>
                    <button style="background-color:transparent;" type="button" class="btn btn-primary"
                        matStepperPrevious>
                        <mat-icon class="material-icons" style="color:#293683;">arrow_back</mat-icon>
                    </button>
                    <button style="background-color:transparent;" type="button" class="btn btn-primary" matStepperNext>
                        <mat-icon class="material-icons" style="color:#293683;">arrow_forward</mat-icon>
                    </button>
                </div>
            </ng-container>
        </mat-step>
        <mat-step label="WorkFlow" #five_step>
            <ng-container *ngIf="stepper.selected == null || stepper.selected == five_step">

                <div *ngIf="!(order == null)" class="flex-column mrg">
                    <h2 class="mb-1">{{'practice_workflow_manager.title' | translate}}</h2>

                    <!-- COMPONENTE DOCUMENTALE -->
                    <div *ngIf="checkComponent('practice_workflow_manager'); else elseBlock">
                        <app-practice-workflow-manager [form]="orderFormGroup" *ngIf="practice && documents && order"
                            [disableUpload]="disableUpload" [isNew]="isNew" [components]="components"
                            [practice]="practice" [documents]="documents"
                            [order]="order"></app-practice-workflow-manager>
                    </div>
                    <ng-template #elseBlock>
                        <strong style="color:red">{{'practice_workflow_manager.noDocumentsNeed' | translate}}</strong>
                    </ng-template>

                </div>


                <hr style="margin:50px 0;">

                <div *ngIf="!(order == null)" class="flex-column mrg">
                    <h2 class="mb-1">{{'WORKFLOW.logSectionTitle' | translate}}</h2>

                    <!-- COMPONENTE LINK PRACTICE -->
                    <app-practices-history [reasons]="reasons" [stateCategories]="stateCategories"
                        [practiceLogs]="practiceLogs" [components]="components" [id]="practice.id"
                        *ngIf="practice && practiceLogs" [form]="orderFormGroup"></app-practices-history>

                </div>

                <br />
            </ng-container>
        </mat-step>
        <mat-step label="{{'LABEL.Documenti' | translate}}" [editable]="false" #six_step>
            <ng-container *ngIf="stepper.selected == null || stepper.selected == six_step">

                <app-practice-document [documents]="documents" [orderVersions]="orderVersions"></app-practice-document>


            </ng-container>
        </mat-step>


    </mat-stepper>
    <br>
    <div class="buttons-right">
        <button *ngIf="editableEstimate() || isStateCategoryWarranty()" style="background-color:transparent;"
            [disabled]="!orderFormGroup?.valid" matTooltip="Salva" type="button" class="my-bounce btn btn-primary mx-2"
            (click)="update(false)">
            <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
        </button>
    </div>
</div>